import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Label
} from "reactstrap";
import { Checkbox } from '@material-ui/core';

import "components/custom.css";

class MultipleAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question:"",
      secondCount:1200,
      questionId:"",
      reset:false,
      is_answer:false,
      selectedValue:[],
      correctFlag:true,
      resultObj:{}
    };
  }
  componentDidMount() {

  }
  arr = [];
  removeItem = (array, item) => {
    for(var i in array){
        if(array[i]==item){
            array.splice(i,1);
            break;
        }
    }
}

stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
}

  handleChange = (event) => {
    this.props.showResult();
    let correct = this.props.data.option.filter(function (item) {
  return item.correct == 1;
}).map(function (i) {
  return i.id;
})
    let arr = this.state.selectedValue;
    let val = event.target.value;
    arr.includes(parseInt(val))?this.removeItem(arr, parseInt(val)):arr.push(parseInt(val))
    this.props.submit({'answer':arr,'correct':correct},true);
    this.setState({selectedValue:arr,resultObj:{'answer':arr,'correct':correct} });
  };

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			return {secondCount:1200,questionId: props.data.id,reset:true,correctFlag:true};
  		}else{
  			if (!state.questionId) {
  				return {questionId: props.data.id,correctFlag:true};
  			}
  		}
  		
  		}    
  }

	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

  showAnswer = () => {
    if (this.props.answer != this.state.is_answer) {
      this.setState({is_answer:true});  
    }
  }

  updateCorrect = () =>{
    let correct = this.props.data.option.filter(function (item) {
        return item.correct == 1;
      }).map(function (i) {
        return i.id;
      });
    
     this.props.submit({'correct':correct,answer:[]});
     this.setState({correctFlag:false,resultObj:{'correct':correct,answer:[]}})
  } 

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

 // this.setState(state => {
 //     const list = state.list.push(state.value);
 //     return {
 //       list,
 //       value: '',
 //     };
 //   });

  
  render() {
  	let question ="";
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }      
  	}
    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.stopRecording) {
      this.stopTest();      
    }

  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false,is_answer:false,selectedValue:[]});
  	}

    const { data, resultMode } = this.props;
    const { selectedValue } = this.state;

    return (
      <div className="visibility-visible">
        <div className="custom-box">
          <p className="gray-text">{question}</p>
        </div>

        <div className="radiolist-content custom-box">
          <div className="checklist-content">
            <h4>{data && data.question_mcq}</h4>
            <div className="radiolist-group">
              {data && !resultMode && data.option.map(function(item, i) {
                return (
                  <React.Fragment key={i}>
                    <label className="radio-wrapper">
                      <p>{item.options}</p>
                      <input
                        type="checkbox"
                        name="answer"
                        onChange={this.handleChange}
                        value={item.id}
                        checked={selectedValue.includes(item.id)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </React.Fragment>
                )
              },this)}
            </div>
          </div>
        </div>
      </div>
    );
    // return (
    //   <>
    //   <div className="container-fluid">
    //     <div className="row">
    //       <Col xs={6}><h5>{question}</h5></Col>
    //       <Col xs={6}>
    //       <div>
    //         <h4 className="infoText"></h4>
    //       </div>
    //       <h3 className="question">{this.props.data && this.props.data.question_mcq}</h3>
    //       <div className="answers">
    //       {this.props.data && this.props.resultMode && this.props.data.option.map(function(item){
    //               return <React.Fragment>
    //                       <Label className={ this.props.answer && item.correct === 1 && 'correct-line' }>
    //                         <Checkbox
    //                             checked={this.state.selectedValue.includes(item.id)}
    //                             value={item.id}
    //                             color="default"
    //                             onChange={this.handleChange}
    //                             inputProps={{ 'aria-label': 'secondary checkbox' }}
    //                             name="answer"
    //                           />{' '}
    //                         {item.options}
    //                       </Label>
    //                     </React.Fragment>;
    //             },this) }
    //             {this.props.data && !this.props.resultMode && this.props.data.option.map(function(item){
    //               return <React.Fragment>
    //                       <Label className={ this.props.answer && (item.correct === 1?(this.state.selectedValue.includes(item.id)?'correct-line':'blue-line'):(this.state.selectedValue.includes(item.id))?'red-line':'') }>
    //                         <Checkbox
    //                             checked={this.state.selectedValue.includes(item.id)}
    //                             value={item.id}
    //                             color="default"
    //                             onChange={this.handleChange}
    //                             inputProps={{ 'aria-label': 'secondary checkbox' }}
    //                             name="answer"
    //                           />{' '}
    //                         {item.options}
    //                       </Label>
    //                     </React.Fragment>;
    //             },this) }
    //       </div>
    //       </Col>
    //     </div>
    //   </div>
    //   </>
    // );
  }
}
export default MultipleAnswer;