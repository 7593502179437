import React, { Component } from "react";

// reactstrap components
import { Row, Col } from "reactstrap";
import API from "api.js";
import SidebarClose from "components/Sidebar/SidebarClose";
import Introduction from "components/StgyVideos/Introduction";
import Category from "components/StgyVideos/Category";
import { store } from "store/index";
import { setAlert } from "action";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import SpeakingZone from "assets/images/images_new/microphone-icon.svg";
import WritingZone from "assets/images/images_new/writing-icon.svg";
import ReadingZone from "assets/images/images_new/reading-icon.svg";
import ListeningZone from "assets/images/images_new/listening-icon.svg";
import VideoIcon from "assets/images/images_new/video-icon.svg";



const classes = [
  "",
  "speakingVideos",
  "writingVideos",
  "readingVideos",
  "listeningVideos",
];

class Video extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: false,
      modal1: false,
      videoPlan: true,
      loadmore: false,
      active_subscription: [],
    };
  }

  componentDidMount() {
    API({
      method: "GET",
      url: "user-data-get/active_subscription",
    }).then((data) => {
      let active_subscription = data.data.user.active_subscription;
      this.setState({ active_subscription: active_subscription });
      if (
        active_subscription.length > 0 &&
        active_subscription[0].package_id === 9
      ) {
        this.setState({ modal1: true });
      }
    }).catch(() => {});

    this.loadVideo();
  }

  loadVideo = () => {
    API({
      method: "GET",
      url: "get-stgy-videos",
    }).then((data) => {
      if (data.status === 200 || data.status === 207) {
        this.setState({
          data: data.data.video,
        });
        return;
      }
      store.dispatch(
        setAlert({ flag: true, type: 2, message: "Something went wrong." })
      );
    }).catch(() => {});
  };

  closeModal = () => {
    this.setState({ modal1: false });
  };

  /*
  * 2. Speaking Zone
  * 3. Writing Zone  
  * 4. Reading Zone  
  * 5. Listening Zone  
  */
  categoryIcon = (category) => {
    let icon = "";
    switch (category) {
      case 2:
        icon = SpeakingZone;
        break;
      case 3:
        icon = WritingZone;
        break;
      case 4:
        icon = ReadingZone;
        break;
      case 5:
        icon = ListeningZone;
        break;
      default:
        break;
    }
    return icon;
  }

  render() {
    return (
      <div className="content p-0">
        <DashboardHeader title="Secret Strategy video" />
        <div className="outer-wrapper">
          {this.state.data.map((category, index) => {
            return category.id === 1 ? (
              <Introduction
                key={category.id}
                title={category.title}
                video={category.stgy_vid}
              />
            ) : (
              <Category key={category.id} containerClass={classes[index] ? classes[index] : "speakingVideos"}>
                <Category.Title>
                  <img src={this.categoryIcon(category.id)} alt="image" /> {category.title}
                </Category.Title>
                <Category.List>
                  {category.stgy_vid.map((video) => {
                    return (
                      <Category.Item
                        title={video.title}
                        description={video.description}
                        thumbnail={video.thumbnail}
                        vidLink={video.link}
                        id={video.id}
                        key={video.id}
                      />
                    ); 
                  })}
                </Category.List>
              </Category>
            );
          })}
          <div className="final-tips-wrapper">
            <div className="final-tips-title">Final Tips</div>
            <p className="final-tips-description">More than 107 students got their desired score with LA in the last month. This Final Tips could help you</p>
            <button className="final-tips-btn"><img src={VideoIcon} alt="Image" /> Final Tips for Exam</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
