import React, { useState, useEffect, memo } from "react";
import API from "api.js";
import { store } from "store/index";
import { useDispatch } from "react-redux";
import { setUserType } from "action";

import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import NotificationArea from "components/Dashboard/New/NotificationArea";
import hiImg from "assets/images/images_new/hi-icon.png";
import SpeakingZone from "assets/images/images_new/microphone-icon.svg";
import WritingZone from "assets/images/images_new/writing-icon.svg";
import ReadingZone from "assets/images/images_new/reading-icon.svg";
import ListeningZone from "assets/images/images_new/listening-icon.svg";

// import CarRegistrationChart from "../components/Dashboard/DashboardChart";

import StatCategories from "components/Dashboard/New/StatCategories";
import Stats from "components/Dashboard/New/Stats";
import TuteVideos from "components/Dashboard/TuteVideos";

const Dashboard = memo(function Dashboard(props) {

  const [loading, setLoading] = useState(true);
  const [estimatedScore, setEstimatedScore] = useState(null);
  const [practiceData, setPracticeData] = useState(null);
  const [mockData, setMockData] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(300);
  const [totalProgress, setTotalProgress] = useState(466);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [portalVideos, setPortalVideos] = useState(null);

  const dispatch = useDispatch();

  const loadDashboardData = () => {
    API({
      method: 'GET',
      url: 'dashboard_data'
    }).then(res => {
      const { data, user, isClassified, branches, userType } = res.data;

      const { 
        show_average_score, 
        estimated_score, 
        practice_data, 
        mock_data, 
        videos, 
        classes, 
        subscription,
        popup_video
      } = data;


      setPracticeData(practice_data);
      setMockData(mock_data);
      setPortalVideos(videos);
    }).catch(err => console.log(err));
  }

  useEffect(() => {

    const { user: { first_name, last_name, email, active_subscription } } = store.getState();
    
    let name = first_name ? first_name : email;
    setLoggedInUser({
      name, email, active_subscription
    });

    loadDashboardData();
  }, []);

  useEffect(() => {
    // Mock loading data from an API
    setTimeout(() => setLoading(false), 300);

    // Dispatching user type for example purposes
    dispatch(setUserType("free"));

    // Clean up body class on unmount
    return () => {
      document.body.classList.remove("dashboardPage");
    };
  }, [dispatch]);


  return (
    <>
      {!loading && (
        <div className="siteContent p-0">
          <DashboardHeader title="Dashboard" />
          <div className="dashboard-wrapper">
            <div className="row">
              <NotificationArea
                loggedInUser={loggedInUser}
              />
              <StatCategories
                practiceData={practiceData}
              />
            </div>
            <div className="row">
              <Stats
                mockData={mockData}
                practiceData={practiceData}
              />

              <div className="col-md-5 pr-md-0">
                <div className="dashboard-card dashboard-score-card">
                  <h2 className="title">Estimated Exam Score</h2>
                  <div className="estimated-card-content">
                    <ul className="estimated-list">
                      <li>
                        <h4>
                          <img src={SpeakingZone} alt="Imgage" /> 46
                        </h4>
                        <span>11%</span>
                      </li>
                      <li>
                        <h4>
                          <img src={WritingZone} alt="Imgage" />
                          65
                        </h4>
                        <span>24%</span>
                      </li>
                      <li>
                        <h4>
                          <img src={ReadingZone} alt="Imgage" />
                          68
                        </h4>
                        <span>26%</span>
                      </li>
                      <li>
                        <h4>
                          <img src={ListeningZone} alt="Imgage" />
                          72
                        </h4>
                        <span>39%</span>
                      </li>
                    </ul>
                    <div>
					          {/* < CarRegistrationChart /> */}
					          Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores error doloremque soluta, </div>
                  </div>
                </div>
              </div>
            </div>
            
            <TuteVideos
              videos={portalVideos}
            />

          </div>
        </div>
      )}
    </>
  );
});
export default Dashboard;