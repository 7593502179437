import { s3base } from "api";
import Image from "components/Image/Image";
import BootstrapModal from "components/PopUps/BootstrapModal";
import ModalSkeleton from "components/PopUps/ModalSkeleton";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const Introduction = ({ title, video }) => {
  const [modelStatus, setModelStatus] = useState(false);
  const history = useHistory();

  const modelHandler = () => {
    if (video[0].link) {
      history.push("/admin/video/" + video[0].id);
      return;
    }
    setModelStatus((prevState) => !prevState);
  };

  let vidPopup;
  if (!video[0].link) {
    vidPopup = (
      <ModalSkeleton
        status={modelStatus}
        toggle={modelHandler}
        handleClick={() => {
          history.push("/admin/plans");
        }}
        btnText="Buy Plan"
        message="Please subscribe to our gold plan to see Secret Strategy Videos"
        isSmall={false}
      />
    );
  }

  return (
    video.length > 0 && (
      <div className="intro-video-section">
        <h2 className="intro-title">{title}</h2>
        <div className="intro-video-row">
          <div className="intro-video-wrapper">
            <div onClick={modelHandler} className="intro-video">
              <Image path={s3base + video[0].thumbnail} alt="" />
            </div>
          </div>
          <div className="intro-video-content">
            <h3>PTE Academic full video course</h3>
            <p>by <strong>Varun Dhawan</strong></p>
          </div>
        </div>
        {vidPopup}
      </div>
    )
  );
};

export default Introduction;
