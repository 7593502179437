import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Row,
  Progress,
  Button
} from "reactstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Sound from 'react-sound';
import getBlobDuration from 'get-blob-duration';
import {base,s3base} from "api.js";
import NoMicrophone from "views/NoMicrophone.jsx";
import MicRecorder from 'mic-recorder-to-mp3';
import SpeechToText from "components/Question/Speaking/New/SpeechToText";
import Beep from "Beep.js";

import "components/custom.css";

let Mp3Recorder = new MicRecorder({ bitRate: 128 });

class DescribeImage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount: 25,
      recordCount:0,
      recordId:1,
      intervalId:0,
      duration:0,
      questionId:"",
      reset:false,
      trans:'',
      correctFlag:true,
      soundStatus:false,
      file:'',
      imageLoaded:true,
      saveTranscript: false,
      micPermitted: null,
      browserAllowed: null,
      question_image: null,
      skip: false
    };
  }

  componentDidMount() {

    // check browser. Only Chrome only.
    let chrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(() => {
      // Microphone Permission Granted.

      // load image and set Values.
      const image_url = s3base + this.props.data.media_link;
      this.setState({
        isBlocked: false,
        micPermitted: true,
        browserAllowed: chrome,
        question_image: image_url,
        reset: false
      });
    }).catch(() => {
      // Permission Denied
      this.setState({ isBlocked: true, micPermitted: false, browserAllowed: chrome });
    });
  }

  startProcess = () => {
    if (!this.props.answer) {
      const intervalId = setInterval(this.timer, 1000);
      this.setState({
        intervalId,
        imageLoaded: true
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			
  			return {recordCount:0,isRecording:false,secondCount:25,questionId: props.data.id,blobURL:'',reset:true,imageLoaded:false,trans:'',duration:0};
  		}else{
  			if (!state.questionId) {
  				return {questionId: props.data.id,imageLoaded:false};
  			}
  		}	
  	}
  }


  start = () => {
    this.setState({ soundStatus: true });
  };

  handleSongFinishedPlaying = () =>{
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      SpeechRecognition.startListening({ continuous: true, language: 'en-GB' })
      Mp3Recorder = new MicRecorder({ bitRate: 128 });
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',soundStatus:false});
        }).catch((e) => console.error(e));
    }
  }

  setTrans = (a)=>{
    if (this.state.trans != a) {
      this.setState({trans:a})  
    }
    
  }

  stop = () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          let file = new File([blob], 'noname1'+this.state.questionId+'.mp3', { type: blob.type });
          this.setState({
            blobURL,
            isRecording: false,
            recordCount:100,
            file,
            saveTranscript: true,
            duration
          });
          SpeechRecognition.stopListening();
        }).catch((e) => console.log(e));
    }
  };

  stopTest = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then( async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          //this.setState({ isRecording: false,recordCount:100 });
          let file = new File([blob], 'noname1.mp3', { type: blob.type });
          console.log('fiile:', file)
          SpeechRecognition.stopListening();

          this.setState({
            saveTranscript: true,
            file,
            duration
          });

          // below line was moved to setTranscript()
          // this.props.nextQuestion({'data': file,'audio_text':this.state.trans, duration:duration });
        }).catch((e) => console.log(e));
    } else {
      this.props.nextQuestion({
        data: this.state.file,
        audio_text: this.state.trans,
        duration: this.state.duration
      });      
    }
  };

  updateCorrect = () =>{
     this.props.submit({'data':'', 'audio_text':'','duration':this.state.duration});
     this.setState({correctFlag:false})
  }

  skip = () => {
    clearInterval(this.state.intervalId);
    
    this.start();
    const recordId = setInterval(this.timerRecord, 1000);
    
    this.setState({
      recordId,
      secondCount: 0,
      skip: false
    });
  };

	timer = () => {
    let state_obj = {};
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
			this.start();
			var recordId = setInterval(this.timerRecord, 1000);
      state_obj.recordId = recordId;
      state_obj.skip = false;
			// this.setState({recordId: recordId});
		}

    state_obj.secondCount = this.state.secondCount - 1;
		this.setState(state_obj);
	}



	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  handleImageLoaded =() => {
    this.startProcess();
    this.setState({
      imageLoaded: true,
      skip: true
    });
  }

  setSaveTranscript = (status) => {
    this.setState({ saveTranscript: status });
  }

  setTranscript = (transcript) => {
    this.setState({
      trans: transcript,
      saveTranscript: false
    }, () => {

      // this code was in stop() earlier.
      // after replace <Dictaphone /> with <SpeechToText />,  this code placed here.
      // to get transcript effectively.
      const data_to_send = {
        data: this.state.file,
        audio_text: this.state.trans,
        duration: this.state.duration
      };
      
      if (this.props.testMode) {
        this.props.nextQuestion(data_to_send);
      } else {
        this.props.submit(data_to_send, true);
        this.props.showResult();
      }
    });
  }

  recordingLabel = () => {
    const { blobURL, isRecording, secondCount } = this.state;

    let label = "";
    if (blobURL === '' && !isRecording) {
      label = `Recording starts in ${secondCount} seconds...`;
    } else {
      label = "Recording started";
    }

    return label;
  }


  showSkipWaitButton = () => {
    const { testMode } = this.props;
    const { blobURL, isRecording, skip } = this.state;

    let button = false;
    if (skip && !testMode && blobURL === '' && !isRecording) {
      button = true;
    }

    return button;
  }

  showStopButton = () => {
    const { testMode } = this.props;
    const { isRecording } = this.state;

    let button = false;
    if (!testMode && isRecording) {
      button = true;
    }

    return button;
  }

  showRecordedAudio = () => {
    const { testMode } = this.props;
    const { blobURL } = this.state;

    let status = false;
    if (blobURL && !testMode) {
      status = true;
    }

    return status;
  }
  
  render() {

  	let question = "";
    
  	if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
    if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
  	}
  	
    if (this.state.isBlocked) {
      return (<NoMicrophone />);
    }

    const { micPermitted, browserAllowed, question_image, blobURL, recordCount, saveTranscript, soundStatus } = this.state;
    const { currentQuestion, total_questions, resultMode } = this.props;

    return (
      <>
        {micPermitted && browserAllowed ? (
          <>
            <SpeechToText
              saveTranscript={saveTranscript}
              setTranscript={this.setTranscript}
            />

            {!resultMode && soundStatus && 
              <Sound
                url={Beep}
                playStatus={Sound.status.PLAYING}
                onFinishedPlaying={this.handleSongFinishedPlaying}
              />
            }

            <div className="pmw-tabs-section d-flex align-items-center pmw-outer-wrapper recorded-wrapper logging-system visibility-visible"> 
              <div className="recorded-section describe-image h-100">
                <div className="recorded-content">
                  <div className="img-wrapper">
                    <img
                      alt="media-link"
                      width="500"
                      onLoad={this.handleImageLoaded}
                      src={question_image}
                    />
                  </div>                                         
                </div>                   
              </div>
              <div className="recorded-section h-100">
                <div className="recorded-content">
                  <ul className="recorded-list">
                    <li>Status:</li>
                    <li>{this.recordingLabel()}</li>
                  </ul>
                  <div className="recorded-audio-wrapper">
                    <Progress
                      value={(recordCount*2.5)}
                    />
                    {this.showSkipWaitButton() &&
                      <div className="btn-outer">
                        <button
                          className="skip-btn visibility-visible"
                          onClick={this.skip}
                        >Skip</button>
                      </div>
                    }
                    {this.showStopButton() &&
                      <div className="btn-outer">
                        <button
                          className="skip-btn visibility-visible"
                          onClick={this.stop}
                        >Stop</button>
                      </div>
                    }
                    {this.showRecordedAudio() && 
                      <audio
                        src={blobURL}
                        controls="controls"
                        className="mt-2 visibility-visible"
                      />
                    }
                  </div>
                </div>
              </div>  
            </div>
          </>
        ) : (
          <div className="pmw-tabs h-100">
            {micPermitted === false &&
              <div className="alert alert-danger">No Microphone permissions granted. Please grant microphone permissions and reload the page.</div>
            }

            {browserAllowed === false &&
              <div className="alert alert-danger">The browser you are using is not supported. Please use Google Chrome.</div>
            }
          </div>
        )}
      </>
    );
    
    // return (
    //   <>        
    //     <SpeechToText
    //       saveTranscript={this.state.saveTranscript}
    //       setTranscript={this.setTranscript}
    //     />

    //     <Col xs={12}>
    //     	<h4 className="infoText"></h4>
    //     </Col>
    //     <Col xs={12}>
    //       <Row>
    //         <Col lg={6}>
    //           {!this.state.imageLoaded && <div style={{margin:'145px'}}>Loading...</div>}
    //           {this.state.imageLoaded && this.props.data && <img alt="media-link" width="500" onLoad={this.handleImageLoaded} src={s3base + this.props.data.media_link} />}
              
    //         </Col>
    //       	<div className="col-lg-6 p-0 text-center">
    //     		  {!this.props.resultMode && 
    //             <div className="audio-box">
    //               <h4>Recorded Answer</h4>
    //               <p><strong>Current status:</strong></p>
    //               <p>
    //                 {this.state.blobURL === '' && this.state.isRecording ? "Recording started" : `Recording starts in ${this.state.secondCount} seconds`}
    //               </p>
    //               {!this.props.testMode && this.state.blobURL === '' && !this.state.isRecording && 
    //                 <div className='stop-btn'>
    //                   <Button color="success" onClick={this.skip} size="sm">Skip</Button>
    //                 </div>
    //               }
    //               <hr></hr>
    //               {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
    //               {!this.props.testMode && this.state.isRecording && 
    //                 <div className='stop-btn'>
    //                   <Button color="success" onClick={this.stop} size="sm">Stop</Button>
    //                 </div>
    //               }
    //               <hr></hr>
    //               <Progress color="info" value={(this.state.recordCount*2.5)} />
    //               <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
    //             </div>
    //           }
    // 		    </div>
    //       </Row>
          
    //       <Col xs={12}>
    //         {this.state.blobURL && !this.props.testMode && 
    //           <audio src={this.state.blobURL} controls="controls" />
    //         }
    //       </Col>
    //     </Col> 
        
    //     <Col xs={12}>
    //       <h5>{question}</h5>
    //     </Col>

    //     {!this.props.resultMode && this.state.soundStatus && 
    //       <Sound url={`${s3base}/ptedata/ptemedia/beep.mp3`} playStatus={Sound.status.PLAYING} onFinishedPlaying={this.handleSongFinishedPlaying} />
    //     }
    //   </>
    // );
  }
}
export default DescribeImage;