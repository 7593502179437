import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

const Confirm = ({ status, confirm, setStatus, message }) => {
  return (
    <Modal
      isOpen={status}
      size="md"
      className="text-translate-modal"
    >
      <ModalHeader className="text-translation" tag="div">
        Delete Account?

        <Button className="modalCloseBtn" onClick={() => setStatus(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              d="M18 6L6 18M6 6l12 12"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </ModalHeader>
      <ModalBody>
        <div>{message}</div>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn close-button"
          onClick={confirm}
        >Yes. Delete Account.</button>
        <button
          className="outline-btn"
          onClick={() => setStatus(false)}
        >No, I don't want to delete.</button>
      </ModalFooter>
    </Modal>
  );
};

export default Confirm;
