import React, { memo } from 'react';
import "assets/css/new-result.css";
import SpeakingResult from "components/Result/SpeakingResult";
import WritingResult from "components/Result/WritingResult";
import ReadingResult from "components/Result/ReadingResult";
import ListeningResult from "components/Result/ListeningResult";
import ShowHistoryData from "views/ShowHistoryData.jsx";
import ReactHtmlParser from 'react-html-parser';

const Result = memo(function Result(props) {

	const {
		isAnswer, resultData, speakingResult, writingResult,
		readingResult, listeningResult, fail, newResult, newHtml,
		subcategoryId, correctAnswer, option_explaination,
		historyData
	} = props;

	const speking_indicators = [
		1, 2, 3, 4, 5
	];


	const indicators = () => {
		return (
			<ul className="result-category">
				<li>
					<span className="good"></span> Good
				</li>
				<li>
					<span className="average"></span>Average
				</li>
				<li>
					<span className="bad"></span>Bad Pronunciation/Missed words
				</li>
			</ul>
		);
	}

	const renderResponseHtml = (html) => {
		return (
			<div className="pmw-outer-wrapper">
				<div className="result-content-wrapper d-flex align-items-center justify-content-between flex-column flex-md-row">
					<div className="result-content h-100">
						<p>{props.renderResponse(html)}</p>
						{subcategoryId === 5 &&
							<p>Correct Answer - <span className="green-text">{correctAnswer}</span></p>
						}
						{speking_indicators.includes(subcategoryId) && indicators()}
					</div>
				</div>
				{writingResult && subcategoryId === 7 &&
					<WritingResult
						resultData={newResult}
						buttonsOnly={true}
					/>
				}
			</div>
		);
	}

	const renderResponseHtmlOldStyle = (html) => {
		return (
			<div className="pmw-outer-wrapper">
				<div className="result-content-wrapper d-flex align-items-center justify-content-between flex-column flex-md-row">
					<div className="result-content h-100">
						<p className="text-dark white-space-fixed">{ReactHtmlParser(newResult.html)}</p>
						{speking_indicators.includes(subcategoryId) && indicators()}
					</div>
				</div>
				{writingResult && subcategoryId === 7 &&
					<WritingResult
						resultData={newResult}
						buttonsOnly={true}
					/>
				}
			</div>
		);
	}
	
	return (
		<>
			{isAnswer && resultData !== '' &&
				<>
					<div className="result-section">
						{speakingResult &&
							<SpeakingResult
								resultData={newResult}
							/>
						}

						{writingResult &&
							<WritingResult
								resultData={newResult}
								buttonsOnly={false}
								essay={subcategoryId === 7 ? true : false}
							/>
						}

						{readingResult &&
							<ReadingResult
								resultData={newResult}
							/>
						}

						{listeningResult &&
							<ListeningResult
								resultData={newResult}
							/>
						}

						<ShowHistoryData
							data={historyData}
							flag={1}
							id={false}
							openAiRemarks={newResult}
							option_explaination={option_explaination}
							type={subcategoryId}
						/>

						{newHtml !== null &&
							renderResponseHtml(newHtml)
						}

						{newResult !== null && newResult !== undefined && 'html' in newResult &&
							renderResponseHtmlOldStyle(newResult.html)
						}						
					</div>
				</>
			}
		</>
	);
});
export default Result;