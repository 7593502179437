import React, { useState } from "react";
import API from "../../api";
import useLogOut from "hooks/useLogOut";
import { useDispatch } from "react-redux";
import { setAlert } from "action";
import Confirm from "components/Dashboard/Popup/Confirm";
import DeleteIcon from "../../assets/images/images_new/delete.png";

function DeleteUser() {
  const { logoutDirectly } = useLogOut();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);

  const handleClick = async () => {
    setStatus(false);
    let response = await API({
      method: "DELETE",
      url: "web/user/delete",
    });

    if (response.status && response.status === 200) {
      dispatch(
        setAlert({
          flag: true,
          type: 1,
          message: "Account deleted successfully.",
        })
      );
      logoutDirectly();
    } else {
      dispatch(
        setAlert({
          flag: true,
          type: 1,
          message: "Something went wrong.",
        })
      );
    }
  };

  return (
    <>
      <Confirm
        message={"Do you want to delete your account?"}
        status={status}
        setStatus={setStatus}
        confirm={handleClick}
      />
      <button
        type="button"
        className="delete-btn"
        onClick={() => setStatus(true)}
      >
        {/* <img src={DeleteIcon} alt="Image" /> */}
        <i className="fa fa-trash-o" aria-hidden="true"></i> 
         Delete Account
      </button>
    </>
  );
}

export default DeleteUser;
