const AvailableBands = [
	{
		"value": "0",
		"label": "Select"
	},
	{
		"value": "36",
		"label": "36+ (5 Bands)"
	},
	{
		"value": "42",
		"label": "42+ (5.5 Bands)"
	},
	{
		"value": "50",
		"label": "50+ (6 Bands)"
	},
	{
		"value": "58",
		"label": "58+ (6.5 Bands)"
	},
	{
		"value": "65",
		"label": "65+ (7 Bands)"
	},
	{
		"value": "73",
		"label": "73+ (7.5 Bands)"
	},
	{
		"value": "79",
		"label": "79+ (8 Bands)"
	}
];
export default AvailableBands;