import { setAlert } from "action/index";
import useHttp from "hooks/useHttpRequest";
import useInput from "hooks/useInput";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Input, FormGroup, Label, Row, Col } from "reactstrap";

const ContactUsPremium = () => {
  const dispatch = useDispatch();
  const {
    value: subject,
    hasError: subjectError,
    onChangeHandler: subjectChangeFn,
    onBlurHandler: subjectBlurFn,
    isValid: isSubjectValid,
    reset: resetSubject,
  } = useInput((value) => !!value);

  const {
    value: message,
    hasError: messageError,
    onChangeHandler: messageChangeFn,
    onBlurHandler: messageBlurFn,
    isValid: isMessageValid,
    reset: resetMessage,
  } = useInput((value) => !!value);

  const { response, error, hasError: responseHasError, request } = useHttp();

  useEffect(() => {
    if (responseHasError) {
      dispatchAlert(2, "Soemthing went wrong");
      return;
    }
    if (response) {
      dispatchAlert(1, response.message);
      resetSubject();
      resetMessage();
    }
  }, [response, responseHasError, resetMessage, resetSubject]);

  const dispatchAlert = (type, message) => {
    dispatch(
      setAlert({
        flag: true,
        type,
        message,
      })
    );
  };

  const submit = async (e) => {
    e.preventDefault();

    if (isSubjectValid && isMessageValid) {
      const data = {
        subject,
        message,
      };
      await request("submitUserQuery", "POST", data);

      return;
    }

    messageBlurFn(true);
    subjectBlurFn(true);
  };

  return (
    <Form action="" method="" className="form">
      <FormGroup>
        <Label for="subject">
          Subject
        </Label>
        <Input
          type="text"
          placeholder="Subject"
          value={subject}
          name="subject"
          onChange={(e) => subjectChangeFn(e.target.value)}
          onBlur={subjectBlurFn.bind(this, true)}
          autoComplete="off"
          id="subject"
        />
        {subjectError && (
          <small style={{ color: "red" }}>Please enter a valid subject</small>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="message">
          Message
        </Label>
        <Input
          style={{ paddingLeft: "0.7rem", paddingBottom: "0.7rem" }}
          value={message}
          placeholder="Message"
          type="textarea"
          onChange={(e) => messageChangeFn(e.target.value)}
          onBlur={messageBlurFn.bind(this, true)}
          name="message"
          id="message"
        />
        {messageError && (
          <small style={{ color: "red" }}>Please enter a valid message</small>
        )}
      </FormGroup>
      <Row className="contact-form-btns">
        <Col className="text-md-right" md="12">
          <Button
            className="contact-discard-btn"                 
          >
            Discard
          </Button>
          <Button
            onClick={submit}
            className="ntn btn-primary btn-round contact-save-btn"
          >
            Send
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactUsPremium;
