import React, { useState, useEffect, memo, useRef } from "react";
import { useHistory } from "react-router-dom";
import SimpleReactValidator from 'simple-react-validator';
import API from "api.js";
import PhoneInputField from 'components/Inputs/PhoneInputField';
import {
	Modal
} from "reactstrap";
import popupLogo from "assets/images/logol.png";

class BookNowModal extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            name: "",
            phone: "",
            email: "",
            center: "",
            process: false,
			branches:[]
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
		this.getBranches();
    }

    getBranches = () => {
		API({
			url: "getAllBranches",
			method: "GET",
		}).then((res) => {
			if (res.status && res.status === 200) {
				this.setState({
					branches: res.data.branches
				});
			}
		});
	}

	changeInput = (e) => {
        let { name, value } = e.target;
        this.setState({ [name] : value });
    };

    handlePhoneChange = (event) => {
    	this.setState({phone: event});
    }

    postSubmit = (e) => {
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return false;
		}

        e.preventDefault();
        this.setState({ process: true }, async () => {

			let bodyFormData = new FormData();
	        bodyFormData.append('phone', this.state.phone);
	        bodyFormData.append('name', this.state.name);
	        bodyFormData.append('email', this.state.email);
	        bodyFormData.append('center', this.state.center);
	        let res = null;
	        try {
				res = await API({
					method: 'POST',
					url: '/submit-query',
					data: bodyFormData
				});
	        } catch(error) {
	            this.setState({ process: false });
	            return "l";
	        };

	        if (res.status === 200) {
				this.props.history.push({
					pathname: '/trial-class-thankyou',
					state: {
						flag: true
					}
				});
				this.setState({
					indexModal: false
				});
			} else if (res.response.status === 422) {
				this.setState({
					error: res.response,
					process: false
				});
				
				let str = '';
				Object.values(this.state.error.data.errors).forEach((val) => {
					str += val[0]+'\n';
				});
				this.setState({
					error_bag: str
				});
			}
        });
	}

	render() {

		const { isOpen, toggle } = this.props;
		const { branches } = this.state;

		return (
			<Modal isOpen={isOpen} size="lg" id="anouncementPopup" >
				<div className="modal-content">
					<div className="modal-header" hidden="">
						<a
							onClick={toggle}
							style={{
								color: '#93c13e',
								opacity: 'unset'
							}}
							href="javascript:void(0)"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							<span aria-hidden="true">×</span>
						</a>
					</div>

					<div className="modal-body pte_popup_modal_body">
						<div className="row">
							<div className="col-md-6 col-sm-6 col-xs-12">
								<img className="pte-popup-logo-img" style={{width: '100px'}} src={popupLogo} />
								<p className="pte_popup_modal_ptag"></p>
								<div>
									<h2 className="pte_popup_modal_h2tag pte_popup_modal_h2tagl1" style={{textAlign: 'center', fontSize:'23px', margin: '30px 0px 0px 0px', padding: '0px',fontWeight:'700'}}>Attend a </h2>
									<h2 className="pte_popup_modal_h2tag pte_popup_modal_h2tagl2" style={{fontSize: '27px',fontStyle: 'italic',margin:'5px 0px',textAlign: 'center'}}><b style={{color:'#a7ff00'}}>FREE TRIAL CLASS</b> </h2>
									<h2 className="pte_popup_modal_h2tag pte_popup_modal_h2tagl3" style={{textAlign: 'center', fontSize: '23px', margin: '0px', padding: '0px',color: 'black'}}>for PTE, NAATI CCL or IELTS with us.</h2>
								</div>
							</div>

							<div className="col-md-6 col-sm-6 col-xs-12 pte_popup_modal_seccol">
								<div className="pte_popup_modal_sec_inner_col">
									<h4 className="pte_popup_modal_h4tag" style={{textAlign: 'center'}}>Book NOW!</h4>
									<p className="pte_popup_modal_ptag" style={{textAlign: 'center'}}>Submit Your Details Below</p>
									<p className="pte_popup_modal_ptag" style={{textAlign: 'center'}}><i className="fas fa-long-arrow-alt-down"></i></p>
									<div className="pte-popup-form-start">
										<form onSubmit={this.postSubmit}>
											<div className="col-md-12">
												<input
													className="ptepop_name form-control"
													onChange={this.changeInput}
													value={this.state.name}
													name="name"
													placeholder="Name*"
													type="text"
												/>
												{this.validator.message('name', this.state.name, 'required')}
											</div>
											<div className="col-md-12">
												<div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
												<PhoneInputField
													value={this.state.phone}
													changeEvent={this.handlePhoneChange}
													addClass="popup-class"
												/>
												{this.validator.message('phone', this.state.phone, 'required|phone')}
											</div>
											<div className="col-md-12">
												<div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
												<input
													className="form-control ptepop_email"
													name="email"
													onChange={this.changeInput}
													value={this.state.email}
													placeholder="Email Address*"
													type="email"
												/>
												{this.validator.message('email', this.state.email, 'required|email')}
											</div>
											<div className="col-md-12">
												<div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
												<select
													className="form-control ptepop_email"
													name="center"
													onChange={this.changeInput}
													value={this.state.center}
												>
													<option value="">Select center</option>
													{branches.map((branch) => (
														<React.Fragment key={branch.id}>
															{branch.branch_detail?.title &&
																<option value={branch.branch_detail.title}>
														 			{branch.branch_detail.title}
																</option>
															}
														</React.Fragment>
													))}
												</select>
												{this.validator.message('center', this.state.center, 'required')}
											</div>
											<div className="col-md-12 row_before_pte_submit">
												<div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
												{!this.state.processing &&
													<a onClick={this.postSubmit} className="btn btn-primary pte_popup_modal_submit">Submit</a>
												}
												{this.state.processing &&
													<a className="btn btn-primary pte_popup_modal_submit">Wait...</a>
												}
											</div>
										</form>
									</div>
									<div className="pte-modal-inputf-gap" style={{height: '10px'}}></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
};
export default BookNowModal;