import React, { memo, useState, useEffect } from 'react';
// import ShowHistory from "views/ShowHistory.jsx";
import History from "components/PracticeMaterial/QuestionResponses/History.jsx";
import MeIcon from "assets/images/images_new/me-icon.png";
import OtherIcon from "assets/images/images_new/others-icon.png";

import "assets/css/question_responses.css";

const ME = "Me";
const OTHERS = "Others";
const QuestionResponses = memo(function QuestionResponses(props) {

	const { loadMyData, loadOthersData, questionData } = props;

	const [activeTab, setActiveTab] = useState(null);
	
	const [othersTabLoaded, setOthersTabLoaded] = useState(false);
	const [othersInitialLoaded, setInitialOthersLoaded] = useState(false);

	const button = (label) => {
		return (
			<button
				className={`nav-link ${label === activeTab ? "active" : ""}`}
				id={`nav-${label.toLowerCase()}-tab`}
				data-bs-toggle="tab"
				data-bs-target={`#nav-${label.toLowerCase()}`}
				type="button"
				role="tab"
				aria-controls={`nav-${label.toLowerCase()}`}
				aria-selected={label === ME ? "true" : "false"}
				onClick={() => setActiveTab(label)}
			>
				<img src={label === ME ? MeIcon : OtherIcon} alt={label} /> {label}
			</button>
		);
	}
	
	useEffect(() => {
		
	}, [activeTab]);

	return (
		<div className="tabs-result-info pmw-tabs-section d-flex align-items-end justify-content-between pmw-outer-wrapper">
			<div className="pmw-tabs h-100 w-100">
				<nav className="tabs-content">
					<div className="nav nav-tabs" id="nav-tab" role="tablist">
						{button(ME)}
						
						{button(OTHERS)}
					</div>
				</nav>
				<div className="tab-content" id="nav-tabContent">
					<div
						className="tab-pane fade show active"
						id="nav-me"
						role="tabpanel"
						aria-labelledby="nav-me-tab"
					>
						<History
							questionData={questionData}
							activeTab={activeTab}
							tabME={ME}
						/>
					</div>
					<div
						className="tab-pane fade"
						id="nav-others"
						role="tabpanel"
						aria-labelledby="nav-others-tab"
					>
						<History
							questionData={questionData}
							activeTab={activeTab}
							tabOTHERS={OTHERS}
						/>
					</div>
				</div>
			</div>
		</div>
	);
});
export default QuestionResponses;