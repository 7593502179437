import React, { Component } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Alert,
  Col,
  Button,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";

import API, { base } from "api.js";
import SimpleReactValidator from "simple-react-validator";
import { setAlert } from "action/index.js";
import { NavLink } from "react-router-dom";
import { setUser } from "action/index.js";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { store, persistor } from "store/index";
import { connect } from "react-redux";
import { thead, tbody } from "variables/general";
import { CountryRegionData } from "react-country-region-selector";
import Select from "react-select";
import DeleteUser from "components/Profile/DeleteUser";
import SidebarClose from "components/Sidebar/SidebarClose";
import { timeZonesNames } from "@vvo/tzdb";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import EditImg from "../assets/images/images_new/edit-icon.svg";
import LockIcon from "../assets/images/images_new/unlock.png";

import AvatarImg from "assets/images/images_new/avatar.png";
import PhoneInputField from 'components/Inputs/PhoneInputField';

const timezones = timeZonesNames.map((timezone) => (
  {
    label: timezone,
    value: timezone,
  }
));

timezones.unshift({
  label: "Auto detect",
  value: "Auto detect"
})

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
  };
};
class Mock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeTab: 1,
      userName: "",
      first_name: null,
      last_name: null,
      country_citizenship: null,
      country_residence: null,
      countryState: "",
      dob: null,
      email: null,
      phone: null,
      address: null,
      lang: "",
      center: null,
      score: "",
      image: "",
      image_src: "",
      error_bag: null,
      modal: false,
      imgModal: false,
      selectArr: [],
      subscriptionId: null,
      name: "",
      countries: [],
      states: [],
      selectedCountryStates: [],
      imageError: "",
      subscription: null,
      timezone: "",
    };
    this.validator = new SimpleReactValidator();
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    let selectArr = [];
    if (store.getState().is_main) {
      selectArr = [
        "LA Parramatta Branch",
        "LA Sydney CBD Branch",
        "LA Jalandhar Branch",
        "LA Online Student",
        "LA Gurdaspur Branch",
        "LA Amritsar Branch",
        "LA Sirsa Branch",
        "LA Sri Ganganagar Branch",
        "LA Kurukshetra Branch",
        "LA Barnala Branch",
        "LA Butwal (Nepal)",
        "Not a student with LA",
      ];
    } else {
      selectArr = selectArr = ["Not a student with LA"];
    }
    this.setState({ selectArr: selectArr });
    API({
      method: "GET",
      url: "user-my-profile",
    })
      .then((data) => {
        var first_name = data.data.user.first_name;
        var last_name = data.data.user.last_name;
        var country_citizenship = data.data.user.country_citizenship;
        var country_residence = data.data.user.country_residence;
        var country_state = data.data.user.country_state;
        var dob = data.data.user.dob;
        var email = data.data.user.email;
        var phone = data.data.user.phone;
        var address = data.data.user.address;
        var lang = data.data.user.lang;
        var center = data.data.user.center;
        var score = data.data.user.score;
        var image_src = data.data.user.image;
        const subscription =
          data.data.user.active_subscription.length > 0
            ? data.data.user.active_subscription[0]
            : null;
        const name = last_name ? `${first_name} ${last_name}` : first_name;
        let tz = "";
        if(data.data.user.user_timezone) {
          tz = data.data.user.user_timezone.timezone;
        }

        this.setState({
          name: name,
          first_name: first_name,
          last_name: last_name,
          country_citizenship: country_citizenship,
          country_residence: country_residence,
          countryState: country_state,
          dob: dob,
          email: email,
          phone: phone,
          address: address,
          lang: lang,
          center: center,
          score: score,
          image_src: image_src,
          subscription,
          timezone: tz
        });
        if (data.data.user.active_subscription[0]) {
          this.setState({
            subscriptionId:
              data.data.user.active_subscription[0].stripe_subscription_id,
          });
          // const subscription = data.data.user.active_subscription[0];
          // this.props.setUser({...this.props.user, active_subscription: subscription})
        }

        // setTimeout(function(){
        //   document.querySelector('select[name="lang"]').value= lang;
        //   document.querySelector('select[name="center"]').value= center;
        //   document.querySelector('select[name="score"]').value= score;
        // },100);
        let selected = CountryRegionData.filter((value) => {
          return value[0] === country_residence;
        });
        const statesString = selected[0][2];
        const statesArray = statesString
          .split("|")
          .map((state) => state.split("~")[0]);
        const states = statesArray.map((value) => {
          return {
            label: value,
            value: value,
          };
        });
        this.setState({ states: states });
      })
      .catch(() => {});

    const countriesArr = CountryRegionData.map((value) => {
      return {
        label: value[0],
        value: value,
      };
    });
    countriesArr.sort(this.compareCountries);
    this.setState({ countries: countriesArr });
  }

  compareCountries(a, b) {
    const topCountries = ["India", "Australia", "Pakistan"];
    // console.log(topCountries);
    const aIndex = topCountries.indexOf(a.label);
    const bIndex = topCountries.indexOf(b.label);

    // If both countries are in the topCountries array, sort based on their order
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    }

    // If only one country is in the topCountries array, prioritize it
    if (aIndex !== -1) {
      return -1; // Move a to the top
    }
    if (bIndex !== -1) {
      return 1; // Move b to the top
    }

    // If neither country is in the topCountries array, maintain original order
    return 0;
  }

  selectCountry(val) {
    this.setState({ country_citizenship: val });
  }

  selectCountryResidence(val) {
    console.log(val);
    this.setState({ country_residence: val.label, countryState: "" });
    const statesString = val.value[2];
    const statesArray = statesString
      .split("|")
      .map((state) => state.split("~")[0]);
    const states = statesArray.map((value) => {
      return {
        label: value,
        value: value,
      };
    });
    console.log(states);
    this.setState({ states: states });
  }

  selectRegion(val) {
    this.setState({ countryState: val.label });
  }

  selectTimezone(val) {
    this.setState({timezone: val});
  }

  selectAvatar = async (av) => {
    var bodyFormData = new FormData();
    bodyFormData.append("email", this.state.email);
    bodyFormData.append("avatarImage", av);
    let res = null;
    try {
      res = await API({
        method: "POST",
        url: "/saveUser",
        data: bodyFormData,
      });
    } catch (error) {
      this.setState({ error: { error } });
    }
    console.log(res.status);
    if (res.status === 200) {
      this.props.setUser(res.data.response.user);
      store.dispatch(
        setAlert({ flag: true, type: 1, message: res.data.response.message })
      );
      this.openModal();
      window.location.reload();
    } else if (res.response.status === 422) {
      this.setState({ error: res.response });
      let str = "";
      Object.values(this.state.error.data.errors).forEach((val) => {
        str += val[0] + "\n";
      });
      this.setState({ error_bag: str });
    }
  };
  postSignUp = async (e) => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return false;
    }
    this.setState({ error_bag: null });
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("phone", this.state.phone);
    bodyFormData.append("first_name", this.state.name);
    // bodyFormData.append('last_name', null);
    if (this.state.dob !== null) {
      bodyFormData.append("dob", this.state.dob);
    }
    // bodyFormData.append('center', this.state.center);
    bodyFormData.append("score", this.state.score);
    bodyFormData.append("lang", this.state.lang);
    let tz;
    if(this.state.timezone === "Auto detect") {
      tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } else {
      tz = this.state.timezone;
    }
    bodyFormData.append('timezone', tz);
    bodyFormData.append("email", this.state.email);
    if (this.state.image) {
      bodyFormData.append("image", this.state.image);
    }

    bodyFormData.append("country_citizenship", this.state.country_residence);
    bodyFormData.append("country_residence", this.state.country_residence);
    bodyFormData.append("country_state", this.state.countryState);
    let res = null;
    try {
      res = await API({
        method: "POST",
        url: "/saveUser",
        data: bodyFormData,
      });
    } catch (error) {
      this.setState({ error: { error } });
    }

    if (res.status === 200) {
      this.props.setUser(res.data.response.user);
      store.dispatch(
        setAlert({ flag: true, type: 1, message: res.data.response.message })
      );
      window.location.href = "/admin/dashboard";
    } else if (res.response.status === 422) {
      this.setState({ error: res.response });
      let str = "";
      Object.values(this.state.error.data.errors).forEach((val) => {
        str += val[0] + "\n";
      });
      this.setState({ error_bag: str });
    }
  };

  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!allowedTypes.includes(img.type)) {
        this.setState({
          imageError: "Unsupported file type. Please upload a valid image.",
        });
        event.target.value = null; // Clear the input
        return;
      }

      const maxSize = 2 * 1024 * 1024; // 2MB
      if (img.size > maxSize) {
        this.setState({ imageError: "File size exceeds the limit (2MB)." });
        event.target.value = null; // Clear the input
        return;
      }

      this.setState({
        image: img,
        imageError: "",
      });
    }
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  cancelSub = () => {
    // this.setState({modal:true});
    this.props.history.push("/admin/subscription-detail");
  };

  openModal = () => {
    this.setState({ imgModal: !this.state.imgModal });
  };

  cancelSubAction = (t) => {
    if (t) {
      this.cancelSubApi();
    }
    this.setState({ modal: false });
  };

  cancelSubApi = () => {
    var bodyFormData = new FormData();
    bodyFormData.set("user_id", this.state.dob);
    bodyFormData.set("stripe_subscription_id", this.state.subscriptionId);
    API({
      method: "POST",
      url: "cancel/sub",
      data: bodyFormData,
    })
      .then((data) => {
        if ("status" in data) {
          this.props.setUser(data.data.user);
          store.dispatch(
            setAlert({ flag: true, type: 1, message: data.data.message })
          );
        } else {
          store.dispatch(
            setAlert({ flag: true, type: 2, message: "Something went wrong" })
          );
        }
      })
      .catch((error) => {
        store.dispatch(
          setAlert({ flag: true, type: 2, message: "Something went wrong" })
        );
      });
  };

  handlePhoneChange = (event) => {
    this.setState({phone: event});
  }

  render() {
    const { subscription } = this.state;
    const showSubBtn = subscription && subscription.is_recurring_plan !== 0;

    let detectedTimezone;
    if(this.state.timezone === "Auto detect") {
      detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    return (
      <>
        {this.state.modal && (
          <Modal isOpen={this.state.modal} size="sm">
            <ModalBody>
              <div>Do you want to cancel subscription ?</div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                className="modalCloseBtn"
                onClick={(e) => {
                  this.cancelSubAction(1);
                }}
              >
                Yes
              </Button>
              <Button
                color="secondary"
                className="modalCloseBtn"
                onClick={(e) => {
                  this.cancelSubAction(0);
                }}
              >
                No
              </Button>
            </ModalFooter>
          </Modal>
        )}
        {this.state.imgModal && (
          <Modal isOpen={this.state.imgModal} size="sm">
            <ModalBody>
              <div className="row">
                <div className="col-md-6">
                  <img style={{ width: "100px" }} src="/images/female.png" />
                  <div>
                    <span
                      onClick={(e) => {
                        this.selectAvatar("/storage/female.png");
                      }}
                      className="mt-3 btn btn-success"
                    >
                      Select
                    </span>
                  </div>
                </div>
                <div className="col-md-6">
                  <img style={{ width: "100px" }} src="/images/male.png" />
                  <div>
                    <span
                      onClick={(e) => {
                        this.selectAvatar("/storage/male.png");
                      }}
                      className="mt-3 btn btn-success"
                    >
                      Select
                    </span>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                className="modalCloseBtn"
                onClick={(e) => {
                  this.openModal();
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}

        <div className="content p-0">
          <DashboardHeader title="My Profile" />
          <div className="contact-page outer-wrapper">
            <Col className="profile-content p-0">
              <FormGroup>
                <div className="profile-img-wrapper">                            
                  <div className="scoreUserImg">
                    <div className="scoreImgOuter">
                      <img
                        src={
                          this.state.image_src
                            ? base + this.state.image_src
                            : AvatarImg
                        }
                        alt="Avatar"
                      />
                    </div>
                  </div>
                  <div className="profile-edit-btn">
                    <Input
                      defaultValue={this.state.image}
                      name="image"
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        this.onImageChange(e);
                      }}/>
                      <div className="edit-img">
                        <img src={EditImg} alt="Image" />
                      </div>
                  </div>
                </div>
                {this.state.imageError && (
                  <small style={{ color: "red" }}>
                    {this.state.imageError}
                  </small>
                )}
              </FormGroup>
            </Col>
            <Form className="contact-form">
              <Row>
                <Col className="" md="6" sm="12">
                  <FormGroup>
                    <label>Name*</label>
                    <Input
                      defaultValue={this.state.name}
                      name="name"
                      placeholder="Name"
                      type="text"
                      onChange={(e) => {
                        this.changeInput(e);
                      }}
                    />
                    {this.validator.message(
                      "name",
                      this.state.name,
                      "required"
                    )}
                  </FormGroup>
                </Col>
                <Col className="" md="6" sm="12">
                  <FormGroup>
                    <label>Phone*</label>
                    <div className="contact-input-wrapper">
                      <PhoneInputField
                        value={this.state.phone}
                        changeEvent={this.handlePhoneChange}
                        addClass="popup-class"
                      />
                      {/*<select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>+1</option>
                        <option value="1">+91</option>
                        <option value="2">+123</option>
                        <option value="3">+123</option>
                      </select>
                      <Input
                        defaultValue={this.state.phone}
                        name="phone"
                        placeholder="Enter phone number"
                        type="text"
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                      />*/}
                    </div>                    
                    {this.validator.message(
                      "phone",
                      this.state.phone,
                      "required"
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="" md="6" sm="12">
                  <FormGroup>
                    <label>Country of residence*</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Select Country"
                      value={this.state.countries.filter((option) => {
                        return (
                          option.label === this.state.country_residence
                        );
                      })}
                      isSearchable={true}
                      name="country_residence"
                      options={this.state.countries}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(val) =>
                        this.selectCountryResidence(val)
                      }
                    />
                    {this.validator.message(
                      "country of residence",
                      this.state.country_residence,
                      "required"
                    )}
                  </FormGroup>
                </Col>
                <Col className="" md="6" sm="12">
                  <FormGroup>
                    <label>State (of Country of residence)*</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Select State"
                      value={this.state.states.filter(
                        (option) =>
                          option.label === this.state.countryState
                      )}
                      isSearchable={true}
                      name="countryState"
                      options={this.state.states}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(val) => this.selectRegion(val)}
                    />
                    {this.validator.message(
                      "state",
                      this.state.countryState,
                      "required"
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="" md="6" sm="12">
                  <FormGroup>
                    <label>Default Language</label>
                    <div className="select-wrapper">
                      <select
                        className="form-control"
                        name="lang"
                        placeholder="English GB"
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                        value={this.state.lang}
                      >
                        <option value="en-GB">en-GB</option>
                        <option value="en-US">en-US</option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col className="" md="6" sm="12">
                  <FormGroup>
                    <label>Desired score</label>
                    <div className="select-wrapper">                    
                      <select
                        className="form-control"
                        placeholder="Select desired score"
                        onChange={(e) => {
                          this.changeInput(e);
                        }}
                        name="score"
                        value={this.state.score}
                      >
                        <option value="null">Select desired score</option>
                        <option value="36">36+ (5 Bands)</option>
                        <option value="42">42+ (5.5 Bands)</option>
                        <option value="50">50+ (6 Bands)</option>
                        <option value="58">58+ (6.5 Bands)</option>
                        <option value="65">65+ (7 Bands)</option>
                        <option value="73">73+ (7.5 Bands)</option>
                        <option value="79">79+ (8 Bands)</option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                {/* <Col md="4" sm="12">
                  <FormGroup>
                    <label htmlFor="">Timezone</label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      placeholder="Search Timezone"
                      value={{label: this.state.timezone, value: this.state.timezone}}
                      isSearchable={true}
                      name="timezone"
                      options={timezones}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      onChange={(val) => this.selectTimezone(val.value)}
                    />
                    {detectedTimezone}
                    {this.validator.message(
                      "timezone",
                      this.state.timezone,
                      "required"
                    )}
                  </FormGroup>
                </Col> */}
              </Row>
            </Form>
            <Row className="contact-form-btns">
              <Col className="d-flex flex-wrap align-items-center" md="6" sm="12">
                    <DeleteUser />
                    <Link to="password" className="change-password">
                      {/* <img src={LockIcon} alt="Image" /> */}
                      <i className="fa fa-key" aria-hidden="true"></i> 
                       Change Password
                    </Link>
              </Col>
              <Col className="text-md-right" md="6" sm="12">
                <Button
                  className="contact-discard-btn"                 
                >
                  Discard
                </Button>
                <Button
                  className="btn-round contact-save-btn"
                  color="info"
                  onClick={this.postSignUp}
                >
                  Save Changes
                </Button>
              </Col>              
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Mock);
