import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Progress,
  Label,
  Button,
  Input
} from "reactstrap";
import { Radio } from '@material-ui/core';
import Sound from 'react-sound';
// core components
import "components/custom.css";
import {base,s3base} from "api.js";
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns";
import SelectVoiceDropDown from "components/PracticeMaterial/SelectVoiceDropDown.jsx";

class CorrectSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      selectedValue:'',
      soundPer:0,
      volume:100,
      correctFlag:true,
      resultObj:{},
      playbackSpeed: 1,
      soundKey: 0,
      question_audio: null,
      defaultSelectedVoice: null,
    };
  }

  componentDidMount() {
    if (!this.props.answer) {
      // select audio.
      let question_audio = null;
      let defaultSelectedVoice = null;
      if ('question_audios' in this.props.data && this.props.data.question_audios.length > 0) {
        let x = parseInt(Math.random() * 6);

        const { question_audios } = this.props.data;
        question_audio = `${s3base}/${question_audios[x].value}`;
        defaultSelectedVoice = question_audios[x].label;
      } else {
        const { media_link } = this.props.data;
        question_audio = `${s3base}${media_link}`;
      }

      var intervalId = setInterval(this.timer, 1000);
      this.setState({
        intervalId,
        question_audio,
        defaultSelectedVoice
      });
    }
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart ) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			//var intervalId = setInterval(ReadAloud.timer1, 1000);

        let question_audio = null;
        let defaultSelectedVoice = null;
        if ('question_audios' in props.data && props.data.question_audios.length > 0) {
          let x = parseInt(Math.random() * 6);

          const { question_audios } = props.data;
          question_audio = `${s3base}/${question_audios[x].value}`;
          defaultSelectedVoice = question_audios[x].label;
        } else {
          const { media_link } = props.data;
          question_audio = `${s3base}${media_link}`;
        }
  			
  			return {
          correctFlag: true,
          recordCount: 0,
          soundPer: 0,
          isRecording: false,
          secondCount: 10,
          questionId: props.data.id,
          blobURL: '',
          reset: true,
          soundStatus: true,
          question_audio,
          defaultSelectedVoice
        };
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  	}
  }
  

	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

  handleSongFinishedPlaying = () => {
    this.setState({'soundStatus':false});
    this.props.soundChecked();
    this.props.showResult();
  } 

  changeRange = (e) => {
      this.setState({volume: e.target.value});
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

  handleChange = (event) => {
    let correct = this.props.data.option.filter(function (item) {
          return item.correct == 1;
        })[0].id;
    this.props.submit({'answer':event.target.value,'correct':correct},true);
    this.setState({selectedValue: event.target.value, resultObj: {'answer':event.target.value,'correct':correct} });
  };

  updateCorrect = () =>{
    let correct = this.props.data.option.filter(function (item) {
          return item.correct == 1;
        })[0].id;
    this.props.submit({'answer':'','correct':correct});
    this.setState({correctFlag:false,resultObj:{'answer':'','correct':correct}});
  } 
	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}
  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  changePlaybackSpeed = (speed) => {
    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1
    }));
    this.setState({playbackSpeed: speed});
  }

  changeAudio = (selected) => {

    const { question_audios } = this.props.data;
    const selected_audio = question_audios.filter((audio, i) => {
      return audio.label === selected.label;
    });

    this.setState({
      question_audio: `${s3base}/${selected_audio[0].value}`,
      playStatus: Sound.status.PLAYING
    });
  }
  
  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		this.setState({intervalId: intervalId,reset:false});
  	}
    return (
      <>
      <Col xs={12}>
      	<h4 className="infoText"></h4>
      </Col>
      <div className="container-fluid">
        <div className="row">
         { !this.props.resultMode && <Col xs={12}>
          	<div className="col-lg-12 text-center">
        		  <div className="currentStatus">
                <p>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
                {this.state.secondCount == 0 && this.state.soundStatus && ' Playing' }
                
                <br></br>
                Volume
                 <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
                 <br></br>
                <Progress color="info" value={(this.state.soundPer)} />
                {!this.props.testMode && (
                  <div className="d-flex justify-content-between">
                    <div className='stop-btn'>
                      {this.state.secondCount !== 0 && this.state.blobURL === '' && !this.state.isRecording &&
                        <Button color="success" onClick={this.skip} size="sm">Skip</Button>
                      }
                    </div>
                    <div className="stop-btn clearfix">
                      <div className="select-voice pull-left">
                        {this.state.defaultSelectedVoice !== null &&
                          <SelectVoiceDropDown
                            voices={this.props.voices}
                            selected={this.state.defaultSelectedVoice}
                            changeAudio={this.changeAudio}
                          />
                        }
                      </div>
                      <div className="select-speed pull-right">
                        <PlaybackSpeedBtns
                          changePlaybackSpeed={this.changePlaybackSpeed}
                          playbackSpeed={this.state.playbackSpeed}
                        />
                      </div>
                    </div>
                  </div>
                )}
                </p>
                
              </div>
              <Col xs={12}>
                {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 && <Sound
                  url={this.state.question_audio}
                  playStatus={Sound.status.PLAYING}
                  onFinishedPlaying={this.handleSongFinishedPlaying}
                  onPlaying={this.handleSongPlaying}
                  volume={this.state.volume}
                  playbackRate={this.state.playbackSpeed}
                  key={this.state.soundKey}
                />}
              </Col>
        		  <Col xs={12}>
        		  	{ this.state.blobURL && <audio src={this.state.blobURL} controls="controls" />}
        		  </Col>
    		    </div>
          </Col> }    
          <Col xs={12}><h3 className="question">{question}</h3>
            <div className="answers">
            {this.props.data && this.props.resultMode && this.props.data.option.map(function(item){
                    return <React.Fragment>
                          <Label className={ this.props.answer && item.correct === 1 && 'correct-line' }>
                              <Radio
                                  checked={this.state.selectedValue == item.id}
                                  value={item.id}
                                  color="default"
                                  onChange={this.handleChange}
                                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                                  name="answer"
                                />{' '}
                              {item.options}
                            </Label>
                            
                          </React.Fragment>;
                  },this) }
            {this.props.data && !this.props.resultMode && this.props.data.option.map(function(item){
                return <React.Fragment>
                        <Label className={ this.props.answer && (item.correct === 1?(this.state.selectedValue == item.id?'correct-line':'blue-line'):(this.state.selectedValue == item.id)?'red-line':'') }>
                          <Radio
                            checked={this.state.selectedValue == item.id}
                            value={item.id}
                            color="default"
                            onChange={this.handleChange}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            name="answer"
                          />{' '}
                          {item.options}
                        </Label>
                      </React.Fragment>;
              },this) }
                </div>
              </Col>
          </div>
      </div>
      </>
    );
  }
}
export default CorrectSummary;
