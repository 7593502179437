import React, { useState, useEffect } from 'react';
import ArrowIcon from "assets/images/images_new/dropdown-icon.svg";

export default function Pagination({
	load, numberButtons, showItems, loadNext, loadHandler,
	PREV, NEXT
}) {

	const [page, setPage] = useState(0);
	const [loadItems, setLoadItems] = useState(10);
	const [showNumberButtons, setShowNumberButtons] = useState(false);
	const [showItemsPerPage, setShowItemsPerPage] = useState(false);
	const [allowNext, setAllowNext] = useState(true);

	useEffect(() => {
		if (numberButtons !== undefined) setShowNumberButtons(numberButtons);
	}, [numberButtons]);

	useEffect(() => {
		if (showItems !== undefined) setShowItemsPerPage(showItems);
	}, [showItems]);

	useEffect(() => {
		if (load !== undefined && load !== loadItems) {
			setLoadItems(load);
		}
	}, [load]);

	useEffect(() => {
		setAllowNext(loadNext);
	}, [loadNext]);

	const previousHandler = (e) => {
		e.preventDefault();
		if (page > 0) {
			const offset = (page - 1) * loadItems;
			setPage(page => (page - 1));

			loadHandler(offset, loadItems, PREV);
		}
	}

	const nextHandler = (e) => {
		e.preventDefault();
		if (allowNext) {
			const offset = (page + 1) * loadItems;
			setPage(page => (page + 1));

			loadHandler(offset, loadItems, NEXT);
		}
	}

	return (
		<div className="dashboard-pagination">
			<ul className="dashboard-pagination-list">
				<li>
					<button
						type="button"
						className={`pagination-list-btn prev-arrow ${page === 0 ? "active" : ""}`}
						onClick={previousHandler}
					>
						<img src={ArrowIcon} alt="Arrow" />
					</button>
				</li>
				{showNumberButtons &&
					<>
						<li>
							<button type="button" className="pagination-list-btn active-pagination">1</button>
						</li>
						<li>
							<button type="button" className="pagination-list-btn">2</button>
						</li>
					</>
				}
				<li className="pagination-list-btn">
					<button
						type="button"
						className={`pagination-list-btn next-arrow ${!allowNext ? "active" : ""}`}
						onClick={nextHandler}
					>
						<img src={ArrowIcon} alt="Arrow" />
					</button>
				</li>
			</ul>

			{showItemsPerPage &&
				<div className="dashboard-pagination-dropdown">
					<select name="pagination" id="pagination" className="pagination-dropdown">
						<option value="">01</option>
						<option value="">02</option>
						<option value="">03</option>
						<option value="">04</option>
						<option value="">05</option>
						<option value="">06</option>
						<option value="">07</option>
						<option value="">08</option>
						<option value="">09</option>
						<option value="">10</option>
						<option value="">11</option>
						<option value="">11</option>
					</select>   
					<span>/Page</span>
				</div>
			}
		</div>
	);
}