import React,{Component} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Alert,
  Badge
} from "reactstrap";
import { Grid } from '@material-ui/core';
import API from "api.js";
import { Redirect } from "react-router-dom";
import {userContext} from 'userContext';
import { Helmet } from "react-helmet";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import ArrowImg from 'assets/images/images_new/practice-arrow.svg';
import SpeakingZone from 'assets/images/images_new/microphone-icon.svg';
import WritingZone from 'assets/images/images_new/writing-icon.svg';
import ReadingZone from 'assets/images/images_new/reading-icon.svg';
import ListeningZone from 'assets/images/images_new/listening-icon.svg';
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";

class Prediction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirect:false,
      type:0
    };
    this.props.history.push(this.props.match.url);
  }
   componentDidMount() {
    API({
      method: 'GET',
      url: 'prediction/count'
    }).then(data => {
     
      this.setState({'data':data.data.data});
    }).catch(()=>{
      
    });
  }
  showQuestion(type) {
    this.setState({'redirect':true,'type':type});
  } 
  render() {
    if (this.state.redirect) {
      return <Redirect to={`/admin/questions-weekly/1/${this.state.type}`} />;
    }
    return (
      <>
        <Helmet>
          <title>Top PTE Training Institute</title>
        </Helmet>
        <div className="siteContent multipleCards monthly-predition">
        <DashboardHeader title="Monthly Prediction Question(s)" />  
          {/* <h3 className="prediction-page-title">Monthly Prediction Question(s)</h3> */}
          <Row>
            <Col className="practice-material-categories dashboard-content-wrapper">
              <Grid container>  
                <Grid item sm={12} md={12} lg={7}>        
                  <Card className="speaking-zone-cards">
                    <CardHeader className="material-header">
                      <CardTitle className="material-category-title speaking" tag="h4"><img className="material-header-icon" src={SpeakingZone} alt="Speaking Zone" /> Speaking Zone</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row className="speaking-zone-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,1)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(1 in this.state.data) ? this.state.data[1] : 0}</Badge>
                              Read Aloud
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Read Aloud" />
                          </div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,2)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(2 in this.state.data) ? this.state.data[2] : 0}</Badge>
                              Repeat Sentence
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Read Aloud" />
                          </div>
                        </Col>
                          <Col className="p-0" onClick={this.showQuestion.bind(this,3)}>
                            <div className="card-content">
                              <div className="d-flex align-items-center">
                                <Badge pill variant="light">{(3 in this.state.data) ? this.state.data[3] : 0}</Badge>
                                Describe Image
                              </div>
                              <img className="card-arrow" src={ArrowImg} alt="Read Aloud" />
                            </div>
                          </Col>
                        </Row>
                        <Row className="speaking-zone-row second-row m-0">
                          <Col className="p-0" onClick={this.showQuestion.bind(this,4)}>
                            <div className="card-content">
                              <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(4 in this.state.data) ? this.state.data[4] : 0}</Badge>
                              Re-tell Lecture
                              </div>
                            <img className="card-arrow" src={ArrowImg} alt="Read Aloud" />
                            </div>
                          </Col>
                          <Col className="p-0" onClick={this.showQuestion.bind(this,5)}>
                              <div className="card-content">
                                <div className="d-flex align-items-center">
                                  <Badge pill variant="light">{(5 in this.state.data) ? this.state.data[5] : 0}</Badge>
                                  Answer Short Questions
                                </div>
                              <img className="card-arrow" src={ArrowImg} alt="Read Aloud" />
                            </div>
                          </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Grid>
                <Grid item sm={12} md={12} lg={5}>
                  <Card className="writing-zone-card h-100">
                    <CardHeader className="material-header">
                      <CardTitle className="material-category-title writing" tag="h4"><img className="material-header-icon" src={WritingZone} alt="Writing Zone" /> Writing Zone</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row className="m-0 h-100">
                        <Col className="p-0 w-100" onClick={this.showQuestion.bind(this,6)}>
                          <div className="card-content h-100">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(6 in this.state.data) ? this.state.data[6] : 0}</Badge>
                              Summarize written text
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Read Aloud" />
                          </div>
                        </Col>
                        <Col  className="p-0 w-100" onClick={this.showQuestion.bind(this,7)}>
                          <div className="card-content h-100">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(7 in this.state.data) ? this.state.data[7] : 0}</Badge>
                              Write Essay
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Read Aloud" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Grid>
                <Grid item md={12}>
                  <Card className="reading-zone-cards">
                    <CardHeader className="material-header">
                      <CardTitle className="material-category-title reading" tag="h4">
                        <img className="material-header-icon" src={ReadingZone} alt="Reading Zone" /> Reading</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row className="reading-zone-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,8)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(8 in this.state.data)?this.state.data[8]:0}</Badge>
                              Multiple Type, Single Answer 
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Reorder Paragraph" />
                          </div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,9)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(9 in this.state.data)?this.state.data[9]:0}</Badge>
                              Multiple Type, Double Answer
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Reorder Paragraph" />
                          </div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,10)}>
                          <div className="card-content"> 
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(10 in this.state.data)?this.state.data[10]:0}</Badge>
                              Reorder Paragraph
                            </div>
                          <img className="card-arrow" src={ArrowImg} alt="Reorder Paragraph" />
                          </div>
                        </Col>
                        </Row>
                        <Row className="reading-zone-row second-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,11)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(11 in this.state.data)?this.state.data[11]:0}</Badge>
                              Reading Fill in the Blanks
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Reorder Paragraph" />
                          </div>
                          </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,12)}>
                          <div className="card-content"> 
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(12 in this.state.data)?this.state.data[12]:0}</Badge>
                              Fill in the Blanks Reading & Writing
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Reorder Paragraph" />
                            </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Grid>
                <Grid item md={12}>
                  <Card className="listening-zone-cards">
                    <CardHeader className="material-header">
                      <CardTitle className="material-category-title listening" tag="h4"><img className="material-header-icon" src={ListeningZone} alt="Listening Zone" /> Listening</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <Row className="listening-zone-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,13)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(13 in this.state.data)?this.state.data[13]:0}</Badge>
                              Summarize Spoken text
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Select Missing Word" />
                          </div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,14)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(14 in this.state.data)?this.state.data[14]:0}</Badge>
                              MCQ Single answer 
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Select Missing Word" />
                          </div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,15)}>
                          <div className="card-content"> 
                            <div className="d-flex align-items-center">
                            <Badge pill variant="light">{(15 in this.state.data)?this.state.data[15]:0}</Badge>
                            MCQ multiple answer
                            </div>
                          <img className="card-arrow" src={ArrowImg} alt="Select Missing Word" />
                          </div>
                        </Col>
                      </Row>
                      <Row className="listening-zone-row second-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,16)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(16 in this.state.data)?this.state.data[16]:0}</Badge> 
                              Listening Fill In the Blanks
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Select Missing Word" />
                          </div>
                        </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,17)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                            <Badge pill variant="light">{(17 in this.state.data)?this.state.data[17]:0}</Badge>
                            Highlight correct summary
                            </div>
                          <img className="card-arrow" src={ArrowImg} alt="Select Missing Word" />
                          </div>
                          </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,18)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(18 in this.state.data)?this.state.data[18]:0}</Badge>
                              Select Missing Word
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Select Missing Word" />
                          </div>
                        </Col>
                      </Row>
                      <Row className="listening-zone-row third-row m-0">
                        <Col className="p-0" onClick={this.showQuestion.bind(this,19)}>
                            <div className="card-content">
                              <div className="d-flex align-items-center">
                                <Badge pill variant="light">{(19 in this.state.data)?this.state.data[19]:0}</Badge>
                                Highlight Incorrect word 
                              </div>
                              <img className="card-arrow" src={ArrowImg} alt="Select Missing Word" />
                            </div>
                          </Col>
                        <Col className="p-0" onClick={this.showQuestion.bind(this,20)}>
                          <div className="card-content">
                            <div className="d-flex align-items-center">
                              <Badge pill variant="light">{(20 in this.state.data)?this.state.data[20]:0}</Badge>
                              Write from Dictation 
                            </div>
                            <img className="card-arrow" src={ArrowImg} alt="Select Missing Word" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </Col>            
          </Row>
        </div>
      </>
    );
  }
}
export default Prediction;
