import React,{Component} from "react";

import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Col,
	Badge,
	Button,
	Modal, ModalHeader, ModalBody, ModalFooter,
	Input,

	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";

import Switch from 'react-bootstrap-switch';

// import ReadAloud from "components/Question/Speaking/ReadAloud.jsx";
import ReadAloud from "components/Question/Speaking/New/ReadAloud.jsx";

import RepeatSentece from "components/Question/Speaking/RepeatSentece.jsx";
// import RepeatSentece from "components/Question/Speaking/New/RepeatSentece.jsx";

// import DescribeImage from "components/Question/Speaking/DescribeImage.jsx";
import DescribeImage from "components/Question/Speaking/New/DescribeImage.jsx";

// import RetellLec from "components/Question/Speaking/RetellLec.jsx";
import RetellLec from "components/Question/Speaking/New/RetellLec.jsx";

import ShortQue from "components/Question/Speaking/ShortQue.jsx";
import Summary from "components/Question/Writting/Summary.jsx";
import Essay from "components/Question/Writting/Essay.jsx";
import SingleAnswer from "components/Question/Reading/SingleAnswer.jsx";
import MultipleAnswer from "components/Question/Reading/MultipleAnswer.jsx";
import Reorder from "components/Question/Reading/Reorder1.jsx";
import ReadingBlank from "components/Question/Reading/ReadingBlank.jsx";
import WrittingBlank from "components/Question/Reading/WrittingBlank.jsx";
import Summer from "components/Question/Listening/Summer.jsx";
import McqSingle from "components/Question/Listening/McqSingle.jsx";
import McqMulti from "components/Question/Listening/McqMulti.jsx";
import ListeningBlank from "components/Question/Listening/ListeningBlank.jsx";
import MissingWord from "components/Question/Listening/MissingWord.jsx";
import CorrectSummary from "components/Question/Listening/CorrectSummary.jsx";
import Dictation from "components/Question/Listening/Dictation.jsx";
import Highlight from "components/Question/Listening/Highlight.jsx";
import ShowHistory from "views/ShowHistory.jsx";
import ShowHistoryData from "views/ShowHistoryData.jsx";
import ChromeCheck from "views/ChromeCheck.jsx";
import QuestionComment from "views/QuestionComment.jsx";
import UserNotes from "views/UserNotes.jsx";
import ReactHtmlParser from 'react-html-parser';
import API, { s3base } from "api.js";
import { store } from 'store/index';
import { setAlert } from 'action/index.js';
import { setUser, expVidPopup } from 'action/index.js';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactPlayer from 'react-player';
import TokenFinished from "components/PracticeMaterial/PopUps/TokensFinished";
import ExplanationPopUp from "components/PracticeMaterial/PopUps/ExplanationPopUp";

import TooltipItem from "components/ToolTip/ToolTipItem.jsx";
import SpeakingResult from "components/Result/SpeakingResult.jsx";
import WritingResult from "components/Result/WritingResult.jsx";
import ReadingResult from "components/Result/ReadingResult.jsx";
import ListeningResult from "components/Result/ListeningResult.jsx";

import LoaderDumb from "components/Loader/LoaderDumb.jsx";
import ExplanationVidPopUp from "components/PracticeMaterial/PopUps/ExplanationVidPopup";
import ModalSkeleton from "components/PopUps/ModalSkeleton";
import SelectOption from "components/PracticeMaterial/SelectOption";
import WordDefinitionModal from 'components/PracticeMaterial/PopUps/WordDefinitionModal';
import TranslateModal from 'components/PracticeMaterial/PopUps/TranslateModal';
import ListenAudio from 'components/PracticeMaterial/PopUps/ListenAudio';
// import SidebarClose from "components/Sidebar/SidebarClose";

import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import VideoImg from "assets/images/images_new/user-img.png";
import MoreAiSuggestions from "components/Result/More/MoreAiSuggestions";
import ItemNumber from "components/PracticeMaterial/New/ItemNumber";

import 'cooltipz-css';
import TagIcon1 from "assets/images/images_new/tag1.svg"
import TagIcon2 from "assets/images/images_new/tag2.svg"
import TagIcon3 from "assets/images/images_new/tag3.svg"
import TagIconDefault from "assets/images/images_new/tagDefault.png";

import Result from "components/Question/Result/Result";
import QuestionResponses from "components/PracticeMaterial/QuestionResponses/QuestionResponses.jsx";

const mapStateToProps = (state) => {
	return {
		user: state.user,
		expVidPopup: state.expVidPopup
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (user) => dispatch(setUser(user)),
		setExpVidPopup: (count) => dispatch(expVidPopup(count))
	};
};

const speaking = [1, 2, 3, 4, 5, 13];
const writing = [6, 7];
const TRANSLATE_BUTTON_SUBCATEGORIES = [1, 2, 4, 11, 13, 16];
const SAMPLE_RESPONSE_BUTTON_SUBCATEGORIES = [1, 11];
const TAGS = ["grey", "red", "green", "yellow"];

class Practice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			questionData:[],
			currentQuestion:0, //parseInt(this.props.match.params.index),
			isRecording: false,
			blobURL: '',
			error:'',
			history:[],
			board:[],
			historyData:[],
			history_flag:false,
			isBlocked: false,
			is_answer:false,
			is_result:false,
			resultObj:{
				text_answer:'',
				length:0,
				type:this.props.match.params.id,
				answer:'',
				script:'',
				q_ans:'',
				correct:'',
				strategy: 1
			},
			resultData:'',
			process:false,
			testModal:false,
			textAr:false,
			comment_process:false,
			modal:false,
			switched:false,
			reportText:'',
			history_process:false,
			historyMore:false,
			boardMore:false,
			restart:false,
			type:this.props.match.params.id,
			prediction:this.props.match.params.type,
			aType:this.props.match.params.aType,
			mark:this.props.match.params.mark,
			atmptd:this.props.match.params.attempted,
			complexity:this.props.match.params.complexity,
			filterByVid:this.props.match.params.videos,
			submitFlag:false,
			attempted:0,
			intervalId1:"",
			secondCount1:0,
			note_flag:false,
			board_process:false,
			all_ok: true,
			hideNav:false,
			user:store.getState().user,
			isSubscribed:(store.getState().user.active_subscription.length > 0)?true:false,
			viewVideo:false,
			viewHindiVideo:false,
			videoModal:false,
			videoModalState:false,
			videoHindiModal:false,
			videoHindiModalState:false,
			videoUrl:"",
			videoHindiUrl:"",
			thirtyDaysInMs:"",
			timeDiffInMs:"",
			attemptedQuestions:[],
			isAttempted:false,
			orderby:localStorage.getItem('orderby') ? localStorage.getItem('orderby') : 'desc',
			isSubscribedCheck:(store.getState().subscribed ? store.getState().subscribed : 'false'),
			total_questions: 0,
			open_ques: 1,
			search_string: '',
			user_text: null,
			connected: true,
			backOnline: false,
			ping_server: null,
			fail: undefined,
			new_html: null,
			average_pace: undefined,
			new_result: null,
			tokens: [],
			currTokens: null,
			tokensPopUp: false,
			explanationPopUp: false,
			expVid: null,
			loader: false,
			nextBtnVid: false,
			showBottomExplanationPopup: false,
			scriptLanguages: [],
			currentScriptLanguage: "English",
			audio_script: "",
			voices: [],
			modalDestroy: true,
			wordDefinitionModalIsOpen: false,
			selectedWord: "",
			shadowingPopup: false,
			translateModal: false,
			sampleResponseModal: false,
			dropdownOpen: false
		};
	}

	toggleDropDown = () => {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen
		});
	}

	toggle = tab => {
		if(this.state.activeTab !== tab){
			this.setState({activeTab:tab});
		}
	}

	stayConnected = () => {
		if (process.env.REACT_APP_ENV === "production") {
			const ping_server = setInterval(() => {
				fetch('https://la-ping.languageacademy.com.au/connected')
				.then(response => response.json())
				.then(json => console.log(""))
				.catch(error => console.log(""));
			}, 5000);
			this.setState({ ping_server });
		}
	}

	async componentDidMount() {
		// if (Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Calcutta') {
		// if (Intl.DateTimeFormat().resolvedOptions().timeZone === 'Australia/Sydney') {
			this.stayConnected();
		// }

		let localStorage_orderby = localStorage.getItem('orderby');
		if(!localStorage_orderby){
			localStorage.setItem('orderby', 'desc');
		}

		let urlParams = new URLSearchParams(window.location.search);
		let myParam = urlParams.get('view');
		let hideNav = parseInt(myParam) === 1 ? true : false;
		let open_ques = parseInt(urlParams.get('page')) ? parseInt(urlParams.get('page')) : 0;
		let q_id = this.props.match.params.index;

		this.setState({ hideNav: hideNav, open_ques });

		let { type, prediction, aType, mark, atmptd, complexity, orderby } = this.state;

		const ques = await this.loadQuestion(open_ques, q_id);
		if (ques.status === 200) {
			const expVid = ques.data.expVid;
			let intervalId1 = setInterval(this.timer1, 1000);
			let question_data = ques.data.data[0];

			const token = speaking.includes(question_data.subcategory_id) ?
				ques.data.tokens.speaking : 
				(
					writing.includes(question_data.subcategory_id) ?
					ques.data.tokens.writing : 
					null
				)

			this.setState({
				currTokens: token,
				questionData: ques.data.data,
				tokens: ques.data.tokens,
				// tag: (data.data.data[currentQuestion].tag.length >0) ? data.data.data[currentQuestion].tag[0].tag : "grey",
				tag: (question_data.tag.length > 0) ? question_data.tag[0].tag : "grey",
				intervalId1: intervalId1,
				currentQuestion: open_ques,
				total_questions: ques.data.total,
				testCount: ques.data.data[0].tested,
				expVid,
				scriptLanguages: ques.data.script_languages,
				audio_script: ques.data.data[0].audio_script,
				voices: ques.data.voices
				// testCount: data.data.data[this.state.currentQuestion].tested.length
			}, () => {

				let { currentQuestion, questionData, user } = this.state;
				let { video, hindi_video, created_at, isVideo, isHindiVideo } = questionData[0];
				if (ques.data.total !== 0 && open_ques > 0) {
					if(document.querySelector('.selectPage')) {
						document.querySelector('.selectPage').value = currentQuestion;
					}
				}

				const pastTime = new Date(created_at);
				const now = new Date();
				const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
				const timeDiffInMs = now.getTime() - pastTime.getTime();


				// const attempted_questions = this.state.user.user_attempted_questions.attempted_questions;

				let attempted_questions = [];
				if (user.user_attempted_questions !== null && user.user_attempted_questions !== undefined) {
					attempted_questions = user.user_attempted_questions.attempted_questions;
				}
				const obj = attempted_questions.length > 0 ? JSON.parse(attempted_questions) : attempted_questions;
				const questions_id = obj.questions_id;

				this.setState({
					videoUrl: video !== null ? video : null,
					viewVideo: isVideo,
					videoHindiUrl: hindi_video !== null ? hindi_video : null,
					viewHindiVideo: isHindiVideo,
					thirtyDaysInMs,
					timeDiffInMs,
					attemptedQuestions: questions_id
				}, () => {
					this.loadAttemptedQuestiondata(currentQuestion);
				});
			});
		} else {
			store.dispatch(setAlert({
				'flag': true,
				'type': 2,
				'message': 'Something went wrong. Unable to load question.'
			}));
		}
	}

	loadQuestiondata = () => {
		API({
			method: 'GET',
			url: 'question/',
		}).then(data => {
			this.setState({'questionData': data.data.data});
		}).catch(() => {});
	}

	loadAttemptedQuestiondata = (currentQuestionId) => {

		const { user_attempted_questions } = store.getState().user;
		let attemptedQuestions = [];
		if (user_attempted_questions != null) {
			attemptedQuestions = user_attempted_questions.attempted_questions;
		}
		let question_id = this.state.questionData[0].id;

		question_id = question_id.toString();
		let checkquestionid = attemptedQuestions.includes(question_id)

		if(checkquestionid) {
			this.setState({isAttempted: true})
		} else {
			this.setState({isAttempted: false})
		}
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId1);
		if (this.state.ping_server !== null) {
			clearInterval(this.state.ping_server);
		}
	}

	changeReportText = (e) => {
		let { name, value } = e.target;
		this.setState({ [name] : value });
	}

	submitReport = () => {
		if(this.state.comment === "") {
			store.dispatch(setAlert({ flag: true, type: 2, message: "Message cant't be empty" }));
			return "";
		}

		let bodyFormData = new FormData();
		bodyFormData.set('text', this.state.reportText);
		bodyFormData.set('question_id', this.state.questionData[0].id);
		API({
			method: 'POST',
			data: bodyFormData,
			url: 'report/question',
		}).then(data => {
			if (data.status === 200) {
				this.setState({ reportText: '', modal: false });
				store.dispatch(setAlert({ flag: true, type: 1, message: data.data.message }));
			}
		}).catch(() => { });
	}

	nextButton = async (manual = false) => {
		this.setState({option_explaination:''})
		if(!manual) {
			if(this.props.expVidPopup === 0) {
				this.nextButtonVidPopup(true);
				return;
			}
		}
		this.setState({
			is_answer: false,
			user_text: null,
			fail: undefined,
			new_html: null,
			new_result: null,
			submitFlag: false
		}, async () => {
			// ----------------

			let { currentQuestion } = this.state;
			let new_ques = await this.loadQuestion(currentQuestion + 1);

			if (new_ques.status === 200) {

				let question_data = new_ques.data.data[0];
				let { tag, tested, video, hindi_video, created_at, isVideo, isHindiVideo } = question_data;

				let now = new Date();
				let pastTime = new Date(created_at);
				let thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
				let timeDiffInMs = now.getTime() - pastTime.getTime();

				this.setState({
					comment: [],
					board: [],
					history: [],
					process: false,
					currentQuestion: currentQuestion + 1,
					tag: (tag.length > 0) ? tag[0].tag : "grey",
					is_answer: false,
					is_result: false,
					history_flag: false,
					comment_flag: false,
					secondCount1: 0,
					testCount: tested,
					// testCount: this.state.questionData[this.state.currentQuestion+1].tested.length,
					board_flag: false,
					history_flag: false,
					comment_flag: false,
					note_flag: false,
					viewVideo: isVideo,
					viewHindiVideo: isHindiVideo,
					videoUrl: video !== null ? video : null,
					videoHindiUrl: hindi_video !== null ? hindi_video : null,
					thirtyDaysInMs,
					timeDiffInMs,

					questionData: new_ques.data.data
				}, () => {
					this.loadAttemptedQuestiondata(currentQuestion);
				});

				
				this.forceUpdate();
				if(this.props.user.active_subscription.length < 1 || this.props.user.active_subscription[0].plan.is_gold === 0) {
					this.props.setExpVidPopup(this.props.expVidPopup - 1);
				}
			} else {
				store.dispatch(setAlert({
					'flag': true,
					'type': 2,
					'message': 'Something went wrong. Unable to load question.'
				}));
			}
		});
	}

	prevButton = async (e) => {
		this.setState({option_explaination:''});
		e.preventDefault();
		this.setState({
			is_answer: false,
			user_text: null,
			fail: undefined,
			new_html: null,
			new_result: null,
			submitFlag: false
		}, async () => {
			let { currentQuestion } = this.state;
			let new_ques = await this.loadQuestion(currentQuestion - 1);

			if (new_ques.status === 200) {

				let question_data = new_ques.data.data[0];
				let { tag, tested, video, hindi_video, created_at, isVideo, isHindiVideo } = question_data;

				let pastTime = new Date(created_at);
				let now = new Date();
				let thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
				let timeDiffInMs = now.getTime() - pastTime.getTime();

				this.setState({
					comment: [],
					board: [],
					history: [],
					process: false,
					tag: (tag.length > 0) ? tag[0].tag : "grey",
					currentQuestion: currentQuestion - 1,
					is_answer: false,
					is_result: false,
					history_flag: false,
					comment_flag: false,
					secondCount1: 0,
					testCount: tested,
					// testCount: this.state.questionData[this.state.currentQuestion-1].tested.length,
					board_flag: false,
					history_flag: false,
					comment_flag: false,
					note_flag: false,
					viewVideo: isVideo,
					videoUrl: video !== null ? video : null,
					viewHindiVideo: isHindiVideo,
					videoHindiUrl: hindi_video !== null ? hindi_video : null,
					thirtyDaysInMs,
					timeDiffInMs,

					questionData: new_ques.data.data
				}, () => {

					this.loadAttemptedQuestiondata(this.state.currentQuestion - 1);
				});
				
				this.forceUpdate();

			} else {
				store.dispatch(setAlert({
					flag: true,
					type: 2,
					message: 'Something went wrong. Unable to load question.'
				}));
			}
		});
	}

	showModal = () => {
		this.setState({ modal: !this.state.modal });
	}

	submitResponse = (data, f = false) => {
		let qdata = this.state.questionData[0];
		let obj = {
			data: data.data,
			text_answer: data.answer,
			length: data.length,
			type: qdata.subcategory_id,
			id: qdata.id,
			script: qdata.audio_script,
			q_ans: qdata.answer,
			html: qdata.html,
			correct: data.correct,
			lang: data.lang,
			duration: data.duration,
			audio_text: data.audio_text,
			answer: qdata.question,
			strategy: this.state.resultObj.strategy,
		};
		// console.log('hi there', obj)

		this.setState({
			resultObj: obj,
			submitFlag: f,
			new_html: null
		});
	}

	loadQuestion = async (ques, q_id = null) => {
		let localStorage_orderby = localStorage.getItem('orderby');
		if(!localStorage_orderby){
			localStorage.setItem('orderby', 'desc');
		}

		let urlParams = new URLSearchParams(window.location.search);
		let hideNav = parseInt(urlParams.get('view')) === 1 ? true : false;

		let { type, prediction, aType, mark, atmptd, complexity, orderby, filterByVid } = this.state;
		let search_string = urlParams.get('search') === '' || urlParams.get('search') === null ? '' : urlParams.get('search');
 
		return await API({
			method: 'GET',
			url: `question/${type}?prediction=${prediction}&type=${aType}&mark=${mark}&attempted=${atmptd}&complexity=${complexity}&orderby=${orderby}&practice=${!hideNav}&open_ques=${ques}&qid=${q_id}&search=${search_string}&filterByVid=${filterByVid}`
		});
	}

	changeSelectPagination = async (e) => {

		let selectedQuestion = parseInt(e.target.value);
		this.setState({
			is_answer: false,
			user_text: null,
			fail: undefined,
			new_html: null,
			new_result: null,
			submitFlag: false
		}, async () => {
			let new_ques = await this.loadQuestion(selectedQuestion);
			if (new_ques.status === 200) {

				let question_data = new_ques.data.data[0];
				let checkifSelectPaghasvideo = question_data.video;
				let checkifSelectPaghashindivideo = question_data.hindi_video;
				let {isHindiVideo, isVideo} = question_data;

				var ques_created_at = question_data.created_at;
				var pastTime = new Date(ques_created_at);
				var now = new Date();
				var thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
				var timeDiffInMs = now.getTime() - pastTime.getTime();

				this.setState({
					comment: [],
					board: [],
					history: [],
					process: false,
					currentQuestion: selectedQuestion,
					tag: (question_data.tag.length > 0) ? question_data.tag[0].tag : "grey",
					is_answer: false,
					is_result: false,
					history_flag: false,
					comment_flag: false,
					note_flag: false,
					secondCount1: 0,
					viewVideo: isVideo,
					videoUrl: checkifSelectPaghasvideo !== null ? checkifSelectPaghasvideo : null,
					viewHindiVideo: isHindiVideo,
					videoHindiUrl: checkifSelectPaghashindivideo !== null ? checkifSelectPaghashindivideo : null,
					thirtyDaysInMs: thirtyDaysInMs,timeDiffInMs:timeDiffInMs,

					questionData: new_ques.data.data,
					option_explaination:""
				}, () => {
					this.loadAttemptedQuestiondata(selectedQuestion);
				});

				this.forceUpdate();
			} else {
				store.dispatch(setAlert({
					flag: true,
					type: 2,
					message: 'Something went wrong. Unable to load question.'
				}));
			}
		});
	}

	submitQuestionResponse = async (formData) => {
		const url = `submit/practice?random=${Math.floor(Math.random() * 1000000000)}`;
		return await API({
			method: 'POST',
			url,
			data: formData
		});
	}

	afterSubmitResponseActionOne = (data, state_obj) => {
		const { content, transcript, fluency, pronunciation, intonation, html, fail, fail_message, new_html, average_pace, total, new_format } = data.data;

		if (this.state.questionData[0].subcategory_id === 5) {
			state_obj.resultData = `Content Score : ${content} \n Your Response: ${transcript}`;
			let new_array = [JSON.parse(new_html)];
			
			state_obj.new_html = new_array;
			state_obj.content_score = content;
		} else {
			state_obj.resultData = `Content Score : ${content} \n Fluency Score : ${fluency} \n Pronunciation Score : ${pronunciation} \n ${html}`;
			state_obj.new_html = typeof new_html === 'string' ? JSON.parse(new_html) : new_html;

			state_obj.content_score = content;
			state_obj.fluency_score = fluency;
			state_obj.pronunciation_score = pronunciation;
			state_obj.intonation_score = intonation;
			state_obj.average_pace = average_pace;
			state_obj.total_score = total;
		}

		if (fail) {
			state_obj.fail = fail_message;
		}
		state_obj.new_result = new_format;
		this.setState(state_obj);
	}

	markAllMistakes = (content, mistakes) => {
		let html = content;
		let html_with_errors = "";

		for(let i = 0; i < mistakes.length; i++) {
			let mistake = mistakes[i];
			

			let error = content
		}
	}

	essayResponse = (data, state_obj) => {
		const { new_format } = data.data;

		state_obj.resultData = `Result Data`;
		state_obj.new_result = new_format;
		state_obj.new_result.openai = true;
		state_obj.new_html = null;

		let html = new_format.html;

		// assing to state.
		state_obj.new_result.html = html;

		
		this.setState(state_obj);
	}

	essayResponseOpenAiOnly = (data, state_obj) => {
		const { new_format } = data.data;

		state_obj.resultData = `Result Data`;
		state_obj.new_result = new_format;
		state_obj.new_result.openai = true;
		state_obj.new_html = null;

		let html = new_format.html;
		
		// grammar mistakes.
		html = this.markGrammarMistakes(html, new_format.mistakes.grammar);
		
		// punctuation mistakes
		html = this.markPunctuationMistakes(html, new_format.mistakes.punctuation);

		// spelling mistakes.
		html = this.markSpellingMistakes(html, new_format.mistakes.spelling);


		// assing to state.
		state_obj.new_result.html = html;

		
		this.setState(state_obj);
	}


	markPunctuationMistakes = (content, mistakes) => {
		for (let i = 0; i < mistakes.length; i++) {
			let mistake = mistakes[i];

			let start_position = content.indexOf(mistake.short_context);
			let end_position = (start_position + parseInt(mistake.short_context.length));

			let error_index = -1;
			if (mistake.error.length < 4) {
				error_index = this.findErrorIndex(content, mistake.error, start_position);
			} else {
				error_index = content.indexOf(mistake.error, start_position);
			}

			if (start_position !== -1) {
				if ((error_index >= start_position) && (error_index < end_position)) {
					let replace_with = this.punctuationMistakeContent(mistake);
					let nc = this.replaceRange(content, error_index, (error_index + mistake.error.length), replace_with);
					content = nc;
				}
			}
		}
		return content;
	}

	punctuationMistakeContent = (mistake) => {
		return `<span class='essay or-bg' aria-label='Punctuation error: ${mistake.correction}' data-cooltipz-dir='left' data-cooltipz-size='custom'>${mistake.error}</span>`;
	}

	findErrorIndex = (content, error, start_position) => {
		let error_index = -1;

		error_index = content.indexOf(` ${error} `, start_position);
		if (error_index === -1) {
			error_index = content.indexOf(`${error} `, start_position);
		} else {
			error_index += 1; // add 1 in case of space before starting string.
		}

		if (error_index === -1) {
			error_index = content.indexOf(`>${error} `, start_position);
		}

		if (error_index === -1) {
			error_index = content.indexOf(` ${error}<`, start_position);
			if (error_index > -1) {
				error_index += 1; // add 1 in case of space before starting string.
			}
		}

		return error_index;
	}


	markSpellingMistakes = (content, mistakes) => {
		console.log(content);
		for (let i = 0; i < mistakes.length; i++) {
			let mistake = mistakes[i];
			
			let word_to_find = ` ${mistake.word} `;
			let start_position = content.indexOf(word_to_find);

			if (start_position === -1) {
				// considering its inside span tags.
				start_position = this.findStartPosition(content, mistake.word);
			}
			
			let end_position = (start_position + parseInt(word_to_find.length));

			// adding space for spelling mistake.
			let error_index = content.indexOf(mistake.word, start_position);

			if ((error_index >= start_position) && (error_index < end_position)) {
				let replace_with = this.spellingMistakeContent(mistake);
				let nc = this.replaceRange(content, error_index, (error_index + mistake.word.length), replace_with);
				content = nc;
			}
		}
		return content;
	}

	findStartPosition = (content, word) => {
		let start_position = -1;

		// find with . at the end.
		start_position = content.indexOf(` ${word}.`);

		if (start_position === -1) {
			// find with , at the end.
			start_position = content.indexOf(` ${word},`);
		}

		if (start_position === -1) {
			// find with ; at the end.
			start_position = content.indexOf(` ${word};`);
		}

		if (start_position === -1) {
			// find with : at the end.
			start_position = content.indexOf(` ${word}:`);
		}


		// find inside span tags. with >
		start_position = content.indexOf(`>${word}`);

		if (start_position === -1) {
			// find inside span tags. with <
			start_position = content.indexOf(`${word}<`);
		}

		if (start_position === -1) {
			// find outside span tag but attached with span tag <span
			start_position = content.indexOf(` ${word}<span`);
		}

		if (start_position === -1) {
			// find outside span tag but attached with closing span tag </span
			start_position = content.indexOf(`/span> ${word} `);
		}

		return start_position;
	}

	spellingMistakeContent = (mistake) => {
		return `<span class='essay rd-bg' aria-label='Spelling mistake: ${mistake.correct}' data-cooltipz-dir='bottom' data-cooltipz-size='custom'>${mistake.word}</span>`;
	}


	// 1. underline the grammar error.
	// 2. tooltip for grammar error.
	markGrammarMistakes = (content, mistakes) => {
		// removing more than one space from string.
		content = content.replace(/  +/g, ' ');

		for (let i = 0; i < mistakes.length; i++) {
			let mistake = mistakes[i];
			let start_position = content.indexOf(mistake.short_context);
			let end_position = (start_position + parseInt(mistake.short_context.length));

			let error_index = -1;
			if (mistake.error.length < 4) {
				error_index = this.findErrorIndex(content, mistake.error, start_position);
			} else {
				error_index = content.indexOf(mistake.error, start_position);
			}

			if ((error_index >= start_position) && (error_index < end_position)) {
				let replace_with = this.grammarMistakeContent(mistake);
				let nc = this.replaceRange(content, error_index, (error_index + mistake.error.length), replace_with);
				content = nc;
			}
		}
		return content;
	}

	replaceRange = (s, start, end, substitute) => {
		return s.substring(0, start) + substitute + s.substring(end);
	}

	grammarMistakeContent = (mistake) => {
		return `<span class='essay bl-bg' aria-label='Grammar mistake: ${mistake.correction}' data-cooltipz-dir='top' data-cooltipz-size='custom'>${mistake.error}</span>`;
	}

	afterSubmitResponseActionTwo = (data, state_obj) => {
		const { content_score, form_score, grammar_score, vocab_score, total_s, ling_score, struct_score, error, spelling_score, new_format } = data.data;
		if(this.state.questionData[0].subcategory_id === 6){
			state_obj.resultData = `Content : ${content_score}/2 \n Form : ${form_score}/1 \n Grammar and spellings : ${grammar_score}/2 \n Vocabulary : ${vocab_score}/2 \n Total : ${total_s}/7 \n ${error}`;
		} else {
			state_obj.resultData = `Content : ${content_score}/3 \n Form : ${form_score}/2 \n Grammar : ${grammar_score}/2 \n Vocabulary : ${vocab_score}/2 \n Linguistic range	: ${ling_score}/2 \n Structure : ${struct_score}/2 \n Spelling : ${spelling_score}/2 \n Total : ${total_s}/15 \n ${error}`;
		}
		state_obj.new_result = new_format;

		this.setState(state_obj);
	}

	afterSubmitResponseActionThree = (data, state_obj) => {
		const { score, from, new_format } = data.data;
		state_obj.resultData = `Score : ${score}/${from}`;

		state_obj.new_result = new_format;
		this.setState(state_obj);
	}

	afterSubmitResponseActionFour = (data, state_obj) => {
		const { questionData, currentQuestion } = this.state;
		const { new_format } = data.data;

		if(questionData[0].subcategory_id === 13) {
			const { content_score, form_score, grammar_score, vocab_score, spelling_score, total_s, error } = data.data;
			state_obj.resultData = `Content : ${content_score}/2 \n Form : ${form_score}/2 \n Grammar : ${grammar_score}/2 \n Vocabulary : ${vocab_score}/2 \n Spelling : ${spelling_score}/2 \n Total : ${total_s}/10 \n ${error}`;
		} else if (questionData[0].subcategory_id === 20) {
			const { score, from, html } = data.data;
			state_obj.resultData = `Score : ${score}/${from} \n ${html}`;
		} else {
			const { score, from } = data.data;
			state_obj.resultData = `Score : ${score}/${from}`;
		}

		state_obj.new_result = new_format;
		this.setState(state_obj);
	}

	showResponse = async () => {
		this.setState({
			is_answer: true,
			resultData: '',
			process: true,
			loader: true
		}, () => {
			let { questionData, resultObj } = this.state;

			var bodyFormData = new FormData();
			bodyFormData.set('text', resultObj.answer);
			bodyFormData.set('script', resultObj.script);
			bodyFormData.set('type', resultObj.type);
			bodyFormData.set('id', resultObj.id);
			bodyFormData.set('lang', resultObj.lang);
			bodyFormData.set('practice', '1');
			bodyFormData.set('device', 'website');

			let t = 0;
			if([1,2,3,4,5].includes(questionData[0].subcategory_id)) {
				bodyFormData.set('answer', resultObj.ans);
				bodyFormData.set('text_answer', resultObj.text_answer);
				bodyFormData.set('q_ans', resultObj.q_ans);
				bodyFormData.set('length', resultObj.length);
				bodyFormData.set('file', resultObj.data);
				bodyFormData.set('duration', resultObj.duration);
				bodyFormData.set('audio_text', resultObj.audio_text);

				// Add strategy only for read-aloud.
				if (parseInt(questionData[0].subcategory_id) === 1) {
					bodyFormData.set('strategy', resultObj.strategy);
				}

				t = 1;
			} else if([6,7].includes(questionData[0].subcategory_id)) {
				bodyFormData.set('answer', this.state.resultObj.q_ans);
				bodyFormData.set('text_answer', resultObj.text_answer);
				bodyFormData.set('length', resultObj.length);

				t = 2;
			} else if([8,9,10,11,12].includes(questionData[0].subcategory_id)) {
				bodyFormData.set('selected', resultObj.text_answer);
				bodyFormData.set('answer', resultObj.correct);
				bodyFormData.set('correct', resultObj.correct);

				t = 3;
			} else if([13,14,15,17,19,20,18,16].includes(questionData[0].subcategory_id)) {
				bodyFormData.set('text_answer', resultObj.text_answer);
				bodyFormData.set('answer', this.state.resultObj.correct);
				bodyFormData.set('selected', resultObj.text_answer);
				bodyFormData.set('correct', resultObj.correct);
				bodyFormData.set('html', resultObj.html);
				bodyFormData.set('ans', resultObj.q_ans);
				bodyFormData.set('length', resultObj.length);

				t = 4;
			}

			this.submitQuestionResponse(bodyFormData)
			.then(data => {
				if(data.response) {
					if(data.response.status === 403 && data.response.data.message === "Tokens finished" && data.response.data.tokens === 0) {
						this.setState({currTokens: data.response.data.tokens, loader: false});
						this.togglePopUp(true);
					}
				}
				const { user, tokens } = data.data;
				const questionSubCat = this.state.questionData[0].subcategory_id
				const token = speaking.includes(questionSubCat) ? tokens.speaking : 
					(
						writing.includes(questionSubCat) ?
						tokens.writing : 
						null
					)
				const { score_data } = data.data.data;

				// fetch explanations for subcategory_id = 11 and 12.
				if ([11, 12].includes(parseInt(resultObj.type))) {
					this.getOpenAIExplaination(bodyFormData);
				}
				
				if (user) {
					store.dispatch(setUser(user));
					this.loadAttemptedQuestiondata(this.state.currentQuestion);
				}

				let state_obj = {
					loader: false,
					user,
					historyData: score_data,
					process: false,
					user_text: null,
					currTokens: token,
					tokens,
				};
				switch(t) {
					case 1:
						this.afterSubmitResponseActionOne(data.data, state_obj);
						break;
					case 2:
						if (questionData[0].subcategory_id === 7) {
							this.essayResponse(data.data, state_obj);
						} else {
							this.afterSubmitResponseActionTwo(data.data, state_obj);
						}
						break;
					case 3:
						this.afterSubmitResponseActionThree(data.data, state_obj);
						break;
					case 4:
						this.afterSubmitResponseActionFour(data.data, state_obj);
						break;
					default:
						console.log('its default');
				};
				// this.explanationPopUp(true);
			}).catch(err => {
				console.log(err)
				this.setState({
					process: false,
					user_text: 'request failed. try again',
					is_answer: false
				});
				store.dispatch(setAlert({
		        	flag: true,
		        	type: 2,
		        	message: "Something went wrong."
		        }));
			});
		});
	}
	getOpenAIExplaination = async(formData) => {
		API({
			method: 'POST',
			url: `submit-explanation`,
			data: formData
		}).then(res => {
			this.setState({
				option_explaination: res.data
			});
		});
	  };

	explanationPopUp = (status) => {
		this.setState({explanationPopUp: status});
	}

	togglePopUp = (status) => {
		console.log(status);
		this.setState({tokensPopUp: status})
	}

	componentDidUpdate(prevProps) {
		if (this.props.expVidPopup !== prevProps.expVidPopup) {
			if(this.props.expVidPopup === 10 && !this.state.nextBtnVid) {
				this.nextButton(true);
			}
		}
	}

	nextButtonVidPopup = (status, showVid = false) => {
		if(!showVid) {
			this.setState({nextBtnVid: status}, () => {
				if(!status) {
					this.props.setExpVidPopup(10);
				}
			});
		} else {
			this.setState({nextBtnVid: status});
		}
	}

	resultStatus = () => {
		this.setState({is_result:true});
	}

	showHistory = (e) => {
		e.preventDefault();
		this.setState({boardMore:false,history_flag:true,note_flag:false,comment_flag:false,board_flag:false,history_process:false});
	}

	showBoard = (e) => {
		e.preventDefault();
		this.setState({historyMore:false,note_flag:false,history_flag:false,board_flag:true,comment_flag:false,board_process:false});
	}

	showNotes = (e) => {
		e.preventDefault();
		this.setState({historyMore:false,history_flag:false,board_flag:false,note_flag:true,comment_flag:false,board_process:false});
	}

	showComment = (e) => {
		e.preventDefault();
		this.setState({comment_flag:true,history_flag:false,board_flag:false,note_flag:false,comment_process:false});
	}

	changeResponseArr = (data) => {
		this.setState({history:data});
	}

	testedFunc = () => {
		this.setState({testModal:true});
	}

	toggleSwitch =(el,state) =>{
		this.setState({switched:(!this.state.switched)})
	}

	testedNo = () => {
		this.setState({testModal:false});
	}

	testedYes = () => {
		var bodyFormData = new FormData();
		bodyFormData.set('question_id', this.state.questionData[0].id);
		API({
			method: 'POST',
			url: 'tested/exam',
			data: bodyFormData
		}).then(data => {
			store.dispatch(setAlert({'flag':true,'type':1,'message':data.data.message}));
			this.setState({testModal:false, testCount:data.data.count});
		});
		this.setState({testModal:false});
		this.forceUpdate();
	}

	timer1 = () => {
		this.setState({ secondCount1: this.state.secondCount1 +1 });
	}

	restartQuestion = () => {
		this.setState({
			restart:true,
			is_result:false,
			is_answer:false,
			secondCount1:0,
			user_text: null,
			fail: undefined,
			new_html: null,
			submitFlag: false
		});
		this.forceUpdate();
		this.setState({option_explaination:''})
	}

	showVideoModal = () => {
		if(this.state.videoUrl) {
			this.setState({videoModal:true});
			this.setState({videoModalState:true});
		} else {
			this.setState({showBottomExplanationPopup: true});
		}
	}

	showHindiVideoModal = () => {
		if(this.state.videoHindiUrl) {
			this.setState({videoHindiModal:true});
			this.setState({videoHindiModalState:true});
		} else {
			this.setState({showBottomExplanationPopup: true});
		}
	}

	closeVideoModal = () => {
		this.setState({videoModal:false});
		// this.setState({videoModalState:false});
	}

	closeHindiVideoModal = () => {
		this.setState({videoHindiModal:false});
		// this.setState({videoModalState:false});
	}

	restartQue = () => {
		this.setState({restart:false,secondCount1:0});
	}

	changeInput = (value) => {
		var bodyFormData = new FormData();
		bodyFormData.set('question_id', this.state.questionData[0].id);
		bodyFormData.set('tag', value);

		bodyFormData.set('id', this.state.type);
		bodyFormData.set('prediction', this.state.prediction);
		bodyFormData.set('type', this.state.aType);
		bodyFormData.set('mark', this.state.mark);
		API({
			method: 'POST',
			url: 'set/tag',
			data: bodyFormData
		}).then(data => {
			let items = this.state.questionData;

			items[0] = {...this.state.questionData[0], tag:[{ tag: value }]};
			this.setState({
				questionData: items
			});
			//this.setState({'questionData':data.data.result.data});
			//this.loadQuestiondata();
			//store.dispatch(setAlert({'flag':true,'type':1,'message':data.data.message}));
			//this.setState({testModal:false, testCount:data.data.count});
		});
	};

	soundChecked = () => {}
	stopRecording = () => {}

	setStrategy = (st) => {
		const r = this.state.resultObj;
		r.strategy = st;
		this.setState({
			resultObj: r
		});
	}


	renderResponse = (items) => {
		return (
			<>
				{
					items.map((item, i) => {
						if (this.state.questionData[0].subcategory_id === 5) {
							return (
								<>
									<span>Your Response: </span><span className={item.class} key={i}>{item.word !== "&nbsp;" ? item.word : " "}</span>
								</>
							);
						} else {
							{/*return <span className={item.class} key={i}>{item.word !== "&nbsp;" ? item.word : " "}</span>;*/}
							return <TooltipItem key={i} item={item} id={i} />;
						}
					})
				}
			</>
		);
	}

	setRestart = () => {
		this.setState({ restart: false, secondCount: 0 });
	}


	selectScriptLanguage = (selectedValue) => {
		if (selectedValue !== "English" && selectedValue !== "0") {
			this.setState({ audio_script: 'Translating text. Please wait...' }, () => {
				API({
					url: 'translate/sentence',
					method: 'POST',
					data: {
						script: this.state.questionData[0].audio_script,
						lang: selectedValue,
						question_id: this.state.questionData[0].id
					}
				}).then((res) => {
					this.setState({
						currentScriptLanguage: selectedValue,
						audio_script: typeof res.data.translation === "object" ? res.data.translation.translation : res.data.translation
					});
				});
			});
		} else {
			this.setState({
				currentScriptLanguage: "English",
				audio_script: this.state.questionData[0].audio_script
			});
		}
	}


	wordDefinitionModalToggle = (status) => {
		if (status) {
			this.setState({
				modalDestroy: false,
				wordDefinitionModalIsOpen: status
			})
		} else {
			
			this.setState({
				wordDefinitionModalIsOpen: status
			}, () => {
				setTimeout(() => {
					this.setState({
						modalDestroy: true,
						selectedWord: ""
					});
				}, 1000);
			});
			
		}
	}


	selectWord = (item) => {
		this.wordDefinitionModalToggle(true);

		this.setState({
			selectedWord: item
		});
	}


	translatePopup = (status) => {
		this.setState({
			shadowingPopup: status,
			translateModal: status
		});
	}

	sampleResponseAudioPopup = (status) => {
		this.setState({
			shadowingPopup: status,
			sampleResponseModal: status
		});
	}

	filterScript = (question) => {

		let script = "";
		let q = question.split("__add_blank__");
		q.map((item, i) => {
			if (i === 0) {
				script += item;
			} else {
				let raw = item;
				item = item.replace("<span id='cAns'>", "");
				let word = item.split("</span>")[0];
				item = item.replace(`${word}</span>`, word);
				// console.log(item);
				script += item;
			}
		});

		return script;
	}

	questionScript = () => {
		let script = "";
		switch(this.state.questionData[0].subcategory_id) {
			case 1:
				script = this.state.questionData[0].question;
				break;
			case 2:
				script = this.state.questionData[0].audio_script;
				break;
			case 4:
				script = this.state.questionData[0].audio_script;
				break;
			case 11:
				script = this.filterScript(this.state.questionData[0].question);
				break;
			case 13:
				script = this.state.questionData[0].audio_script;
				break;
			case 16:
				script = this.state.questionData[0].audio_script;
				break;
		}
		return script;
	}


	buttonLabel = (subcategory_id) => {
		if (subcategory_id === 1) {
			return "Sample Response";
		} else {
			return "Hearing Mode";
		}
		return "";
	}


	getComplexity = (req_type, comp) => {
		let complexity = "";
		switch(comp) {
			case 0:
				complexity = req_type === "label" ? "Easy" : "easy";
				break;
			case 1:
				complexity = req_type === "label" ? "Medium" : "medium";
				break;
			case 2:
				complexity = req_type === "label" ? "Difficult" : "difficult";
				break;
		}
		return complexity;
	}


	/**
	 * Convert seconds to hours.
	 */
	secondsToHours = (seconds) => {
		return Math.floor(seconds / 3600);
	}

	/**
	 * Convert seconds to minutes.
	 */
	secondsToMinutes = (seconds) => {
		return Math.floor(seconds % 3600 / 60);
	}

	/**
	 * Convert seconds to seconds.
	 */
	secondsToSeconds = (seconds) => {
		return Math.floor(seconds % 3600 % 60);
	}


	tagIcon = (tag) => {
		const tags = {
			"green": TagIcon1,
			"red": TagIcon2,
			"yellow": TagIcon3,
			"grey": TagIconDefault
		}
		if (typeof tag !== "string" && typeof tag !== undefined) {
			tag = tag.length > 0 ? tag[0].tag : "grey";
		}
		return tags[tag] !== undefined ? tags[tag] : TagIconDefault;
	}

	tagLabel = (tag) => {
		const tags = {
			"green": "Green",
			"red": "Red",
			"yellow": "Yellow",
			"grey": "Grey"
		}
		if (typeof tag !== "string" && typeof tag !== undefined) {
			tag = tag.length > 0 ? tag[0].tag : "grey";
		}
		return tags[tag] !== undefined ? tags[tag] : "Grey";
	}

	setCorrectClassName = () => {
		let className = "";
		const subcategory_id = parseInt(this.state.type);
		
		if ([8, 9].includes(subcategory_id)) {
			className = "reading-section";
		} else if ([10].includes(subcategory_id)) {
			className = "re-order-section";
		} else if ([13].includes(subcategory_id)) {
			className = "write-essay-wrapper";
		} else if ([16].includes(subcategory_id)) {
			className = "lf-blank";
		}

		return className;
	}


	render() {
		let que;
		let ques_title = "";
		let title;
		let topBar = false;

		let {
			questionData, currentQuestion, type, all_ok, modal, reportText, testModal, videoModal, videoModalState, videoUrl,
			videoHindiModal, videoHindiModalState, videoHindiUrl, is_answer, tag, timeDiffInMs, thirtyDaysInMs, isAttempted,
			secondCount1, hideNav, total_questions, isBlocked, resultData, historyData, resultId, switched, isSubscribed,
			user, submitFlag, viewVideo, viewHindiVideo, testCount, comment_flag, isSubscribedCheck, history_flag, board_flag,
			note_flag, open_ques, restart, user_text, fail, content_score, pronunciation_score, fluency_score, intonation_score,
			average_pace, total_score, writing_result, new_result, option_explaination
		} = this.state;

		let props = {
			restart,
			restartQue: this.restartQue,
			submit: this.submitResponse,
			showResult: this.resultStatus,
			data: questionData[0],
			func: this.changeAnswer,
			answer: is_answer,
			resultMode: false,
			testCount: 0,
			tag: 'grey',
			testMode: false,
			resultId: false,
			soundChecked: this.soundChecked,
			stopRecording: this.state.stopRecording,
			user_text,
			strategy: this.state.resultObj.strategy,
			setStrategy: this.setStrategy,
			setRestart: this.setRestart,
			scriptLanguages: this.state.scriptLanguages,
			voices: this.state.voices,
			shadowingPopup: this.state.shadowingPopup,
			total_questions,
			currentQuestion
		};

		if (questionData.length > 0) {
			ques_title = questionData[0].q_title;
		}

		switch(type) {
			case "1":
				que = <ReadAloud languages={this.state.scriptLanguages} {...props} key={restart || currentQuestion} />;
				title = "Look at the text below. In the 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 35 seconds to read aloud.";
				break;
			case "2":
				que = <RepeatSentece {...props} key={restart || currentQuestion} />;
				title = "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.";
				break;
			case "3":
				que = <DescribeImage {...props} key={restart || currentQuestion} />;
				title = "Look at the Image below. In 25 seconds,please speak into the microphone and describe in detail what the graph showing. You will have 40 seconds to give your response.";
				break;
			case "4":
				que = <RetellLec {...props} key={restart || currentQuestion} />;
				title = "You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.";
				break;
			case "5":
				que = <ShortQue {...props} key={restart || currentQuestion} />;
				title = "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.";
				break;
			case "6":
				que = <Summary {...props} key={restart || currentQuestion} />;
				title = "Read the passage and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. your response will be judge on the quality of your writing and on how well your response presents the key points in the passage.";
				break;
			case "7":
				que = <Essay {...props} key={restart || currentQuestion} />;
				title = "You will have 20 minutes to plan, write and revise an essay about the topic below. your response will be judged on how well you develop a position, oraganize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.";
				break;
			case "8":
				topBar = true;
				que = <SingleAnswer {...props} key={restart || currentQuestion} />;
				title = "Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
				break;
			case "9":
				topBar = true;
				que = <MultipleAnswer {...props} key={restart || currentQuestion} />;
				title = "Read the text and answer the question by selecting all the correct responses. More than one response is correct.";
				break;
			case "10":
				que = <Reorder {...props} key={restart || currentQuestion} />;
				title = "The textbox in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.";
				break;
			case "11":
				topBar = true;
				que = <ReadingBlank {...props} languages={this.state.scriptLanguages} option_explaination={this.state.option_explaination} key={restart || currentQuestion} />;
				title = "In the text below some words are missing. Drag words from the text box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.";
				break;
			case "12":
				topBar = true;
				que = <WrittingBlank {...props} languages={this.state.scriptLanguages} option_explaination={this.state.option_explaination} key={restart || currentQuestion} />;
				title = "Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.";
				break;
			case "13":
				que = <Summer {...props} key={restart || currentQuestion} />;
				title = "You will hear a short lecture. Write a summary for a fellow student who was not present at the lecture. You should write 50-70 words. You have 10 minutes to finish the task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.";
				break;
			case "14":
				topBar = true;
				que = <McqSingle {...props} key={restart || currentQuestion} />;
				title = "Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
				break;
			case "15":
				topBar = true;
				que = <McqMulti {...props} key={restart || currentQuestion} />;
				title = "Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.";
				break;
			case "16":
				topBar = true;
				que = <ListeningBlank {...props} key={restart || currentQuestion} />;
				title = "You will hear a recording. Type the missing words in each blank.";
				break;
			case "17":
				topBar = true;
				que = <CorrectSummary {...props} key={restart || currentQuestion} />;
				title = "You will hear a recording. Click on the paragraph that best relates to the recording.";
				break;
			case "18":
				topBar = true;
				que = <MissingWord {...props} key={restart || currentQuestion} />;
				title = "You will hear a recording about "+ ques_title+". At the end of the recording the last word or group of words has benn replaced by a beep. Select the correct option to complete the recording";
				break;
			case "19":
				topBar = true;
				que = <Highlight {...props} key={restart || currentQuestion} />;
				title = "You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.";
				break;
			case "20":
				que = <Dictation {...props} key={restart || currentQuestion} />;
				title = "You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.";
				break;
			default:
				// code block
		}

		let content_class_name = "";
		if (content_score !== undefined) {
			if (parseInt(type) !== 5) {
				content_class_name = content_score.score <= 30 ? 'text-danger' : (content_score.score > 30 && content_score.score <= 70 ? 'text-orange' : 'text-success');
			} else {
				content_class_name = content_score.score < 1 ? 'text-danger' : 'text-success';
			}
		}


		let speak_result_show = false;
		let write_result_show = false;
		let reading_result_show = false;
		let listening_result_show = false;
		
		if ([1, 2, 3, 4, 5].includes(parseInt(type))) {
			speak_result_show = new_result !== null ? true : false; // also for type 20.
		}
		
		if ([6, 7, 13].includes(parseInt(type))) {
			write_result_show = new_result !== null ? true : (new_result !== null ? true : false); // also for type 13.
			// console.log('write_result_show', write_result_show);
		}

		if ([8, 9, 10, 11, 12].includes(parseInt(type)) && new_result !== null) {
			reading_result_show = true;
		}

		if ([14, 15, 16, 17, 18, 19, 20].includes(parseInt(type)) && new_result !== null) {
			listening_result_show = true;
		}

		return (
			<>
				<Helmet>
					<title>Top PTE Training Institute</title>
				</Helmet>

				{!all_ok && <ChromeCheck />}

				{!this.state.connected &&
		  		  <div className="connectivity-container">
		  		  	{/*<div className="bg-danger text-light not-connected text-center p-1">You are not connected to internet. please wait for the connection to be restored.</div>*/}
					<div className="bg-danger text-light not-connected text-center p-1">Seems like you are not connected to our server. Please try reloading the page or check your connection.
						&nbsp; <button className="btn-success btn-sm">Connect</button>
					</div>
		  		  </div>
		  	    }

				{this.state.connected && this.state.backOnline &&
		  		  <div className="connectivity-container">
		  		  	<div className="bg-success text-light not-connected text-center p-1"><b>Connection restored. Please continue with your tasks.</b></div>
		  		  </div>
		  	    }

				{(all_ok && questionData.length > 0) &&
					<>
						<div className="siteContent p-0">
							<DashboardHeader title="Practice Material" />

							<div className={`pmw ${this.setCorrectClassName()}`}>
								<div className="dashboard-content-wrapper read-aloud-wrapper mb-12">
									<Card>
										<CardHeader className="p-0">
											<CardTitle tag="h4">{questionData[0].q_title}</CardTitle>
											<div className="read-aloud-header pmw-header">
												<div className="strategy-video">
													<div className="strategy-video-img">
														<img src={VideoImg} alt="Question Strategy Video" />
													</div>
													<span>Question Strategy Video</span>
												</div>
												<span className={`complexity-badge ${this.getComplexity("class", questionData[0].complexity)}`}>{this.getComplexity("label", questionData[0].complexity)}</span>
												<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown} direction="down">
												  <DropdownToggle tag="span" className="badge-dropdown"><img src={this.tagIcon(questionData[0].tag)} alt="Image" /> {this.tagLabel(questionData[0].tag)}</DropdownToggle>
												  <DropdownMenu>
													{TAGS.map((tag, i) => {
														let item = questionData[0].tag.length > 0 ? questionData[0].tag[0].tag : "grey";
														if (item !== tag) {
															return (<DropdownItem key={i} onClick={() => this.changeInput(tag)}><img src={this.tagIcon(tag)} alt="Image" /> {this.tagLabel(tag)}</DropdownItem>)
														}
													})}
												  </DropdownMenu>
												</Dropdown>

												<div className="time-spent">
													<img src={TagIcon1} alt="Badge" /><span>Time Spent:</span> {this.secondsToHours(secondCount1)}h : {this.secondsToMinutes(secondCount1)}m : {this.secondsToSeconds(secondCount1)}s
												</div>
											</div>
											<ItemNumber
												currentQuestion={currentQuestion}
												total_questions={total_questions}
											/>
										</CardHeader>
										<CardBody>
											<p className="gray-text">{title}</p>
										</CardBody>
									</Card>
								</div>

								<div className="question-area-main">
									{que}
								</div>

								<Result
									isAnswer={is_answer}
									resultData={resultData}
									speakingResult={speak_result_show}
									writingResult={write_result_show}
									readingResult={reading_result_show}
									listeningResult={listening_result_show}
									fail={fail}
									newResult={new_result}
									newHtml={this.state.new_html}
									subcategoryId={parseInt(type)}
									renderResponse={this.renderResponse}
									correctAnswer={questionData[0].answer}
									option_explaination={option_explaination}
									historyData={historyData}
								/>

								<div className="d-flex align-items-center justify-content-between  pmw-outer-wrapper">
									<div className="left-btns">
										<a href="javascript:void(0)" className="outline-btn" onClick={this.restartQuestion}>Restart</a>
										<a
											href="javascript:void(0)"
											className="submit-btns"
											onClick={this.showResponse}
											disabled={is_answer || !submitFlag}
										>Submit</a>
									</div>
									<div className="right-btns"> 
										<select onChange={this.changeSelectPagination} value={currentQuestion} className="badge-dropdown">
											{Array.from(Array(total_questions), (e, i) => {
												const item = (i + 1);
												return (<option key={i} value={item}>{item}</option>)
											})}
										</select>
										<a href="javascript:void(0)" onClick={this.prevButton} disabled={(currentQuestion === 1)} className="prev-next-btn">Previous</a>
										<a href="javascript:void(0)" onClick={() => this.nextButton(false)} disabled={(total_questions === currentQuestion)} className="prev-next-btn">Next</a>
									</div>
								</div>

								<div className="pmw-outline-btns pmw-outer-wrapper d-flex align-items-center justify-content-between ">
									<div className="outline-left-btns">
										{TRANSLATE_BUTTON_SUBCATEGORIES.includes(questionData[0].subcategory_id) &&
											<a
												href="javascript:void(0)"
												className="outline-btn"
												onClick={() => this.translatePopup(true)}
											>Translate</a>
										}
										{SAMPLE_RESPONSE_BUTTON_SUBCATEGORIES.includes(questionData[0].subcategory_id) &&
											<a
												href="javascript:void(0)"
												className="outline-btn"
												onClick={() => this.sampleResponseAudioPopup(true)}
											>{this.buttonLabel(questionData[0].subcategory_id)}</a>
										}
									</div>
									<div className="outline-right-btns"> 
										<a href="javascript:void(0)" className="outline-btn">{questionData[0].attempted.length} X Attempted</a>
										<a href="javascript:void(0)" className="outline-btn">{testCount} X Tested</a>
										<a href="javascript:void(0)" onClick={this.showModal} className="outline-btn">Report</a>
									</div>
								</div>

								{questionData.length !== 0 &&
									<QuestionResponses
										loadMyData={this.showHistory}
										loadOthersData={this.showBoard}
										questionData={questionData[0]}
										key={restart || currentQuestion}
									/>
								}
								
							</div>


							<ExplanationPopUp 
								status={this.state.nextBtnVid} 
								toggle={this.nextButtonVidPopup} 
								isSubscribed={this.state.isSubscribed}
								expVid={this.state.expVid}
							/>

							{this.state.translateModal &&
								<TranslateModal
									isOpen={this.state.shadowingPopup}
									toggle={this.translatePopup}
									question={this.questionScript()}
									languages={this.state.scriptLanguages}
									question_id={this.state.questionData[0].id}
									voices={this.state.voices}
								/>
							}

							{this.state.sampleResponseModal &&
								<ListenAudio
									isOpen={this.state.shadowingPopup}
									toggle={this.sampleResponseAudioPopup}
									question={this.questionScript()}
									question_id={this.state.questionData[0].id}
									voices={this.state.voices}
									audios={questionData[0].question_audios}
									subcategory_id={this.state.questionData[0].subcategory_id}
								/>
							}

							{/*<Row>
								<Col xs={12}>
									<Card>
										<CardHeader>
											{topBar && is_answer &&
												<div className="row infoBars">
													<div className="col-sm-3 wrongAns">
														<i className="fa fa-times" aria-hidden="true"></i> <span>Your selected & wrong answer</span>
													</div>
													<div className="col-sm-3 correctAns">
														<i className="fa fa-check" aria-hidden="true"></i> <span>Your selected & correct answer</span>
													</div>
													<div className="col-sm-3 notSelected">
														<i className="fa fa-check" aria-hidden="true"></i> <span>Not selected & correct answer</span>
													</div>
												</div>
											}

											<Row>
												<Col xs={4}>
													<CardTitle tag="h3">
														{questionData.length > 0 && questionData[0].q_title}
														{questionData.length > 0 &&
															<select name="tag" onChange={(e) => { this.changeInput(e)} } style={{border: '0px',background: 'white', color: tag}} ref={(node) => {if (node) node.style.setProperty("font-family", "FontAwesome", "important")}}>
																<option value="grey" style={{color: 'grey'}}>&#xf02c;</option>
																<option value="red" style={{color: 'red'}}>&#xf02c;</option>
																<option value="green" style={{color: 'green'}}>&#xf02c;</option>
																<option value="yellow" style={{color: 'yellow'}}>&#xf02c;</option>
															</select>
														}
														{timeDiffInMs >= thirtyDaysInMs ? '' : <img src="/images/new-question-tag.png" className='new-question-image'/>}
													</CardTitle>

													{questionData.length > 0 && questionData[0].is_weekly_prediction === 1 &&
														<Badge color="primary">Monthly Prediction</Badge>
													}
													{questionData.length > 0 && questionData[0].is_exam_question === 1 &&
														<Badge color="primary">Exam Question</Badge>
													}
													{questionData.length > 0 && questionData[0].complexity === 0 &&
														<Badge color="primary">Easy</Badge>
													}
													{questionData.length > 0 && questionData[0].complexity === 1 &&
														<Badge color="primary">Medium</Badge>
													}
													{questionData.length > 0 && questionData[0].complexity === 2 &&
														<Badge color="primary">Difficult</Badge>
													}
													{isAttempted &&
														<Badge color="success">Attempted</Badge>
													}
												</Col>
												<Col xs={4}>
													<h3>&nbsp;</h3>
													<div className="question-strategy-video">
														<Button onClick={() => this.explanationPopUp(true)}>Question Strategy Video</Button>
													</div>
												</Col>
												<Col xs={4}>
													<div className="text-right questionTime">
														<div className="timeRemaining">
															<p>
																<b>
																	<i className="fa fa-clock-o"></i>
																	<span>Time spent: {Math.floor(secondCount1 / 60) +':'+ (secondCount1 % 60)}</span>
																</b>
															</p>
														</div>
														<div className="item">
															{!hideNav &&
																<p>Item {currentQuestion} of {total_questions}</p>
															}
														</div>
													</div>
												</Col>
											</Row>
											{title}
										</CardHeader>

										<CardBody>
											{questionData.length ===0 &&
												<h5 className="text-center">No Question Found</h5>
											}

											<Row className='unselectable'>
												{!isBlocked && questionData.length !==0 && store.getState().loaded && questionData &&
													<>
														{que}
													</>
												}
												{isBlocked &&
													<h2>Please attach microphone to your system </h2>
												}

												{is_answer && resultData !== '' &&
													<Col xs={12}>
														<div className="result-div">
															{fail &&
																<h3 className="text-danger">{ReactHtmlParser(fail)}</h3>
															}

															{speak_result_show &&
																<SpeakingResult resultData={new_result} />
															}

															{write_result_show &&
																<WritingResult
																	resultData={new_result}
																	buttonsOnly={false}
																	essay={parseInt(type) === 7 ? true : false}
																/>
															}

															{reading_result_show &&
																<ReadingResult resultData={new_result} />
															}

															{listening_result_show &&
																<ListeningResult resultData={new_result} />
															}

															{this.state.new_html !== null &&
																<h4>
																	{this.renderResponse(this.state.new_html)}
																</h4>
															}

															{new_result !== null && new_result !== undefined && 'html' in new_result &&
																<h4 className="para-form">{ReactHtmlParser(new_result.html)}</h4>
															}

														
														{write_result_show && parseInt(type) === 7 &&
															<div className="row">
																<WritingResult
																	resultData={new_result}
																	buttonsOnly={true}
																/>
															{(type === '3' || type === '4') &&
																<div>
																	<div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW" >
																		<div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
																			<div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd" ></div>
																			Good
																		</div>
																		<div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
																			<div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJav"></div>
																			Average
																		</div>
																	</div>
																</div>
															}
															{(type === '1' || type === '2')  &&
																<div>
																	<div className="AIScoreCom__Category-sc-1yzm0aw-12 liAXDW" >
																		<div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
																			<div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJgd" ></div>
																			Good
																		</div>
																		<div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
																			<div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJav"></div>
																			Average
																		</div>
																		<div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca">
																			<div className="AIScoreCom__Circle-sc-1yzm0aw-10 iyKrJbad"></div>
																			Bad pronunciation/Missed words
																		</div>
																		<div className="AIScoreCom__CategoryItem-sc-1yzm0aw-11 ffgUca"></div>
																	</div>
																</div>
															}

															<ShowHistoryData
																data={historyData}
																flag={1}
																id={resultId}
																openAiRemarks={new_result}
																option_explaination={this.state.option_explaination}
																type={type}
															/>
														
															{type === '5' &&
																<h3>Correct Answer - <span className="green-text">{questionData[0].answer}</span></h3>
															}
														</div>
													</Col>
												}
											</Row>

											{switched &&
												<span className="unselectable script-options">
													<div className="mb-4">
														<h3>Question Audio</h3>
														{'question_audios' in questionData[0] && questionData[0].question_audios.length > 0 ? (
															<audio src={`${s3base}/${questionData[0].question_audios[0].value}`} controls="controls" />
														) : (
															<audio src={s3base + questionData[0].media_link} controls="controls" />
														)}
													</div>
													<div className="mb-2 row">
														<h3>Audio Script</h3>
														<div className="col audio_script_text">
															{questionData[0].audio_script !== null && questionData[0].audio_script.split(" ").map((item, i) => {
																return (
																	<>
																		<span
																			className="word"
																			key={i}
																			onClick={() => this.selectWord(item)}
																		>{item}</span><i> </i>
																	</>
																)
															})}

														</div>
													</div>
												</span>
											}
										</CardBody>
									</Card>
								</Col>

								<Col xs={12}>

									{user_text !== null &&
										<div className="bg-danger text-light p-1">
											<h4>Submission failed. Please try again.</h4>
										</div>
									}

									{false && !isSubscribed &&
										<span className="badge badge-primary">(A.I. Token(s) X {user.a_token})</span>
									}

									{!isBlocked && questionData.length !==0 &&
										<div className="bottomBtns">
											<div className="row">
												<Col xs={8}>
													{false && !isSubscribed && user.a_token > 0 && !this.state.process && <Button color="primary" className="bottom-btn" disabled={is_answer || !submitFlag} onClick={this.showResponse}  size="sm">Submit</Button>}
													{ !this.state.process && 
													<>{
														this.state.currTokens !== 0 ?
															<Button color="primary" className="bottom-btn" disabled={is_answer || !submitFlag} onClick={this.showResponse}  size="sm">Submit</Button>
															: <Button color="primary" className="bottom-btn" disabled={is_answer || !submitFlag} onClick={this.togglePopUp.bind(this, true)}  size="sm">Submit</Button>	
													}</> 
													}
													{false && !isSubscribed && user.a_token <= 0 && !this.state.process && <Link to="/video-courses"><Button color="primary" className="bottom-btn"   size="sm">Subscribe to paid plan for submit answer</Button></Link>}
													{this.state.process && <Button color="primary" className="bottom-btn"  size="sm"><i className="fa fa-spinner"></i>&nbsp;Submit</Button>}
													{<Button color="info" className="bottom-btn" onClick={this.restartQuestion} disabled={false} size="sm">Restart</Button>}
													{viewVideo ? <Button color="info" className="bottom-btn" onClick={this.showVideoModal} disabled={false} size="sm">Explanation (English)</Button>:''}
													{viewHindiVideo ? <Button color="info" className="bottom-btn" onClick={this.showHindiVideoModal} disabled={false} size="sm">Explanation (Hindi)</Button>:''}
													{[4,2,5,13,14,15,16,17,18,19,20].includes(questionData[0].subcategory_id) && <span className="audio_script_button">Audio Transcript : <Switch onText="Show" offText="Hide" onChange={(el, state) => this.toggleSwitch(el, state)} on={switched}></Switch></span>}
												</Col>

												{!hideNav &&
													<Col xs={4} className="text-right">
														<select className="selectPage" onChange={this.changeSelectPagination} >
															{Array.from({ length: total_questions }, (_, key) => <option key={key} value={key+1} >{key+1}</option>)}
														</select>
														{ questionData.length !== 0 &&
															<Button color="info" className="bottom-btn" onClick={this.prevButton} disabled={ (currentQuestion === 1) } size="sm">Previous</Button>
														}
														{ questionData.length !== 0 &&
															<Button color="info" className="bottom-btn" onClick={this.nextButton.bind(this, false)} disabled={(total_questions===currentQuestion) } size="sm">Next</Button>
														}
													</Col>
												}
												{hideNav && <Col xs={4}></Col>}
											</div>
										</div>
									}
								</Col>

								{this.state.tokensPopUp &&
									<TokenFinished isOpen={this.state.tokensPopUp} toggle={this.togglePopUp} />
								}

								{
									this.state.explanationPopUp &&
									<ExplanationVidPopUp
										status={this.state.explanationPopUp} 
										toggle={this.explanationPopUp} 
										isSubscribed={this.state.isSubscribed}
										expVid={this.state.expVid}
									/>
								}
								
								<ExplanationPopUp 
									status={this.state.nextBtnVid} 
									toggle={this.nextButtonVidPopup} 
									isSubscribed={this.state.isSubscribed}
									expVid={this.state.expVid}
								/>

								{ this.state.showBottomExplanationPopup &&
									<ModalSkeleton
										status={this.state.showBottomExplanationPopup}
										toggle={() => this.setState({showBottomExplanationPopup: false})}
										handleClick={() => {this.props.history.push("/admin/sub/payment/form/37")}}
										btnText="Buy Plan"
										message="Please subscribe to our gold plan to see Explanation Video"
										isSmall={false}
									/>
								}

								{this.state.translateModal &&
									<TranslateModal
										isOpen={this.state.shadowingPopup}
										toggle={this.translatePopup}
										question={this.questionScript()}
										languages={this.state.scriptLanguages}
										question_id={this.state.questionData[0].id}
										voices={this.state.voices}
									/>
								}

								{this.state.sampleResponseModal &&
									<ListenAudio
										isOpen={this.state.shadowingPopup}
										toggle={this.sampleResponseAudioPopup}
										question={this.questionScript()}
										question_id={this.state.questionData[0].id}
										voices={this.state.voices}
										audios={questionData[0].question_audios}
										subcategory_id={this.state.questionData[0].subcategory_id}
									/>
								}

								{this.state.wordDefinitionModalIsOpen &&
									<WordDefinitionModal
										isOpen={this.state.wordDefinitionModalIsOpen}
										toggle={this.wordDefinitionModalToggle}
										word={this.state.selectedWord}
									languages={this.state.scriptLanguages}
									/>
								}
								

								<Col xs={12}>
									<div>
										<>
											<div>
												<>
													<div className="bottomBtns clearfix">
														<div className="pull-left">
															{questionData[0] !== undefined && TRANSLATE_BUTTON_SUBCATEGORIES.includes(questionData[0].subcategory_id) &&
																<Button
																	color="info"
																	className="bottom-btn"
																	size="sm"
																	onClick={() => this.translatePopup(true)}
																>Translate</Button>
															}
															{questionData[0] !== undefined && SAMPLE_RESPONSE_BUTTON_SUBCATEGORIES.includes(questionData[0].subcategory_id) &&
																<Button
																	color="info"
																	className="bottom-btn"
																	size="sm"
																	onClick={() => this.sampleResponseAudioPopup(true)}
																>{this.buttonLabel(questionData[0].subcategory_id)}</Button>
														}
														{questionData[0] !== undefined && (parseInt(questionData[0].subcategory_id) === 7) &&
															<Row>
																<MoreAiSuggestions
																	question_id={this.state.questionData[0].id}
																/>
															</Row>
															}
														</div>
														<div className="pull-right">
															{questionData.length !==0 &&
																<Button
																	color="info"
																	className="bottom-btn"
																	size="sm"
																>{questionData[0].attempted.length} x Attempted</Button>
															}
															{questionData.length !==0 &&
																<Button
																	color="info"
																	onClick={this.testedFunc}
																	className="bottom-btn"
																	size="sm"
																>{testCount} x tested</Button>
															}
															{
																this.state.questionData.length > 0 && 
																this.state.currTokens !== null && 
																this.state.currTokens > -1 && (
																	<span className="border ml-1 p-2" style={{borderRadius: "50px"}}>
																		<img width={"25"} className="mr-2 mb-1" src="/images/ai.svg" atl="" />AI Score X {" "}
																		{
																			this.state.currTokens
																		}
																	</span>
																)
															}
															{questionData.length !== 0 &&
																<Button
																	color="info"
																	className="bottom-btn"
																	onClick={this.showModal}
																	disabled={false}
																	size="sm"
																>Report</Button>
															}
														</div>
														<div className="clear"></div>
													</div>
												</>
												<Row className="discussion row ml-2">
													<div className="discuTabs">
														<ul className="nav nav-tabs">
															<li className={history_flag ? "active" : ""}>
																{questionData.length !== 0 &&
																	<a href="#" onClick={this.showHistory}><i className="fa fa-list-ul" aria-hidden="true"></i> &nbsp;Me</a>
																}
															</li>
															<li className={board_flag ? "active" : ""}>
																{questionData.length !== 0 &&
																	<a href="#" onClick={this.showBoard}><i className="fa fa-list-ul" aria-hidden="true"></i> &nbsp;Others</a>
																}
															</li>
														</ul>
													</div>
												</Row>
											</div>
										</>
									</div>
								</Col>

								<Col xs={12} className="practiceContent">
									<div>
										{note_flag &&
											<UserNotes question={questionData[0].id} />
										}
										{history_flag &&
											<ShowHistory all="0" changeResponseArr={this.changeResponseArr} question={questionData[0].id} flag={0} />
										}
										{board_flag &&
											<ShowHistory all="1" changeResponseArr={this.changeResponseArr} question={questionData[0].id} flag={0} />
										}
										{comment_flag &&
											<QuestionComment question={questionData[0].id} />
										}
									</div>
								</Col>
							</Row>*/}
							<LoaderDumb show={!this.state.loader} />
						</div>


						{modal &&
							<Modal isOpen={modal} size="lg">
								<ModalBody>
									<ModalHeader>Report the problem in this question </ModalHeader>
									<Input type="textarea" value={reportText} name="reportText" onChange={(e)=>{this.changeReportText(e);}} />
								</ModalBody>
								<ModalFooter>
									<Button color="secondary" className="modalCloseBtn" onClick={this.submitReport}>Submit</Button>
									<Button color="secondary" className="modalCloseBtn" onClick={this.showModal}>Close</Button>
								</ModalFooter>
							</Modal>
						}

						<Modal isOpen={testModal} size="lg">
							<ModalHeader>Do you have seen this question in exam </ModalHeader>
							<ModalFooter>
								<Button color="secondary" className="modalCloseBtn" onClick={this.testedYes}>Yes</Button>
								<Button color="secondary" className="modalCloseBtn" onClick={this.testedNo}>No</Button>
							</ModalFooter>
						</Modal>

						{videoModal &&
							<Modal className="videopopup" centered="true" isOpen={videoModalState} size="lg">
								<ModalBody>
									<ReactPlayer url={videoUrl} controls={true}/>
								</ModalBody>
								<ModalFooter>
									<Button color="secondary" className="modalCloseBtn" onClick={this.closeVideoModal}>Close</Button>
								</ModalFooter>
							</Modal>
						}

						{videoHindiModal &&
							<Modal className="videopopup" centered="true" isOpen={videoHindiModalState} size="lg">
								<ModalBody>
									<ReactPlayer url={videoHindiUrl} controls={true}/>
								</ModalBody>
								<ModalFooter>
									<Button color="secondary" className="modalCloseBtn" onClick={this.closeHindiVideoModal}>Close</Button>
								</ModalFooter>
							</Modal>
						}
					</>
				}
			</>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Practice);
