import React, { useState, useEffect } from 'react';
import {
	Button,
	Progress
} from 'reactstrap';
import Sound from 'react-sound';
import MicRecorder from 'mic-recorder-to-mp3';
import SpeechRecognition from 'react-speech-recognition';
import getBlobDuration from 'get-blob-duration';
import { s3base } from "api.js";

import SpeechToText from "components/Question/Speaking/New/SpeechToText";

let Mp3Recorder = new MicRecorder({ bitRate: 128 });
const WAITING = 35;
const LIMIT = 35;

export default function FunctionBox(props) {

	const [waiting, setWaiting] = useState(true);
	const [beep, setBeep] = useState(false);
	const [recording, setRecording] = useState(false);
	const [requireTranscript, setRequireTranscript] = useState(false);
	const [trans, setTrans] = useState("");
	const [processComplete, setProcessComplete] = useState(false);
	const [file, setFile] = useState(null);
	const [duration, setDuration] = useState(0);
	const [blobUrl, setBlobUrl] = useState(0);


	/**
	 * ComponentWillUnmount
	 * stop listening.
	 * Stop Recording.
	 */
	useEffect(() => {
		return (() => {
			SpeechRecognition.stopListening();
			Mp3Recorder.stop();
		});
	}, []);

	const updateWaitingStatus = (status) => {
		setWaiting(status);
		setBeep(true);
	}

	const beepComplete = () => {
		setBeep(false);
		setRecording(true);
	}

	const updateRecordingStatus = (status) => {
		setRecording(status);
	}


	const stopRecordingAndListening = () => {
		if (recording) {
			SpeechRecognition.stopListening();
			requireTranscriptSet(true); // save transcript to this component.
			
			Mp3Recorder
			.stop()
			.getMp3()
			.then(async ([buffer, blob]) => {
				
				const blobURL = URL.createObjectURL(blob);
				const duration = await getBlobDuration(blobURL);
				const file = new File([blob], 'noname.mp3', { type: blob.type });
				setFile(file);
				setDuration(duration);
				setBlobUrl(blobURL);

				// props.blobUrlSet(blobURL);
				setProcessComplete(true);
	        });
		} else {
			setProcessComplete(true);
		}
	}

	/**
	 * Skip waiting timer.
	 */
	const skip = () => {
		setWaiting(false);
		setBeep(true);
	}

	/**
	 * Stop Recording.
	 */
	const stop = () => {
		updateRecordingStatus(false);
		stopRecordingAndListening();
	}


	const requireTranscriptSet = (status) => {
		setRequireTranscript(status);
	}


	/**
	 * Transcript.
	 */
	const setTranscript = (transcript) => {
		setTrans(transcript);
		requireTranscriptSet(false);
	}


	/**
	 * recording hook.
	 */
	useEffect(() => {
		if (recording) {
			Mp3Recorder
	        .start()
	        .then(() => {
	        	SpeechRecognition.startListening({ continuous: true, language: 'en-GB' });
	        });
		}
	}, [recording]);



	/**
	 * Recording Process Complete.
	 */
	useEffect(() => {
		if(processComplete) {
			setTimeout(() => {
				props.submitReadySet({
	        		file,
	        		duration,
	        		blobUrl,
	        		transcript: trans
	        	}); // send message to main read-aloud file that we are ready to submit this question.
			}, 100);
			
		}
	}, [processComplete]);


	/**
	 * stopRecording from parent components
	 * For mocks.
	 */
	useEffect(() => {
		if (props.stopRecording === true) {
			setWaiting(false);
			stop();
		}
	}, [props.stopRecording]);

	
	return (
		<>

			<SpeechToText
				saveTranscript={requireTranscript}
				setTranscript={setTranscript}
			/>

			<div className="audio-box">
				<h4>Recorded Answer</h4>
				<p><strong>Current status:</strong></p>
				<div>
					{waiting &&
						<Waiting
							waiting={updateWaitingStatus}
							pauseWaiting={props.pauseWaiting}
						/>
					}

					{beep &&
						<Sound
							url={`${s3base}/ptedata/ptemedia/beep.mp3`}
							playStatus={Sound.status.PLAYING}
							onFinishedPlaying={() => beepComplete()}
						/>
					}

					{recording &&
						<span>Recording Started</span>
					}

					{!waiting && !beep && !recording &&
						<span>Recording Stopped.</span>
					}
				</div>

				{!props.testMode && waiting &&
					<div className='stop-btn'>
						<Button color="success" onClick={() => skip()} size="sm">Skip</Button>
					</div>
				}

				{recording &&
					<>
						{!props.testMode && 
							<div className='stop-btn'>
								<Button color="success" onClick={() => stop()} size="sm">Stop</Button>
							</div>
						}

						<RecordingProgress
							stop={stop}
						/>
					</>
				}

				<div className="audo-line">
					<span className="progress-bar-fill-Read intro"></span>
				</div>
			</div>
		</>
	);
}


/**
 * Waiting.
 */
function Waiting(props) {
	const [seconds, setSeconds] = useState(WAITING);
	const [timerId, setTimerId] = useState(null);
	const [init, setInit] = useState(false);

	const watingTimer = (isSubscribed) => {
		const id = setInterval(() => {
			if (isSubscribed) {
				setSeconds(seconds => (seconds - 1));
			}
		}, 1000);
		setTimerId(id);
	};


	useEffect(() => {
		const { pauseWaiting } = props;
		if (pauseWaiting) {
			clearInterval(timerId);
		} else if (init) {
			watingTimer(true);
		}
	}, [props.pauseWaiting]);


	useEffect(() => {
		let isSubscribed = true;

		watingTimer(isSubscribed);

		// set init value after 1s.
		setTimeout(() => {
			setInit(true);
		}, 1000);

		// component will unmount.
		return (() => {
			clearInterval(timerId);
			isSubscribed = false;
		});
	}, []);


	useEffect(() => {
		if (seconds === 0) {
			props.waiting(false);
			clearInterval(timerId);
		}
	}, [seconds]);


	return (
		<>
			Recording starts in {seconds} seconds
		</>
	);
}


/**
 * Recording Progress.
 */
function RecordingProgress(props) {

	const [seconds, setSeconds] = useState(0);
	const [timerId, setTimerId] = useState(null);

	useEffect(() => {
		let isSubscribed = true;

		const id = setInterval(() => {
			if (isSubscribed) {
				setSeconds(seconds => (seconds + 1));
			}
		}, 1000);
		setTimerId(id);

		// below will execute when component is about to  unmount.
		return (() => {
			isSubscribed = false;
			clearInterval(timerId); // precautionary. just to avoid errors and warnings.
		});
	}, []);


	useEffect(() => {
		if (seconds === LIMIT) {
			clearInterval(timerId);
			props.stop(); // This will cause component unmount.
		}
	}, [seconds]);


	return (
		<Progress color="info" value={(seconds*3)} />
	);
}
