import React,{Component} from "react";
import {
  Col
} from "reactstrap";
import "components/custom.css";
import {arrayMove} from 'react-sortable-hoc';
import reactStringReplace from 'react-string-replace';
import ReactHtmlParser from 'react-html-parser';
import WordDefinitionModal from 'components/PracticeMaterial/PopUps/WordDefinitionModal';
import ModalPopup from "components/PopUps/Modal";
import FillInTheBlankExplanation from "components/PracticeMaterial/PopUps/FillInTheBlankExplanation";

class ReadingBlank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question:"",
      secondCount:1200,
      questionId:"",
      reset:false,
      correctFlag:true,
      resultObj:{},
      answerData:{
        answer: [],
        correct: [],
         selectedAnswer:""
      },
      wordDefinitionModalIsOpen: false,
      modalDestroy: true,
      selectedWord: "",
      isModalOpen:false,
      answer_explaination_key:"",
      explanationModal: false,
      };
    this.questionPara = "";
  }
  componentDidMount = () => {
  }
  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  }
  onSortEnd = ({oldIndex, newIndex}) => {

    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  allowDrop(event) {
    event.preventDefault();
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  drop1(event) {
    this.props.showResult();
    event.preventDefault();
    let data = event.dataTransfer.getData("Text");
    let no = event.dataTransfer.getData("no");
    let temp = event.target.innerHTML;
    let tempData = this.state.answerData.answer.slice();

    if(temp === ""){
     // this.setState({answerData:{'answer':[document.getElementById(event.target.id).getAttribute("no")],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })}});

      event.target.innerHTML = document.getElementById(data).innerHTML;
      document.getElementById(data).innerHTML = temp;
      let key = document.getElementById(event.target.id).getAttribute("no");
      let tempData = this.state.answerData.answer.slice();
      tempData[key] = event.target.innerHTML;

      //this.setState({answerData:{'answer':tempData,'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })} });
      this.props.submit({
        'answer': this.gatherAnswer(),
        'correct': this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })
      }, true);
      if(document.querySelectorAll('#'+data).length > 0){
        //document.querySelectorAll('#'+data)[0].style.display='none';

      }
    }else{
      //this.setState({answerData:{'answer':[document.getElementById(event.target.id).getAttribute("no")],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })}});
      let tempData = this.state.answerData.answer.slice();
      event.target.innerHTML = document.getElementById(data).innerHTML;
      document.getElementById(data).innerHTML = temp;
      let key = document.getElementById(event.target.id).getAttribute("no");

      tempData[key] = event.target.innerHTML;
      console.log(tempData);
      this.props.submit({
        'answer': this.gatherAnswer(),
        'correct': this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })
      }, true);
    }

    this.setState({
      resultObj: {
        'answer': this.gatherAnswer(),
        'correct': this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })
      },
      answerData:{
        'answer': this.gatherAnswer(),
        'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })
      }
    });
    //document.querySelectorAll('.span2').forEach((t) => { if(t.innerHTML == '') { t.style.display="none"; } } );

  }

  gatherAnswer = () => {
    const container = document.querySelectorAll('.response')[0];
    const blanks = container.querySelectorAll(".span1");

    let values = [];

    // for compatibility purpose: with older method.
    for (let k = 0; k < (blanks.length * 2); k++) {
      if (k%2 === 0) {
        values[k] = undefined;
      } else {
        values[k] = blanks[parseInt(k/2)].innerHTML;
      }
    }
    return values;
    // return container.innerHTML.replace(/<[^>]*>?/gm, 'x_x').split('x_x').map(function(i,x){ if(x%2 !=0) return i; })
  }

  updateCorrect = () => {
    this.props.submit({'answer':[],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })});
    this.setState({correctFlag:false,resultObj:{'answer':[],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })}})
  }

  drop(event) {
    event.preventDefault();
    var data = event.dataTransfer.getData("Text");
    var no = event.dataTransfer.getData("no");
    var temp = event.target.innerHTML;
    event.target.innerHTML = document.getElementById(data).innerHTML;
    document.getElementById(data).innerHTML = temp;


  }

  showAnswer = () => {


      if (!this.props.resultMode) {
        let elem = document.querySelectorAll('.checkedSign');
        for (var i = 0; i <= elem.length-1; i++) {
          let id = elem[i].dataset.id;

          if (elem[i].dataset.text.trim() == document.getElementById('item-'+id).textContent.trim()) {
            elem[i].innerHTML= '<i class="fa fa-check" style="font-size:32px;color:green"></i>';
          }else{
            elem[i].innerHTML = `
            <i class="fa fa-times" style="font-size:32px;color:red"></i>
          `;
          }
        }
      }
  }

  dragStart(event) {
    event.dataTransfer.setData("Text", event.target.id);

  }
  onDrop(data) {
    console.log(data);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        document.querySelectorAll('.span1').forEach((t) => { t.innerHTML="" } );
        document.querySelectorAll('.span2').forEach((t) => { t.style.display="inline-block" } );
        document.querySelectorAll('.span2').forEach((t) => { t.innerHTML=t.getAttribute('txt') } );
        return {correctFlag:true,questionId: props.data.id,answerData:{'answer':[],'correct':[]},optionList:props.data.option};
      }else{
        if (!state.questionId) {
          return {correctFlag:true,questionId: props.data.id,optionList:props.data.option};
        }
      }
    }
  }

  openCloseWordDefinitionModal = (status, word="") => {
    this.setState({
      wordDefinitionModalIsOpen: status
    }, () => {
      if (!status) {
        setTimeout(() => {
          this.setState({
            modalDestroy: true,
            selectedWord: ""
          });
        }, 500);
      } else {
        this.setState({
          modalDestroy: false,
          selectedWord: word
        });
      }
    });
  }


  wordClickHandle = (e, word) => {
    e.preventDefault();
    this.openCloseWordDefinitionModal(true, word);
  }
  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };


  renderQuestionParagraph = () => {
    let html = [];
    this.questionPara.map((item, i) => {
      if (typeof item === "string") {
        item.split(" ").map((word, i) => {
          html.push(
            reactStringReplace(word, word, (match, i, o) => (
              <span
                key={i}
                className="question-para-word speach-text"
                onClick={(e) => this.wordClickHandle(e, word)}
              >{word}</span>
            ))
          );
          html.push(" ");
        });
      } else {
        html.push(item);
      }
    });

    return html.map((item, i) => {
      return (
        <React.Fragment key={i}>
          {item}
        </React.Fragment>
      )
    });
  }

  toggleModalExplanation = () => {
    this.setState({
      explanationModal: !this.state.explanationModal
    });
  }


  
  render() {
    const { isModalOpen, modalContent } = this.state;
    let question ="";
    let optionList =[];
    let correct =[];
    if (this.props.restart) { 
      this.props.restartQue();
    }
    if (this.props.answer) {
      this.showAnswer();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
    if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
      question = this.props.data.question;
      correct = question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; });
      this.questionPara = reactStringReplace(question.replace(/<[^>]*>?/gm, 'x_x').replace(/x_x(.*?)x_x/g,''), /(__add_blank__)/g, (match, i, o) => (
        <>
          <span
            className="span1"
            onDrop={this.drop1.bind(this)}
            draggable="true"
            onDragStart={this.dragStart}
            no={i}
            id={'item-'+i}
            onDragOver={this.allowDrop}
          ></span>
          {this.props.answer && <span><span className="checkedSign" style={{display: (this.props.answer)?"inline-block":"none"}} data-id={i} data-text={correct[(i-1)/2]} ></span><span className="correct" >{correct[(i-1)/2]}</span>
          {document.getElementById('item-'+i)?.textContent.trim()!=correct[(i-1)/2].trim() && (<span>
            <i
              className="fa fa-question cursor-pointer blanks explanation-icon" 
              onClick={() => {
                this.setState({answer_explaination_key:correct[(i-1)/2]})
                this.toggleModalExplanation();
              }}
            ></i>
            </span>)}</span>}
        </>
      ));
      optionList = this.props.data.option;
    }

    if (this.state.reset) {
      document.querySelectorAll('.span1').forEach((t) => { t.innerHTML="" } );
      console.error("reset true");
    }
    let i=0;
    const selectedAnswer = this.props.quen?.answer.split(",") || [];
    return (
      <>
        <Col xs={12}>
          <h4 className="infoText"></h4>
        </Col>
        <Col xs={12}>
          <h5 className="response">
            {this.props.data && !this.props.resultMode && this.renderQuestionParagraph()}
          </h5>
        </Col>
        <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Correct answer</h4>}<h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, '<b class="correct">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
        <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Your response</h4>}
        <h5>
            {this.props.data &&
              this.props.resultMode &&
              reactStringReplace(
                question,
                /__add_blank__<span id='cAns'>(.*?)<\/span>/g,
                (match) => {
                  return (
                    <span key={i}>
                      <b className="selected-option"></b>
                      {!(match?.trim() === selectedAnswer?.[i++]?.trim()) && this.props.option_explaination.status &&  (
                        <i
                          className="fa fa-question cursor-pointer blanks explanation-icon"
                          onClick={() => {
                            this.setState({
                              answer_explaination_key: match,
                            });
                            this.toggleModalExplanation();
                          }}
                        ></i>
                      )}
                    </span>
                  );
                }
              )}
          </h5>
        </Col>
        <Col xs={12}>
          <h3 className="question">{this.props.data && this.props.data.question_mcq}</h3>
        </Col>
        <Col lg={12} className="anwrsOptions">
          {this.props.data && !this.props.resultMode && this.state.optionList.map((item, i) => {
            return (
              <span
                draggable="true"
                key={i}
                no="0"
                onDrop={this.drop.bind(this)}
                onDragOver={this.allowDrop}
                onDragStart={this.dragStart}
                className="span2"
                txt={item.options.trim()}
                id={'item-'+item.options.trim()}
              >{item.options.trim()}</span>
            )}
          )}
        </Col>
        <Col lg={12}>&nbsp;</Col>

        {!this.props.resultMode && !this.props.testMode &&
          <Col xs={12}>
            <div className="row shadowing">
              <div className="word-definition">
                {!this.state.modalDestroy &&
                  <WordDefinitionModal
                    isOpen={this.state.wordDefinitionModalIsOpen}
                    toggle={this.openCloseWordDefinitionModal}
                    word={this.state.selectedWord}
                    languages={this.props.languages}
                  />
                }
              </div>
            </div>
          </Col>
        }
        {isModalOpen && (
          <ModalPopup
            status={isModalOpen}
            toggle={this.toggleModal}
            showCloseBtn={true}
            render={(toggle) => <span>
                  <h2 className="modal-header modal-title">{
                  `${this.state.answer_explaination_key.charAt(0).toUpperCase()}${this.state.answer_explaination_key.slice(1)}`
                  }</h2>
              <p className="mb-2 definition-text">
                {this.state.option_explaination?this.state.option_explaination[this.state.answer_explaination_key.toLowerCase()]:'Fetching Definitions...'}
                </p>
            </span>}
          />
        )}

        {this.state.explanationModal &&
          <FillInTheBlankExplanation
            isOpen={this.state.explanationModal}
            title={`${this.state.answer_explaination_key.charAt(0).toUpperCase()}${this.state.answer_explaination_key.slice(1)}`}
            // data={this.props.option_explaination?this.props.option_explaination[this.state.answer_explaination_key.toLowerCase()]:'Fetching Definitions...'}
            data={this.props.option_explaination}
            keyName={this.state.answer_explaination_key}
            closeModal={this.toggleModalExplanation}


            
          />
        }
      
      </>
    );
  }
}

export default ReadingBlank;
