import React, { useState, memo, useEffect } from "react";

import SpeakingZoneIcon from "assets/images/images_new/microphone-icon.svg";
import WritingZoneIcon from "assets/images/images_new/writing-icon.svg";
import ReadingZoneIcon from "assets/images/images_new/reading-icon.svg";
import ListeningZoneIcon from "assets/images/images_new/listening-icon.svg";

const StatCategories = memo(function StatCategories({
	practiceData
}) {

	//  Speaking = 1
	//  Writing = 2
	//  Reading = 3
	//  Listening = 4
	return (
		<div className="col-md-7 pl-md-0">
			<div className="progress-section">
				<Section
					data={practiceData}
					title="Speaking"
					icon={SpeakingZoneIcon}
					class_name="speaking"
					category={1}
				/>

				<Section
					data={practiceData}
					title="Writing"
					icon={WritingZoneIcon}
					class_name="writing"
					category={2}
				/>

				<Section
					data={practiceData}
					title="Reading"
					icon={ReadingZoneIcon}
					class_name="reading"
					category={3}
				/>

				<Section
					data={practiceData}
					title="Listening"
					icon={ListeningZoneIcon}
					class_name="listening"
					category={4}
				/>
			</div>
		</div>
	);
});


const Section = ({
	data, title, icon, class_name, category
}) => {

	const [percentage, setPercentage] = useState(0);
	const [completed, setCompleted] = useState(0);
	const [total, setTotal] = useState(0);

	useEffect(() => {
		if (data) {
			switch(category) {
				case 1:
					setData(data.speaking);
					break;
				case 2:
					setData(data.writing);
					break;
				case 3:
					setData(data.reading);
					break;
				case 4:
					setData(data.listening);
					break;
				default:
					break;
			}
		}
	}, [data]);

	const setData = (d) => {
		setCompleted(d.done);
		setTotal(d.total);
		setPercentage(parseFloat((d.done / d.total) * 100).toFixed(2));
	}

	return (
		<div className={`progress-card ${class_name}`}>
			<h4>
				<img src={icon} alt={title} /> {title}
			</h4>
			<div className="progress-container">
				<div className="progress-title">
					<div className="progress-percentage">{percentage}%</div>
					<div className="progress-text">
						{completed} <span>of {total}</span>
					</div>
				</div>
				<div className="progress-bar">
					<div
						className="progress-fill"
						style={{ width: `${(percentage)}%` }}
					></div>
				</div>
			</div>
		</div>
	);
};

export default StatCategories;