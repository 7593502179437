import React,{Component} from "react";
import { withRouter } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Modal, ModalBody, ModalFooter
} from "reactstrap";

/**
 * !!!!!!!!!!!!!!!!
 * Commented ones are the originally used before new designs implementation,
 */
// import ReadAloud from "components/Question/Speaking/New/ReadAloud.jsx";
import ReadAloud from "components/Question/Mocks/Speaking/ReadAloud.jsx";

// import RepeatSentece from "components/Question/Speaking/RepeatSentece.jsx";
import RepeatSentece from "components/Question/Mocks/Speaking/RepeatSentece.jsx";

// import DescribeImage from "components/Question/Speaking/New/DescribeImage.jsx";
import DescribeImage from "components/Question/Mocks/Speaking/DescribeImage.jsx";

// import RetellLec from "components/Question/Speaking/New/RetellLec.jsx";
import RetellLec from "components/Question/Mocks/Speaking/RetellLec.jsx";

/**
 * till here.
 * !!!!!!!!!!!!!!!!
 *.


/**
 * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 * Below are from original folders
 * components/Question/{section}/{filename}.jsx
 */
import ShortQue from "components/Question/Mocks/Speaking/ShortQue.jsx";

import Summary from "components/Question/Mocks/Writting/Summary.jsx";
import Essay from "components/Question/Mocks/Writting/Essay.jsx";

import SingleAnswer from "components/Question/Mocks/Reading/SingleAnswer.jsx";
import MultipleAnswer from "components/Question/Mocks/Reading/MultipleAnswer.jsx";
import Reorder from "components/Question/Mocks/Reading/Reorder1.jsx";
import ReadingBlank from "components/Question/Mocks/Reading/ReadingBlank.jsx";
import WrittingBlank from "components/Question/Mocks/Reading/WrittingBlank.jsx";

import Summer from "components/Question/Mocks/Listening/Summer.jsx";
import McqSingle from "components/Question/Mocks/Listening/McqSingle.jsx";
import McqMulti from "components/Question/Mocks/Listening/McqMulti.jsx";
import ListeningBlank from "components/Question/Mocks/Listening/ListeningBlank.jsx";
import MissingWord from "components/Question/Mocks/Listening/MissingWord.jsx";
import CorrectSummary from "components/Question/Mocks/Listening/CorrectSummary.jsx";
import Dictation from "components/Question/Mocks/Listening/Dictation.jsx";
import Highlight from "components/Question/Mocks/Listening/Highlight.jsx";
/**
 * @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
 */

import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import ResourceCheck from "./ResourceCheck.jsx";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { NavLink } from "react-router-dom";
import API from "api.js";
import { store } from 'store/index';
import { setAlert } from 'action/index.js';

class Test extends Component {
	constructor(props) {
		super(props);
		this.state = {
			questionData: [],
			currentQuestion: 0,
			isRecording: false,
			blobURL: '',
			isBlocked: false,
			type: '',
			result: {
				strategy: 1
			},
			finalResult: [],
			lastFlag: true,
			resultId: false,
			secondCount: 0,
			submitProcess: false,
			saveProcess: false,
			dashboardFlag: false,
			resourceTested: false,
			mockCat: false,
			mockTime: 20,
			title: '',
			modal: false,
			pending: 0,
			msg: '',
			nextType: 0,
			qTime: 0,
			stopRecording: false,
			soundStat: true,
			soundModal: false,
			soundArr: [2,4,5,13,14,15,16,17,18,19,20],
			q_count: 0,
			timeOutFlag: false,
			all_ok: false,
			ids: [],
			complete: 0,
			connected: true,
			missing_data: {},
			failed: [],
			internet: true,
			loading: false
		};
	}

	onUnloadListener(event) {
		event.preventDefault();
		event.returnValue = "";
		return "";
    }

	/*2,4,5,13,14,15,16,17,18,19,20*/
	componentDidMount() {
		if(!this.props.location.state) {
			document.body.classList.toggle("withoutBar");
			document.body.classList.toggle("sidebar-test-close");
			this.props.history.replace('/admin/mock');
		} else {
			window.addEventListener('beforeunload', this.onUnloadListener);
	
			let isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
			let all_ok = (isChrome) ? true : false;
			navigator.getUserMedia({ audio: true }, () => {
				console.log('Permission Granted');
				this.setState({ all_ok: all_ok });
			}, () => {
				console.log('Permission Denied');
			});
			// document.body.classList.toggle("sidebar-mini");
			document.body.classList.toggle("withoutBar");
			document.body.classList.toggle("sidebar-test-close");
	
			document.addEventListener('contextmenu', function(e) {
				e.preventDefault();
			});
	
			const {id, pending} = this.props.location.state;
	
			API({
				method: 'GET',
				url: `web/question/detail/${id}/${pending}`
			}).then(data => {
				if ('code' in data.data && data.data.code === 'F') {
			this.props.history.push("/admin/unauthorized");
			return;
		  }
				console.log(data.data);
	
				let to_pending = false;
				let to_begin = false;
				if (parseInt(pending) === 0 && data.data.resume == true) {
					to_pending = true;
				} else if (parseInt(pending) === 1 && data.data.resume == false) {
					to_begin = true;
				}
	
				this.setState({ mock_already_complete: data.data.complete === 1 ? true : false, check_ok: true, redirect_to_pending: to_pending, redirect_to_begin: to_begin }, () => {
					/**
					* Providing question Count. Sequencing for result.
					*/
					let seq = parseInt(pending) === 0 ? 1 : (data.data.q_count + 1);
					data.data.mock.question.map((v) => {
						v.question_number = seq;
						seq++;
					});
	
					var intervalId = setInterval(this.timer, 1000);
					let soundStat = true;
					if (this.state.soundArr.includes(data.data.mock.question[0].subcategory_id)) {
						soundStat = false;
					}
	
					this.setState({
						ids: data.data.mock.question,
						title: data.data.mock.title,
						mockCat: data.data.mock.category,
						questionData: data.data.mock.question,
						type: data.data.mock.question[0].subcategory_id,
	
						q_count: data.data.q_count,
						soundStat: soundStat,
						intervalId: intervalId,
						secondCount: parseFloat(data.data.time)*60,
						mockTime: parseFloat(data.data.time)*60,
	
						missing_data: { mock_id: id, questions: [] }
					});
				});
			}).catch(() => { });
	
			navigator.getUserMedia({ audio: true }, () => {
				this.setState({ isBlocked: false });
			}, () => {
				this.setState({ isBlocked: true })
			});
		}

		return () => window.removeEventListener("beforeunload", this.onUnloadListener);
	}

	submitResponse = (data,f = false) => {
		// console.log('test-->', data)
		let obj = {
			data: data.data,
			text_answer: data.answer,
			length: data.length,
			correct: data.correct
		};
		if (f) {} else {
			this.setState({ result: obj });
		}
	}

	resultStatus = () => {
		this.setState({is_result:true});
	}

	timer = () => {
		let connection;
		if(typeof store.getState().intStatus === undefined || typeof store.getState().intStatus === "undefined") {
		connection = true;
		} else {
		connection = store.getState().intStatus;
		}
		
		if(connection) {
			if(!this.state.internet) {
				this.setState({internet: true});
			}
			if (this.state.secondCount === 1) {
				this.setState({
					dashboardFlag: true,
					timeOutFlag: true,
					stopRecording: true,
					pending: 1,
					nextType: 1,
					complete: 1
				});
				let bodyFormData = new FormData();
				const { id } = this.props.location.state;
				bodyFormData.append('mock_id', id);

				this.state.ids.map((v, k) => {
					if (k !== 0) {
						// bodyFormData.append('id[]', v.id );
						bodyFormData.append('id[]', `${v.id}-${v.question_number}`);
					}
				});
				API({
					method: 'Post',
					url: 'set/mockTime',
					data: bodyFormData
				});
				clearInterval(this.state.intervalId);
			}
			this.setState({
				secondCount: this.state.secondCount - 1,
				qTime: this.state.qTime + 1
			});
		} else {
			if(this.state.internet) {
				this.setState({internet: false});
			}
		}
	}

	componentWillUnmount = () => {
		// document.body.classList.toggle("sidebar-mini");
		document.body.classList.toggle("withoutBar");
		document.body.classList.toggle("sidebar-test-close");
		document.addEventListener('contextmenu', function(e) {
			e.preventDefault();
		});
		clearInterval(this.state.intervalId);

		window.removeEventListener("beforeunload", this.onUnloadListener);
	}

	nextButton = (e,p) => {
		if (!this.state.soundStat) {
			this.setState({ soundModal: true });
		} else {
			let pending = 0;
			let msg = "";
			let complete = 0;
			if (p === 0) {
				pending = 0;
				msg = "Do you want to go to next Question?";
			} else if(p === 1) {
				pending = 0;
				msg = "Do you want to submit test?";
				complete = 1;
				// clearInterval(this.state.intervalId)
			} else if(p === 2) {
				msg = "Do you want to save this question and exit mock test?";
				pending = 1;
				// clearInterval(this.state.intervalId)
			}
			this.setState({
				modal: true,
				pending: pending,
				msg: msg,
				nextType: p,
				complete: complete
			});
		}
	}

	toggleYes = () => {
		console.log(this.state.pending)
		console.log(this.state.msg)
		let check_if_exit_pending = this.state.pending;
		let check_if_exit_message = this.state.msg;
		if(check_if_exit_pending === 0 || check_if_exit_pending === 1){
			if(check_if_exit_message === 'Do you want to submit test?' || check_if_exit_message === 'Do you want to save this question and exit mock test?'){
				clearInterval(this.state.intervalId)
			}
		}
		this.setState({
			stopRecording: true,
			modal: false
		});
	}

	loadNextQuestionProcess = () => {
    this.setState({
      loading: true
    }, () => {
      setTimeout(() => {
        this.setState({
          loading: false
        });
      }, 500);
    });
  }

	nextQuestion = async (obj) => {

		this.loadNextQuestionProcess();
		
		let obj2 = {
			data:obj.data,
			text_answer:obj.answer,
			length:obj.length,
			correct:obj.correct,
			second:obj.time,
			lang:obj.lang,
			html: obj.html,
			audio_text:obj.audio_text,
			duration : obj.duration,
			strategy: this.state.result.strategy,
		};
		let obj1 = await this.finalObjectCreation(obj2);
		this.finalSubmit(obj1);
		
		if(this.state.nextType === 1 || this.state.nextType === 2){
			this.submitFailed();
			this.setState({
				stopRecording: false, modal: false, dashboardFlag: true
			});
			store.dispatch(setAlert({'flag':true,'type':1,'message':"Mock submitted successfully"}));
		} else {
			let soundStat = true;
			if (this.state.soundArr.includes(this.state.questionData[this.state.currentQuestion+1].subcategory_id)) {
				soundStat=false;
			}
			let currentStrategy = this.state.result.strategy;
			this.setState({
				soundStat: soundStat,
				stopRecording: false,
				result: {
					strategy: currentStrategy
				},
				currentQuestion: this.state.currentQuestion + 1,
				type: this.state.questionData[this.state.currentQuestion+1].subcategory_id,
				modal: false
			}, () => {
				this.forceUpdate();
			});
		}
	}

	missingQuestionData = (question, response) => {
		question.text = response.answer;
		question.selected = response.text_answer;
		question.text_answer = response.text_answer;
		question.type = response.type;
		question.lang = response.lang;
		question.script = response.script;
		question.html = response.html;
		question.correct = response.correct;
		question.length = response.length;
		question.id = response.id;
		question.audio_text = response.audio_text;
		question.q_ans = response.q_ans;
		question.duration = response.duration;
		question.ans = response.q_ans;
		question.device = 'website';
		question.response = response.response;


		if ([1,2,3,4,5].includes(response.type)) {
			question.answer = response.ans;
			question.file = response.data !== undefined || response.data !== "" ? response.data : new Blob(['hello worllllllllllllld']);
		} else if ([6,7].includes(response.type)) {
			question.answer = response.q_ans;
			question.file = new Blob(['hello worlppppppppppppd']);
		} else if ([8,9,10,11,12].includes(response.type)) {
			question.answer = response.correct;
			question.file = new Blob(['hello worlppppppppppppd']);
		} else if ([13,14,15,17,19,20,18,16].includes(response.type)) {
			question.answer = response.correct;
			question.file = new Blob(['hello worlppppppppppppd']);
		}
		return question;
	}

	finalSubmit = (obj1) => {
		let { questionData, currentQuestion, secondCount, qTime, pending, complete } = this.state;
		let curr = questionData[currentQuestion];
		let obj = [];
		let time = secondCount;
		obj.push(obj1);
		let finalArr = [];
		let questions = [], question = {}; // store questions which are not saved in database.
		
		let bodyFormData = new FormData();
		if(this.props.location.state.pending == 1){
			bodyFormData.append('result_id', this.props.location.state.id);
		}

		let mock_id = this.props.location.state.id;
		bodyFormData.append('q_count', questionData.length);
		bodyFormData.append('mock_id', mock_id);
		bodyFormData.append('q_time', qTime);
		bodyFormData.append('pending', pending);
		bodyFormData.append('curr_q', 0);
		bodyFormData.append('complete', complete);
		bodyFormData.append('question_number', curr.question_number);

		console.log('object', obj);

		obj.map((v,k) => {
			if ([6,7,13].includes(v.type)) {
				time = (this.state.secondCount - v.second);
				if (time < 1) {
					time = 1;
				}
				//this.setState({secondCount: (this.state.secondCount-v.second)})
			} else {
				time = this.state.secondCount;
			}
			bodyFormData.append('time', time);
			bodyFormData.append('response[]', v.response);
			bodyFormData.append('device', 'website');
			if([1,2,3,4,5].includes(v.type)) {
				bodyFormData.append('answer[]', v.ans);
				if (v.data === "" || v.data === undefined) {
					bodyFormData.append('file[]',  new Blob(['hello worllllllllllllld']));
				} else {
					bodyFormData.append('file[]', v.data);
				}

				// Add strategy only for read-aloud.
				if (parseInt(v.type) === 1) {
					bodyFormData.set('strategy', v.strategy);
				}
			} else if([6,7].includes(v.type)) {
				bodyFormData.append('answer[]', v.q_ans);
				bodyFormData.append('file[]',  new Blob(['hello worlppppppppppppd']));
			} else if([8,9,10,11,12].includes(v.type)) {
				bodyFormData.append('answer[]', v.correct);
				bodyFormData.append('file[]',  new Blob(['hello worlppppppppppppd']));
			} else if([13,14,15,17,19,20,18,16].includes(v.type)) {
				bodyFormData.append('answer[]', v.correct);
				bodyFormData.append('file[]',  new Blob(['hello worlppppppppppppd']));
			}
			bodyFormData.append('selected[]', v.text_answer);
			bodyFormData.append('text[]', v.answer);
			bodyFormData.append('type[]', v.type);
			bodyFormData.append('id[]', v.id);
			bodyFormData.append('duration[]', v.duration);
			bodyFormData.append('script[]', v.script);
			bodyFormData.append('q_ans[]', v.q_ans);
			bodyFormData.append('audio_text[]', v.audio_text);
			bodyFormData.append('html[]', v.html);
			bodyFormData.append('text_answer[]', v.text_answer);
			bodyFormData.append('length[]', v.length);
			bodyFormData.append('lang[]', v.lang);
			bodyFormData.append('correct[]', v.correct);
			bodyFormData.append('ans[]', v.q_ans);

			question = this.missingQuestionData(question, v);
		});

		API({
			method: 'POST',
			url: 'submit/mock',
			data: bodyFormData
		}).then(data => {
			let filteredAry = this.state.ids.filter(function(e) {
				return e.id !== obj[0].id;
			});
			this.setState({
				secondCount: time,
				qTime: 0,
				ids: filteredAry
			});
			if (data.status === 200 && data.data.status === 200) { } else {
				this.setState({ submitProcess: false });
				store.dispatch(setAlert({ flag: true, type: 2, message: "Something went wrong." }));
			}
		}).catch(() => {
			// in case, data not stored in database.
			// question.time = time;
			// question.question_number = curr.question_number;
			// question.q_time = qTime;
			// questions.push(question);

			// let { missing_data } = this.state;
			// missing_data.questions.push(question);
			// this.setState({ missing_data });
			// console.log('user response:', missing_data);
			let arr = [];
			arr = this.state.failed;
			arr.push(bodyFormData);
			this.setState({failed: arr});
		});
	}

	submitFailed = () => {
		let data = [];
		let formData = new FormData();
		this.state.failed.forEach((value, index) => {
		  let object = {};
		  // value.forEach((value, key) => console.log(key , value));
		  value.forEach((value, key) => {
			  if(key === 'file[]') {
				  formData.append('files[]', value)
			  } else {
				  object[key] = value;
				  formData.append(`data[${index}][${key}]`, value)
			  }
		  });
		  data.push(object);
		})
		setTimeout(() => {
		  API({
		  method: "POST",
		  url: "submitFailed/mock",
		  data: formData,
		  })
		  .then(() => {})
		  .catch(() => {});
		}, 2000)
	}

	toggle = () => {
		this.setState({ modal: !this.state.modal });
	}

	soundChecked = () => {
		this.setState({ soundStat: true });
	}

	showTest = () => {
		this.setState({ resourceTested: true, secondCount: this.state.mockTime });
		this.forceUpdate();
	}

	closeSoundModal = () => {
		this.setState({ soundModal: false });
	}

	closeTimeoutModal = () => {
		this.setState({ timeOutFlag: false });
	}

	finalObjectCreation = (obj1 = false) => {
		let qdata = this.state.questionData[this.state.currentQuestion];
		let response = true;
		if (Object.keys(this.state.result).length === 0 ) {
			response = true;
		}
		let obj = {};
		if (obj1) {
			obj = {
				...obj1,
				type:qdata.subcategory_id,
				id:qdata.id,
				response:response,
				answer:qdata.question,
				script:qdata.audio_script,
				q_ans:qdata.answer
			}
		} else {
			obj = {
				...this.state.result,
				type:qdata.subcategory_id,
				id:qdata.id,
				response:response,
				answer:qdata.question,
				script:qdata.audio_script,
				q_ans:qdata.answer
			}
		}
		return obj;
	}

	setStrategy = (st) => {
		const r = this.state.result;
		r.strategy = st;
		this.setState({
			result: r
		});
	}

	redirectToMock = (id, pending) => {
		this.props.history.replace({
			pathname: `/admin/test`,
			state: {
				id,
				pending
			}
		});
		window.location.reload();
	}


	render() {
		
		let props = {
			submit: this.submitResponse,
			showResult: this.resultStatus,
			data: this.state.questionData[this.state.currentQuestion],
			resultMode: false,
			testMode: true,
			soundChecked: this.soundChecked,
			stopRecording: this.state.stopRecording,
			nextQuestion: this.nextQuestion,
			strategy: this.state.result.strategy,
			setStrategy: this.setStrategy
		};
		
		let que;
		let title = "";
		let ques_title = "";
		if (this.state.questionData.length > 0) {
			ques_title = this.state.questionData[this.state.currentQuestion].q_title;
		}

		let { redirect_to_pending, redirect_to_begin, mock_already_complete } = this.state;

		switch(this.state.type) {
			case 1:
				que = <ReadAloud {...props} key={this.state.currentQuestion} />;
				title = "Look at the text below. In the 35 seconds, you must read this text aloud as naturally and clearly as possible. You have 35 seconds to read aloud.";
				break;
			case 2:
				que = <RepeatSentece {...props} key={this.state.currentQuestion} />;
				title = "You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.";
				break;
			case 3:
				que = <DescribeImage {...props} key={this.state.currentQuestion} />;
				title = "Look at the Image below. In 25 seconds,please speak into the microphone and describe in detail what the graph showing. You will have 40 seconds to give your response.";
				break;
			case 4:
				que = <RetellLec {...props} key={this.state.currentQuestion} />;
				title = "You will hear a lecture. After listening to the lecture, in 10 seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have 40 seconds to give your response.";
				break;
			case 5:
				que = <ShortQue {...props} />;
				title = "You will hear a question. Please give a simple and short answer. Often just one or a few words is enough.";
				break;
			case 6:
				que = <Summary {...props} />;
				title = "Read the passage and summarize it using one sentence. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. your response will be judge on the quality of your writing and on how well your response presents the key points in the passage.";
				break;
			case 7:
				que = <Essay {...props} />;
				title = "You will have 20 minutes to plan, write and revise an essay about the topic below. your response will be judged on how well you develop a position, oraganize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.";
				break;
			case 8:
				que = <SingleAnswer {...props} />;
				title = "Read the text and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
				break;
			case 9:
				que = <MultipleAnswer {...props} />;
				title = "Read the text and answer the question by selecting all the correct responses. More than one response is correct.";
				break;
			case 10:
				que = <Reorder {...props} />;
				title = "The textbox in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.";
				break;
			case 11:
				que = <ReadingBlank {...props} />;
				title = "In the text below some words are missing. Drag words from the text box below to the appropriate place in the text. To undo an answer choice, drag the word back to the box below the text.";
				break;
			case 12:
				que = <WrittingBlank {...props} />;
				title = "Below is a text with blanks. Click on each blank, a list of choices will appear. Select the appropriate answer choice for each blank.";
				break;
			case 13:
				que = <Summer {...props} />;
				title = "You will hear a short lecture. Write a summary for a fellow student who was not present at the lecture. You should write 50-70 words. You have 10 minutes to finish the task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.";
				break;
			case 14:
				que = <McqSingle {...props} />;
				title = "Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.";
				break;
			case 15:
				que = <McqMulti {...props} />;
				title = "Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.";
				break;
			case 16:
				que = <ListeningBlank {...props} />;
				title = "You will hear a recording. Type the missing words in each blank.";
				break;
			case 17:
				que = <CorrectSummary {...props} />;
				title = "You will hear a recording. Click on the paragraph that best relates to the recording.";
				break;
			case 18:
				que = <MissingWord {...props} />;
				title = "You will hear a recording about "+ ques_title+". At the end of the recording the last word or group of words has benn replaced by a beep. Select the correct option to complete the recording";
				break;
			case 19:
				que = <Highlight {...props} />;
				title = "You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.";
				break;
			case 20:
				que = <Dictation {...props} />;
				title = "You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.";
				break;
			default:
				// code block
		}

		return (
			<>
				{mock_already_complete === true ? (
					<div className="content questionsArea mock-already-completed">
						<Row>
							<Col xs={12}>
								<CardHeader>
									<Row>
										<Col xs={12}>
											<CardTitle tag="h4">
												{this.state.title}
											</CardTitle>
										</Col>
									</Row>
								</CardHeader>
								<CardBody>
									<Row className='testFinish unselectable'>
										<div className="col-sm-12">
											<p>
												<span>Dear user, you've already <strong>FINISHED</strong> this mock. Please attempt some other mock.</span>
											</p>
										</div>
									</Row>
								</CardBody>
							</Col>
						</Row>
					</div>
				) : (
					<>
						{!mock_already_complete && redirect_to_pending &&
							// <div className="content questionsArea mock-already-completed">
							<div className="questionsArea mock-already-completed">
								<Row>
									<Col xs={12}>
										<CardBody>
											<Row className='testFinish unselectable'>
												<div className="col-sm-12">
													<p>
														<span>This mock is unfinished. Please <a href={`/admin/test/${this.props.match.params.id}/1`}><Button color="primary" size="xs">Click here</Button></a> to resume this test.</span>
													</p>
												</div>
											</Row>
										</CardBody>
									</Col>
								</Row>
							</div>
						}
						{!mock_already_complete && redirect_to_begin &&
							// <div className="content questionsArea mock-already-completed">
							<div className="questionsArea mock-already-completed">
								<Row>
									<Col xs={12}>
										<CardBody>
											<Row className='testFinish unselectable'>
												<div className="col-sm-12">
													<p>
														<span>You can't resume this mock. <a href={`/admin/test/${this.props.match.params.id}/0`}><Button color="primary" size="xs">Click here</Button></a> to begin this test.</span>
													</p>
												</div>
											</Row>
										</CardBody>
									</Col>
								</Row>
							</div>
				    }
				    {!this.state.all_ok && <ChromeCheck />}
						{!this.state.connected &&
							<div className="connectivity-container">
								<div className="bg-danger text-light not-connected text-center p-1">You are not connected to internet. We are saving your responses with us and will be submitted once conncetion is restored.</div>
							</div>
						}

						{this.state.all_ok &&
							<>
								{this.state.timeOutFlag &&
									<Modal isOpen={this.state.timeOutFlag} size="sm">
										<ModalBody>
											<div>Test stopped due to timeout.</div>
										</ModalBody>
										<ModalFooter>
											<Button color="secondary" className="modalCloseBtn" onClick={this.closeTimeoutModal}>OK</Button>
										</ModalFooter>
									</Modal>
								}
								{this.state.soundModal &&
									<Modal isOpen={this.state.soundModal} size="sm">
										<ModalBody>
											<div>Please wait for question audio to be finished.</div>
										</ModalBody>
										<ModalFooter>
											<Button color="secondary" className="modalCloseBtn" onClick={this.closeSoundModal}>OK</Button>
										</ModalFooter>
									</Modal>
								}
								{this.state.modal &&
									<Modal isOpen={this.state.modal} size="sm">
										<ModalBody>
											<div>{this.state.msg}</div>
										</ModalBody>
										<ModalFooter>
											<Button color="secondary" className="modalCloseBtn" onClick={this.toggleYes}>Yes</Button>
											<Button color="secondary" className="modalCloseBtn" onClick={this.toggle}>No</Button>
										</ModalFooter>
									</Modal>
								}

								{this.state.mockCat && !this.state.resourceTested &&
									<ResourceCheck showTest={this.showTest} pending={0} data={{}} cat={this.state.mockCat} />
								}

								
								{this.state.resourceTested &&
									<div className="content questionsArea">
										<Row>
											<Col xs={12}>
												<Card>
													<CardHeader>
														<Row>
															<Col xs={6}>
																<CardTitle tag="h4">{this.state.title}</CardTitle>
															</Col>
															<Col xs={6}>
																<div className="text-right questionTime">
																	<div className="timeRemaining">
																		<p><i className="fa fa-clock-o"></i> <span>Time Remaining: {Math.floor(this.state.secondCount / 60) +':'+ parseInt(this.state.secondCount % 60)}</span></p>
																	</div>
																	<div className="item">
																		<p>Question {this.state.currentQuestion+this.state.q_count+1} of {this.state.questionData.length+this.state.q_count}</p>
																	</div>
																</div>
															</Col>
														</Row>
													</CardHeader>
													<CardBody>
														<h4>{title}</h4>
														<Row className='unselectable'>
															{this.state.secondCount === 0 && <div>Time up please restart the Test</div> }
															{
																this.state.internet
																? this.state.secondCount !== 0
																&& this.state.questionData && !this.state.loading && que 
																: <>
																<Skeleton count={1} width={"50%"} height={200} containerClassName="skeleton-wrapper__mockrecording" />
																<Skeleton count={5} width={"100%"} containerClassName="skeleton-wrapper__mocklines" />
																</>
															}
															{/* {this.state.secondCount !== 0 &&  this.state.questionData && que } */}
														</Row>
													</CardBody>
												</Card>
											</Col>

											<Col xs={12}>
												<div className="bottomBtns">
													<Row>
														<Col xs={9}>
															{!this.state.dashboardFlag && !this.state.saveProcess && !this.state.resultId && this.state.currentQuestion > 0 && this.state.questionData.length !== (this.state.currentQuestion+1) &&
																<Button color="primary" onClick={(e) => this.nextButton(e,2)} size="xs">Save & Exit</Button>
															}
															{this.state.saveProcess && !(this.state.questionData.length !== (this.state.currentQuestion+1)) &&
																<Button color="primary" size="xs">Wait...</Button>
															}
															{!this.state.dashboardFlag && !this.state.submitProcess && this.state.secondCount !== 0 && !this.state.resultId && !(this.state.questionData.length !== (this.state.currentQuestion+1)) &&
																<Button color="primary" onClick={(e) => this.nextButton(e,1)} size="xs">Submit</Button>
															}
															{this.state.submitProcess && !(this.state.questionData.length !== (this.state.currentQuestion+1)) &&
																<Button color="primary" size="xs">Wait...</Button>
															}

															{this.state.submitProcess && !(this.state.questionData.length !== (this.state.currentQuestion+1)) &&
																<Button color="primary" size="xs">Wait...</Button>
															}
															{this.state.resultId &&
																<NavLink to={'/admin/result/'+this.state.resultId}>
																	<Button color="primary" size="xs">Show Result</Button>
																</NavLink>
															}
															{this.state.dashboardFlag &&
																<NavLink to={'/admin/dashboard'}>
																	<Button color="primary" size="xs">Go to Dashboard</Button>
																</NavLink>
															}
															{this.state.timeOutFlag &&
																<div>Test stopped due to timeout</div>
															}
														</Col>
														<Col xs={3}>
															{!this.state.dashboardFlag && this.state.secondCount !== 0 && !this.state.resultId && (this.state.questionData.length !== (this.state.currentQuestion+1)) && this.state.questionData.length !== 0 &&
																<Button color="primary" onClick={(e) => this.nextButton(e,0)} size="xs">NEXT</Button>
															}
														</Col>
													</Row>
												</div>
											</Col>
										</Row>

									</div>
								}
				      </>
						}
					</>
				)}
			</>
		);
  }
}

export default Test;
