import React,{Component} from "react";
import {
  Col,
  Progress,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import {base,s3base} from "api.js";
import Sound from 'react-sound';
import "components/custom.css";
import {store,persistor} from 'store/index';
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns";
import SelectVoiceDropDown from "components/PracticeMaterial/SelectVoiceDropDown.jsx";
import TagIcon1 from "assets/images/images_new/tag1.svg";
import BoldICon from "assets/images/images_new/bold-icon.svg";

class Summer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      secondCount1:30,
      recordCount:0,
      recordId:1,
      intervalId:0,
      intervalId1:0,
      questionId:"",
      reset:false,
      lang:store.getState().user.lang,
      soundStatus:true,
      content:'',
      soundPer:0,
      volume:100,
      correctFlag:true,
      resultObj:{},
      timeoutStat:false,
      newQue: false,
      playbackSpeed: 1,
      soundKey: 0,
      showTimer: false,
      question_audio: null,
      defaultSelectedVoice: null
    };
  }
  componentDidMount() {
    
    if (!this.props.answer) {
      // select audio.
      let question_audio = null;
      let defaultSelectedVoice = null;
      if ('question_audios' in this.props.data && this.props.data.question_audios.length > 0) {
        let x = parseInt(Math.random() * 6);

        const { question_audios } = this.props.data;
        question_audio = `${s3base}/${question_audios[x].value}`;
        defaultSelectedVoice = question_audios[x].label;
      } else {
        const { media_link } = this.props.data;
        question_audio = `${s3base}${media_link}`;
      }

      this.setState({
        question_audio,
        defaultSelectedVoice
      });
    }

    const { isQuestionTime, questionTime } = this.props;
    if (isQuestionTime) {
      this.setState({
        secondCount1: questionTime * 60,
        showTimer: isQuestionTime,
      });
    }
    var intervalId = setInterval(this.timer, 1000);
    var intervalId1 = setInterval(this.timer1, 1000);
    this.setState({
      intervalId,
      intervalId1
    });
    // this.setState({intervalId1: intervalId1});
  }

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  stopTest = () =>{
    this.props.nextQuestion({...this.state.resultObj,time:this.state.secondCount1});
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        clearInterval(state.intervalId);
  			clearInterval(state.intervalId1);
  			clearInterval(state.recordId);

        let question_audio = null;
        let defaultSelectedVoice = null;
        if ('question_audios' in props.data && props.data.question_audios.length > 0) {
          let x = parseInt(Math.random() * 6);

          const { question_audios } = props.data;
          question_audio = `${s3base}/${question_audios[x].value}`;
          defaultSelectedVoice = question_audios[x].label;
        } else {
          const { media_link } = props.data;
          question_audio = `${s3base}${media_link}`;
        }

        const { isQuestionTime, questionTime } = props;
        let secondState = { secondCount1: 600, showTimer: false };
        if (isQuestionTime) {
          secondState = {
            secondCount1: questionTime * 60,
            showTimer: isQuestionTime,
          };
        }

  			return {
          newQue: true,
          timeoutStat: false,
          correctFlag: true,
          recordCount: 0,
          soundPer: 0,
          content: '',
          isRecording: false,
          secondCount: 10,
          ...secondState,
          questionId: props.data.id,
          blobURL: '',
          reset: true,
          soundStatus: true,
          question_audio,
          defaultSelectedVoice
        };
  		}else{
  			if (!state.questionId) {
  				return {content:'',correctFlag:true,questionId: props.data.id};
  			}
  		}
  	}
  }

  copyToClipboard = () => {
    document.execCommand("copy");
  };

  cutToClipboard = () => {
    document.execCommand("cut");
  };

  pasteToClipboard = async () => {
    alert("Press Ctrl+v for paste text");

  };

	timer = () => {
    if (this.state.secondCount === 1) {
      clearInterval(this.state.intervalId);
    }

    this.setState({
      secondCount: this.state.secondCount - 1
    });
  }

  timer1 = () => {
		if (this.state.secondCount1 === 1) {
			clearInterval(this.state.intervalId1);
      this.setState({timeoutStat:true});
      if (this.state.showTimer) {
        this.props.nextQuestionHandler();
      }
		}
    //this.props.submit(this.state.resultObj,true);
		this.setState({ secondCount1: this.state.secondCount1 -1 });
	}

  handleSongFinishedPlaying = () => {
    this.setState({
      soundStatus: false
    });
    this.props.soundChecked();
    this.props.showResult();
  }

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  handleSongPlaying = (obj) => {
    this.setState({
      soundPer: obj.position*100/obj.duration
    });
  }

  changeRange = (e) => {
      this.setState({volume: e.target.value});
  }

  stopAudio = () => {
    //var intervalId = setInterval(this.timer1, 1000);
    this.setState({'soundStatus':false})
  }

  chaneText = (e) =>{
    let { name, value } = e.target;

    this.props.submit({'answer':value,'correct':'','length':value.split(' ').filter(function(n) { return n != '' }).length,time:this.state.secondCount1,lang:this.state.lang},true);
    this.setState({ [name] : value,resultObj: {'answer':value,'correct':'','length':value.split(' ').filter(function(n) { return n != '' }).length,time:this.state.secondCount1,lang:this.state.lang} });
  };

  updateCorrect = () =>{
    this.props.submit({'answer':'','correct':'','length':this.state.content.split(' ').filter(function(n) { return n != '' }).length,time:this.state.secondCount1,lang:this.state.lang});
    this.setState({correctFlag:false,resultObj:{'answer':'','correct':'','length':this.state.content.split(' ').filter(function(n) { return n != '' }).length,time:this.state.secondCount1,lang:this.state.lang}})
  }

  newQuesFunc = () =>{
    var intervalId1 = setInterval(this.timer1, 1000);
    this.setState({intervalId1:intervalId1,newQue:false});
  }

  changePlaybackSpeed = (speed) => {
    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1
    }));
    this.setState({playbackSpeed: speed});
  }

  changeAudio = (selected) => {
    clearInterval(this.state.intervalId1);
    
    this.setState({
      intervalId1: 0
    }, () => {
      
      const { question_audios } = this.props.data;
      const selected_audio = question_audios.filter((audio, i) => {
        return audio.label === selected.label;
      });

      this.setState({
        question_audio: `${s3base}/${selected_audio[0].value}`
      });
    });
  }

  playingQuestionSectionLabel = () => {
    const { isRecording, secondCount, soundStatus, blobURL } = this.state;
    let status = '';

    if (!isRecording) {
      if (secondCount !== 0 && soundStatus === true) {
        status = `Beginning in ${this.state.secondCount} seconds`;
      } else if (secondCount === 0 && soundStatus) {
        status = 'Playing question audio...';
      } else {
        status = 'Question audio finished.'; // just any case.
      }
    } else {
      status = 'Question audio finished.';
    }
    return status;
  }

  showSkipAudioWaitButton = () => {
    const { secondCount, blobURL, isRecording } = this.state;
    const { testMode } = this.props;

    let button = false;
    if (!testMode && secondCount !== 0 && blobURL === '' && !isRecording) {
      button = true;
    }

    return button;
  }

  showSkipAudioButton = () => {
    const { secondCount, isRecording, soundStatus } = this.state;
    const { testMode } = this.props;

    let button = false;
    if (!testMode && secondCount === 0 && soundStatus === true && !isRecording) {
      button = true;
    }

    return button;
  }

  timeLeft = () => {
    const { secondCount1 } = this.state;
    return `${Math.floor(secondCount1 / 60)}m : ${secondCount1 % 60}s`;
  }

  wordCount = () => {
    const { content } = this.state;

    return content === "" ? 0 : content.trim().split(/\s+/).length;
  }

  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if(this.state.newQue){
      this.newQuesFunc();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}

    const { data, resultMode } = this.props;
    const { soundStatus, secondCount, volume, playbackSpeed, soundKey, question_audio } = this.state;

    return (
      <>

        {data && !resultMode && soundStatus && secondCount === 0 &&
          <Sound
            url={question_audio}
            playStatus={Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
            onPlaying={this.handleSongPlaying}
            volume={volume}
            playbackRate={playbackSpeed}
            key={soundKey}    
          />
        }

        <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper challenge-content-wrapper visibility-visible">
          <div className="recorded-section h-100"></div>
          <div className="recorded-section h-100">
            <div className="recorded-content">
              <ul className="recorded-list">
                <li>Status:</li>
                <li>{this.playingQuestionSectionLabel()}</li>
              </ul>
              <div className="recorded-audio-wrapper">
                <Progress value={(this.state.soundPer)} />

                {this.showSkipAudioWaitButton() &&
                  <div className="btn-outer">
                    <a
                      href="#"
                      className="outline-btn text-center mt-2"
                      onClick={this.skip}
                    >Skip to Audio</a>
                  </div>
                }
                {this.showSkipAudioButton() &&
                  <div className="btn-outer visibility-visible">
                    <a
                      href="#"
                      className="outline-btn text-center mt-2"
                      onClick={this.stopAudio}
                    >Skip Audio</a>
                  </div>
                }
              </div>
            </div>
            <div className="challenge-content-dropdown clearfix">
              {this.state.defaultSelectedVoice !== null &&
                <div className="pull-left">
                  <SelectVoiceDropDown
                    voices={this.props.voices}
                    selected={this.state.defaultSelectedVoice}
                    changeAudio={this.changeAudio}
                  />
                </div>
              }
              <div className="pull-left">
                <PlaybackSpeedBtns
                  changePlaybackSpeed={this.changePlaybackSpeed}
                  playbackSpeed={this.state.playbackSpeed}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="editor-wrapper-section custom-box">
          <div className="clearfix">
            <div className="time-spent pull-left">
              <img src={TagIcon1} alt="Badge" /><span>Time Left:</span> {this.timeLeft()}
            </div>

            <div className="word-count pull-right">
              <img src={TagIcon1} alt="Badge" /><span>Total word count:</span> {this.wordCount()}
            </div>
          </div>

          <div className="editor-wrapper">
            <div className="editor__toolbar">
              <button className="toolbar__button"><img src={BoldICon} alt="Bold" /></button>
            </div>
            <div className="editor__input">
              {!this.state.timeoutStat ? (
                <Input
                  type="textarea"
                  onCopy={this.copyFunc}
                  autoCorrect="false"
                  spellCheck="false"
                  name="content"
                  onChange={(e) => { this.chaneText(e)}}
                  value={this.state.content}
                  placeholder="write your content here..."
                />
              ) : (
                <div style={{
                  whiteSpace: 'pre-line'
                }}>{this.state.content}</div>
              )}
            </div>
          </div>
        </div>
      </>
    );


    // return (
    //   <>
    //     {!this.props.resultMode &&
    //       <Col xs={12}>
    //         <Col xs={12} className="text-right">
    //           <b>
    //             <p>
    //               <i className="fa fa-clock-o"></i> <span>Time Left: {Math.floor(this.state.secondCount1 / 60) +':'+ (this.state.secondCount1 % 60)}</span>
    //             </p>
    //           </b>
    //         </Col>
    //         <div className="col-lg-12 text-center">
    //     		  <div className="currentStatus">
    //             <div>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
    //               {this.state.secondCount == 0 && this.state.soundStatus && ' Playing' }
    //               <br></br>
    //                 Volume
    //                <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
    //                <br></br>
    //               <Progress color="info" value={(this.state.soundPer)} />
    //               <div className="d-flex justify-content-between">
    //                 <div className='stop-btn'>
    //                   {!this.props.testMode && this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording && <Button color="success" onClick={this.skip} size="sm">Skip</Button> }
    //                   {!this.props.testMode && this.state.soundStatus ===true && this.state.secondCount ==0  && <Button color="success" onClick={this.stopAudio} size="sm">Skip</Button>}
    //                 </div>
    //                 {!this.props.testMode && (
    //                   <div className="stop-btn clearfix">
    //                     <div className="select-voice pull-left">
    //                       {this.state.defaultSelectedVoice !== null &&
    //                         <SelectVoiceDropDown
    //                           voices={this.props.voices}
    //                           selected={this.state.defaultSelectedVoice}
    //                           changeAudio={this.changeAudio}
    //                         />
    //                       }
    //                     </div>
    //                     <div className="select-speed pull-right">
    //                       <PlaybackSpeedBtns changePlaybackSpeed={this.changePlaybackSpeed} playbackSpeed={this.state.playbackSpeed} />
    //                     </div>
    //                   </div>
    //                 )}
    //               </div>
    //             </div>
    //           </div>
    //           <Col xs={12}>
    //             {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 &&
    //               <Sound
    //                 url={this.state.question_audio}
    //                 playStatus={Sound.status.PLAYING}
    //                 onFinishedPlaying={this.handleSongFinishedPlaying}
    //                 onPlaying={this.handleSongPlaying}
    //                 volume={this.state.volume}
    //                 playbackRate={this.state.playbackSpeed}
    //                 key={this.state.soundKey}    
    //               />
    //             }
    //           </Col>
    //     		  <Col xs={12}>
    //     		  	{ this.state.blobURL && <audio src={this.state.blobURL} controls="controls" />}
    //     		  </Col>
    // 		    </div>
    //       </Col>
    //     }

    //     <Col xs={12}><h5>{question}</h5></Col>
    //     {!this.props.resultMode && !this.props.answer &&
    //       <>
    //         <Col xs={2} style={{margin:'10px'}}>

    //         </Col>
    //         <Col xs={12}>
    //           <FormGroup>
    //             {!this.state.timeoutStat && <Input type="textarea" autoCorrect="false" spellCheck="false" name="content" id="textBox" onChange={(e) => { this.chaneText(e)} } value={this.state.content} />}
    //             {this.state.timeoutStat && <div className="timeout-box">{this.state.content}</div>}
    //           </FormGroup>
    //           <div className="bottomContent">
    //             <div className="copyPaste">
    //               <a href="javascript:void(0)" onClick={this.cutToClipboard} className="btn">Cut</a>
    //               <a href="javascript:void(0)" onClick={this.copyToClipboard} className="btn">Copy</a>
    //               <a href="javascript:void(0)" onClick={this.pasteToClipboard} className="btn">Paste</a>
    //             </div>
    //             <p className="wordCount">Total word count: {this.state.content.split(' ').filter(function(n) { return n != '' }).length}</p>
    //           </div>
    //         </Col>
    //         <Col xs={12}>
    //           <h4 className="infoText">

    //             <p></p>
    //             <p></p>
    //           </h4>
    //         </Col>
    //       </>
    //     }
    //   </>
    // );
  }
}

export default Summer;
