import React, { Component } from "react";

import {
  Card,
  CardBody,
  Table,
  Row,
  Alert,
  Col,
  Modal
} from "reactstrap";
import API, { base } from "api.js";
import { NavLink } from "react-router-dom";
import {store,persistor} from 'store/index';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { Helmet } from "react-helmet";
import { thead, tbody } from "variables/general";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import ArrowIcon from "assets/images/images_new/dropdown-icon.svg";
import UpgradeIcon from "assets/images/images_new/sidebar-icons/11.svg";
import Pagination from "components/Paginate/Pagination";


const PREV = "PREV";
const NEXT = "NEXT";

class Template extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      paidMember: true,
      paginate: false,
      active_subscription: [],
      isOpen: false,
      pdf_file: null,
      popup_title: null,
      loadNext: false,
      page: 1
    };
  }
  
  componentDidMount() {
    API({
      method: 'GET',
      url: 'user-data-get/active_subscription'
    }).then(data => {
      let active_subscription = data.data.user.active_subscription
      this.setState({active_subscription:active_subscription});
      if(active_subscription.length ===0 || active_subscription[0].package_id === 9){
        this.setState({paidMember:false});
      }else{
        this.setState({paidMember:true});
      }
    }).catch(()=>{});
    
    this.loadTemplate();
  } 
  
  loadTemplate = () =>{
    API({
      method: 'GET',
      url: 'template/list?skip='+this.state.data.length
      }).then(data => {

        const { paginate, loadNext } = this.state;

        const pagination = data.data.length < 10 ? paginate : true;
        const loadnext = data.data.length < 10 ? loadNext : true;
        
        this.setState({
          data: data.data,
          paginate: pagination,
          loadNext: loadnext
        });
    }).catch(() => {});
  }

  actionHtml = (paidMember) => {
    return (
      <>
        {!paidMember ? (
          <>
            <img
              className="view-btn-icon"
              src={UpgradeIcon}
              alt="View"
            />Upgrade Plan
          </>
        ) : (
          <>
            View <img
              className="view-btn-icon"
              src={ArrowIcon}
              alt="View"
            />
          </>
        )}
      </>
    );
  }

  rowAction = (paidMember, rowId, file, title) => {

    let action_url = "/admin/plans";
    let action_class = "upgrade-plan-btn";
    let inner_html = this.actionHtml(paidMember);
    
    if (paidMember) {
      action_url = "javascript:void(0)";
      action_class = "";
    }

    return (
      <a
        href={action_url}
        className={`nav-link view-btn ${action_class}`}
        onClick={(e) => {
          this.handleActionClick(e, file, title)
        }}
      >
        {inner_html}
      </a>
    );
  }

  handleActionClick = (e, file, title) => {

    const { paidMember } = this.state;
    
    if (paidMember) {
      e.preventDefault();
      this.setState({
        isOpen: true,
        pdf_file: `${base}${file}`,
        popup_title: title
      });

    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  loadNextPrev = (offset, limit, type) => {
    
    API({
      method: 'GET',
      url: `template/list?skip=${offset}`
    }).then(data => {

      const { loadNext, page } = this.state;
      
      const load_next = (data.data.length < 10) ? false : true;
      const page_number = (type === PREV) ? (page - 1) : (page + 1);
      
      this.setState({
        data: data.data,
        loadNext: load_next,
        page: page_number
      });
    }).catch(()=>{});
  }

  itemSrNumber = (i) => {
    const { page } = this.state;

    let start_position = (page * 10) - 10;
    let item_number = (start_position + i);

    return item_number;
  }
  
  render() {

    const { paidMember, data, paginate, isOpen, popup_title, pdf_file, loadNext } = this.state;
    
    return (
      <>
        <Helmet>
          <title>Top PTE Training Institute</title>
        </Helmet>

        <div className="content p-0 template-page">
          <DashboardHeader title="Templates" />
          
          <Row className="outer-wrapper">
            <Col xs={12} className="p-0">
              <Card className="border-0">
                {!paidMember &&
                  <Alert color="success">Please upgrade your plan to access secret templates.</Alert>
                }
                <CardBody className="table-wrapper p-0">
                  <Table>
                    <thead className="text-primary">
                      <tr>                  
                        <th className="table-counting">#</th>        
                      <th className="">
                        Title
                      </th>
                      <th className="table-action">
                        Action
                      </th>                            
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((val, key) => {
                        return (
                          <tr key={key} className="visibility-visible">
                            <td>{this.itemSrNumber(key + 1)}</td>                            
                            <td>{val.title}</td>
                            <td>{this.rowAction(paidMember, val.id, val.pdf, val.title)}</td>                              
                          </tr>
                        );
                      })}
                    </tbody>                    
                  </Table>                   
                </CardBody>

                {paginate &&
                  <Pagination
                    PREV={PREV}
                    NEXT={NEXT}
                    loadHandler={this.loadNextPrev}
                    loadNext={loadNext}
                  />
                }
              </Card>
            </Col>                  
          </Row>          
        </div>

        {isOpen &&
          <Modal
            isOpen={isOpen}
            modalClassName="view-pdf-popup"
          >
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                onClick={this.toggle}
              ></button>
              <h1 className="modal-title" id="exampleModalLabel">
                {popup_title}
              </h1>
            </div>
            <div className="modal-body">
              <embed
                src={`${pdf_file}#toolbar=0`}
                style={{
                  width: "100%",
                  height: "85vh"
                }}
                frameBorder="0"
              ></embed>
            </div>
          </Modal>
        }
      </>
    );
  }
}
export default Template;