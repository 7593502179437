import React, { useState, useEffect } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';

export default function FillInTheBlankExplanation({isOpen,closeModal,title,data,keyName=""}) {

	const [modal, setModal] = useState(isOpen);
	const modalData = data?data[keyName.toLowerCase().trim()]:"";

	const closeModalIntarnal = () => {
		setModal(false);
		// close modal in parent file.
		setTimeout(() => {
			closeModal();
		}, 500);
	}
	// const chosenWordBlanks = ['none','','No word was selected','No word was selected.']

	return (
    <Modal
      isOpen={modal}
      size="lg"
      className="text-translate-modal pre-line"
    >
      <ModalHeader className="text-translation" tag="div">
        Explanation [{title}]

        <Button className="modalCloseBtn" onClick={() => closeModalIntarnal()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              d="M18 6L6 18M6 6l12 12"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="text-to-translate">
          <div className="definition-text ice-storm-form">
            {keyName==='paragraph_explanation'?(data): modalData ? (
              <>
                <b>Selected Answer:</b>{" "}
                <i style={{ color: "#ff6160", fontWeight: "600" }}>{(modalData.chosen_word==='' || modalData.chosen_word==='__')?"No word was selected":modalData.chosen_word} </i>
                <br />
                {(modalData.chosen_word === "No word was selected" || modalData.chosen_word==='' || modalData.chosen_word==='__') ? (
                  ""
                ) : (
                  <>
                    <b>Meaning:</b> {modalData.chosen_word_meaning} <br />
                    <b>Definition:</b> {modalData.chosen_word_defination} <br />
                    <b>Example:</b> {modalData.chosen_word_example} <br />
                  </>
                )}
                <hr />
                <b>Correct Answer:</b>{" "}
                <i style={{ color: "#23b67f", fontWeight: "600" }}>{modalData.correct_word}</i>{" "}
                <br />
                <b>Meaning:</b> {modalData.correct_word_meaning} <br />
                <b>Definition:</b> {modalData.correct_word_defination} <br />
                <b>Example:</b> {modalData.correct_word_example} <br />
                <hr />
                <b>Explanation:</b> {modalData.explanation} <br />
              </>
            ) : (
              "Loading..."
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          className="close-button"
          onClick={() => {
            closeModalIntarnal();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}