import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Progress,
  Button,
  Input
} from "reactstrap";
import SpeechRecognition from 'react-speech-recognition';
import Dictaphone from "./Dictaphone.jsx";
import Sound from 'react-sound';
import {base,s3base} from "api.js";
import MicRecorder from 'mic-recorder-to-mp3';
import getBlobDuration from 'get-blob-duration';
import "components/custom.css";
import NoMicrophone from "views/NoMicrophone";
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns.jsx";
import SelectVoiceDropDown from "components/PracticeMaterial/SelectVoiceDropDown.jsx";
import Beep from "Beep.js";

let Mp3Recorder = new MicRecorder({ bitRate: 128 });

class ShortQue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:3,
      secondCount2:1,
      duration:0,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      soundPer:0,
      volume:100,
      trans:'',
      correctFlag:true,
      beepStatus:false,
      file:'',
      playbackSpeed: 1,
      soundKey: 0,
      secondCount2Bckup: 0,
      question_audio: null,
      defaultSelectedVoice: null,
      micPermitted: null,
      browserAllowed: null
    };
  }
  componentDidMount() {

    // check browser. Only Chrome only.
    let chrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    // mic access.
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(() => {
      // Permission Granted
      this.setState({
        isBlocked: false,
        micPermitted: true,
        browserAllowed: chrome
      }, () => {
        if (chrome) { // check if browser is chrome only. Not even any other variant of chromium browser i.e. edge, brave etc.
          this.startProcess();
        }
      });
    }).catch(() => {
      // Permission Denied
      this.setState({
        isBlocked: true,
        micPermitted: false,
        browserAllowed: chrome
      });
    });
  }

  startProcess = () => {
    if (!this.props.answer) {

      // select audio.
      let question_audio = null;
      let defaultSelectedVoice = null;
      if ('question_audios' in this.props.data && this.props.data.question_audios.length > 0) {
        let x = parseInt(Math.random() * 6);

        const { question_audios } = this.props.data;
        question_audio = `${s3base}/${question_audios[x].value}`;
        defaultSelectedVoice = question_audios[x].label;
      } else {
        const { media_link } = this.props.data;
        question_audio = `${s3base}${media_link}`;
      }

      var intervalId = setInterval(this.timer, 1000);
      this.setState({
        intervalId,
        question_audio,
        defaultSelectedVoice
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);

        let question_audio = null;
        let defaultSelectedVoice = null;
        if ('question_audios' in props.data && props.data.question_audios.length > 0) {
          let x = parseInt(Math.random() * 6);

          const { question_audios } = props.data;
          question_audio = `${s3base}/${question_audios[x].value}`;
          defaultSelectedVoice = question_audios[x].label;
        } else {
          const { media_link } = props.data;
          question_audio = `${s3base}${media_link}`;
        }

  			return {
          correctFlag: true,
          recordCount: 0,
          isRecording: false,
          secondCount: 3,
          secondCount2: 1,
          questionId: props.data.id,
          blobURL: '',
          reset: true,
          trans: '',
          soundStatus: true,
          duration: 0,
          question_audio,
          defaultSelectedVoice
        };
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  	}
  }


  start = () => {
    this.setState({ beepStatus:true});
  };

  handleBeepFinishedPlaying = () =>{
    if (this.state.isBlocked) {
      
    } else {
      SpeechRecognition.startListening({ continuous: true, language: 'en-GB' })
      Mp3Recorder = new MicRecorder({ bitRate: 128 });
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',beepStatus:false});
        }).catch((e) => console.error(e));
    }
  }
  updateCorrect = () =>{
     this.props.submit({'data':''});
     this.setState({correctFlag:false})
  }
  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }
  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  setTrans = (a)=>{
    if (this.state.trans != a) {
      this.setState({trans:a});
    }
    
  }

  stop = () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          let file = new File([blob], 'noname5.mp3', { type: blob.type });
          this.setState({ blobURL, isRecording: false,recordCount:100, file:file });
          SpeechRecognition.stopListening();
          
          const dataToSubmit = {
            data: file,
            audio_text: this.state.trans,
            duration: duration
          };
          this.props.submit(dataToSubmit, true);
          
          //this.props.submit({'data':file },true)
          this.props.showResult();
        }).catch((e) => console.log(e));
    }
  };

  stopTest = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          //this.setState({ blobURL, isRecording: false,recordCount:100 });
          let file = new File([blob], 'noname5.mp3', { type: blob.type });
          SpeechRecognition.stopListening();
          //this.props.submit({'data':file },true)
          //this.props.showResult();
          this.props.nextQuestion({
            data: file,
            audio_text: this.state.trans,
            duration:duration
          });
        }).catch((e) => console.log(e));
    }else{
      this.props.nextQuestion({
        data: this.state.file,
        audio_text: this.state.trans,
        duration: this.state.duration
      });
    }
  };

	timer = () => {
    if (this.state.secondCount === 0) {
      clearInterval(this.state.intervalId);
      
    }else{
      this.setState({ secondCount: this.state.secondCount -1 });  
    }
    
  }

  timer1 = () => {
    if (this.state.secondCount2 === 0) {
      clearInterval(this.state.intervalId);
      this.start();
      var recordId = setInterval(this.timerRecord, 1000);
      this.setState({'soundStatus':false,recordId: recordId});
    }else{
      if(this.state.secondCount2Bckup === 0) {
        this.setState({secondCount2Bckup: this.state.secondCount2});
      }
      this.setState({ secondCount2: this.state.secondCount2 -1 });  
    }
    
  }

  handleSongFinishedPlaying = () => {
    var intervalId = setInterval(this.timer1, 1000);
    this.setState({'soundStatus':false,intervalId: intervalId});
    this.props.soundChecked();
  } 

  changeRange = (e) => {
      this.setState({volume: e.target.value});
      console.log(this.state.volume);
  }

	timerRecord = () => {
		if (this.state.recordCount === 8) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  changePlaybackSpeed = (speed) => {
    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1
    }));
    this.setState({playbackSpeed: speed, secondCount2: this.state.secondCount2Bckup});
  }

  changeAudio = (selected) => {
    clearInterval(this.state.intervalId2);
    
    this.setState({
      intervalId2: 0
    }, () => {
      
      const { question_audios } = this.props.data;
      const selected_audio = question_audios.filter((audio, i) => {
        return audio.label === selected.label;
      });

      this.setState({
        question_audio: `${s3base}/${selected_audio[0].value}`
      });
    });
  }

  playQuestionAudio = () => {

    const { resultMode, data } = this.props;
    const { soundStatus, secondCount } = this.state;
    
    let sound = false;
    if (!resultMode && data && soundStatus && secondCount === 0) {
      sound = true;
    }
    return sound;
  }

  playBeep = () => {

    const { resultMode } = this.props;
    const { beepStatus } = this.state;

    let beep = false;
    if (!this.props.resultMode && this.state.beepStatus) {
      beep = true;
    }
    return beep;
  }

  playingQuestionSectionLabel = () => {

    const { isRecording, secondCount, soundStatus, blobURL } = this.state;
    let status = '';

    if (!isRecording) {
      if (secondCount !== 0 && soundStatus === true) {
        status = `Begining in ${this.state.secondCount} seconds`;
      } else if (secondCount === 0 && blobURL === '') {
        status = 'Playing question audio...';
      } else {
        status = 'Question audio finished.'; // just any case.
      }
    } else {
      status = 'Question audio finished.';
    }
    return status;
  }

  showSkipAudioWaitButton = () => {
    const { secondCount, blobURL, isRecording } = this.state;
    const { testMode } = this.props;
    
    let show_button = false;
    if (!testMode && secondCount !== 0 && blobURL === '' && !isRecording) {
      show_button = true;
    }

    return show_button;
  }

  recordingLabel = () => {
    const { secondCount, secondCount2, blobURL, isRecording } = this.state;

    let label = '';
    if (secondCount === 0 && secondCount2 !== 0) {
      label = `Recording starts in ${secondCount2} seconds...`;
    } else if (blobURL === '' && isRecording) {
      label = 'Recording audio...';
    } else if (blobURL !== '' && !isRecording) {
      label = 'Recording Finished.';
    }

    return label;
  }

  showStopRecordingButton = () => {
    let button = false;
    
    const { testMode } = this.props;
    const { isRecording } = this.state;
    
    if (!testMode && isRecording) {
      button = true;
    }

    return button;
  }

  showRecordedAudio = () => {

    const { blobURL } = this.state;
    const { testMode } = this.props;

    let recorded_audio = false;
    if (blobURL && !testMode) {
      recorded_audio = true;
    }

    return recorded_audio;
  }
  
  render() {
    if (this.state.isBlocked) {
      return(<NoMicrophone />);
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
  	
    if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}

    const { currentQuestion, total_questions } = this.props;
    const { micPermitted, browserAllowed } = this.state;

    return (
      <>
        {micPermitted && browserAllowed ? (
          <>
            <Dictaphone
              setTrans={this.setTrans}
              status={this.state.reset || this.state.isRecording}
            />

            {this.playQuestionAudio() &&
              <Sound
                url={this.state.question_audio}
                playStatus={Sound.status.PLAYING}
                onFinishedPlaying={this.handleSongFinishedPlaying}
                onPlaying={this.handleSongPlaying}
                volume={this.state.volume}    
                playbackRate={this.state.playbackSpeed}  
                key={this.state.soundKey}    
              />
            }

            {this.playBeep() &&
              <Sound
                url={Beep}
                playStatus={Sound.status.PLAYING}
                onFinishedPlaying={this.handleBeepFinishedPlaying}
              />
            }

            <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper challenge-content-wrapper visibility-visible">
              <div className="recorded-section h-100">
                <div className="recorded-content">
                  <ul className="recorded-list">
                    <li>Status:</li>
                    <li>{this.playingQuestionSectionLabel()}</li>
                  </ul>
                  <div className="recorded-audio-wrapper">
                    <Progress value={(this.state.soundPer)} />
                    {this.showSkipAudioWaitButton() &&
                      <div className="btn-outer">
                        <a
                          href="#"
                          className="outline-btn text-center mt-2"
                          onClick={this.skip}
                        >Skip</a>
                      </div>
                    }
                  </div>
                </div>
                <div className="challenge-content-dropdown clearfix">
                  {this.state.defaultSelectedVoice !== null &&
                    <div className="pull-left">
                      <SelectVoiceDropDown
                        voices={this.props.voices}
                        selected={this.state.defaultSelectedVoice}
                        changeAudio={this.changeAudio}
                      />
                    </div>
                  }
                  <div className="pull-left">
                    <PlaybackSpeedBtns
                      changePlaybackSpeed={this.changePlaybackSpeed}
                      playbackSpeed={this.state.playbackSpeed}
                    />
                  </div>
                </div>
              </div>
              <div className="recorded-section h-100">
                <div className="recorded-content">
                  <ul className="recorded-list">
                    <li>Status:</li>
                    <li>{this.recordingLabel()}</li>
                  </ul>
                  <div className="recorded-audio-wrapper">
                    <Progress value={(this.state.recordCount*12.5)} />
                    {this.showStopRecordingButton() &&
                      <div className="btn-outer">
                        <a
                          href="#"
                          className="outline-btn text-center mt-2 visibility-visible"
                          onClick={this.stop}
                        >Stop Recording</a>
                      </div>
                    }
                    {this.showRecordedAudio() &&
                      <audio
                        controls
                        src={this.state.blobURL}
                        className="mt-2 visibility-visible"
                      ></audio>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="pmw-tabs h-100">
            {micPermitted === false &&
              <div className="alert alert-danger">No Microphone permissions granted. Please grant microphone permissions and reload the page.</div>
            }

            {browserAllowed === false &&
              <div className="alert alert-danger">The browser you are using is not supported. Please use Google Chrome.</div>
            }
          </div>
        )}
      </>
    );
    
    // return (
    //   <>
    //   <Dictaphone setTrans={this.setTrans} status={this.state.reset || this.state.isRecording} />
    //   <Col xs={12}>
    //   	<h4 className="infoText"></h4>
    //   </Col>
    //   <Col xs={12}>
    //   	<div className="col-lg-12 p-0 text-center">
    //   { !this.props.resultMode && <div>  <div className="currentStatus">
    //         <p>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
    //         {this.state.secondCount == 0 && this.state.blobURL === '' && !this.state.isRecording && ' Playing' }
            
    //         <br></br>
    //         Volume
    //          <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
    //          <br></br>
    //         <Progress color="info" value={(this.state.soundPer)} />
    //         {!this.props.testMode && (
    //           <div className="d-flex justify-content-between">
    //             <div className='stop-btn'>
    //               {!this.props.testMode && this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording &&
    //                 <Button color="success" onClick={this.skip} size="sm">Skip</Button> 
    //               }
    //             </div>
    //             <div className="stop-btn clearfix">
    //               <div className="select-voice pull-left">
    //                 {this.state.defaultSelectedVoice !== null &&
    //                   <SelectVoiceDropDown
    //                     voices={this.props.voices}
    //                     selected={this.state.defaultSelectedVoice}
    //                     changeAudio={this.changeAudio}
    //                   />
    //                 }
    //               </div>
    //               <div className="select-speed pull-right">
    //                 <PlaybackSpeedBtns changePlaybackSpeed={this.changePlaybackSpeed} playbackSpeed={this.state.playbackSpeed} />
    //               </div>
    //             </div>
    //           </div>
    //         )}
    //         </p>
            
    //       </div>
		//   <div className="audio-box">
    //     <h4>Recorded Answer</h4>
    //     <p><strong>Current status:</strong></p>
    //     <p> { this.state.blobURL === '' && this.state.isRecording?"Recording started": '' } </p>
    //       <p>  { this.state.secondCount == 0 && this.state.secondCount2 !== 0 && 'Recording starts in '+this.state.secondCount2+ ' seconds'  }</p>
    //     <hr></hr>
    //     {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
    //     {!this.props.testMode && this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.stop} size="sm">Stop</Button></div>}
    //     <hr></hr>
    //     <Progress color="info" value={(this.state.recordCount*12.5)} />
    //     <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
    //   </div> </div> }
    //   <Col xs={12}>
    //     {!this.props.resultMode && this.props.data && this.state.soundStatus && this.state.secondCount === 0 && <Sound
    //       url={this.state.question_audio}
    //       playStatus={Sound.status.PLAYING}
    //       onFinishedPlaying={this.handleSongFinishedPlaying}
    //       onPlaying={this.handleSongPlaying}
    //       volume={this.state.volume}    
    //       playbackRate={this.state.playbackSpeed}  
    //       key={this.state.soundKey}    
    //     />}
    //   </Col>
		//   <Col xs={12}>
		//   	{ this.state.blobURL && !this.props.testMode && <audio src={this.state.blobURL} controls="controls" />}
		//   </Col>
		// </div>
    //   </Col>      
    //   <Col xs={12}><h5>{question}</h5></Col> 
    //   {!this.props.resultMode && this.state.beepStatus && <Sound
    //               url={s3base + "/ptedata/ptemedia/beep.mp3"}
    //               playStatus={Sound.status.PLAYING}
    //               onFinishedPlaying={this.handleBeepFinishedPlaying}
    //             />}     
    //   </>
    // );
  }
}
export default ShortQue;