import React, { memo, useState, useEffect } from 'react';
import {
	Col,
	Modal, ModalHeader, ModalBody, ModalFooter,
	Button
} from 'reactstrap';
import Speech from 'speak-tts';
import Sound from 'react-sound';
import API, { s3base } from "api.js";
import FunctionBox from 'components/Question/Speaking/FunctionBox';
import SelectOption from "components/PracticeMaterial/SelectOption";
import WordDefinitionModal from 'components/PracticeMaterial/PopUps/WordDefinitionModal';

import "components/custom.css";

const ReadAloud = memo(function ReadAloud(props) {

	const { strategy, resultMode, testMode, answer, languages, currentQuestion, total_questions } = props;

	const [eval_strategy, setStrategy] = useState(strategy === 1 || strategy === 2 ? strategy : 1);
	const [question, setQuestion] = useState("");
	const [isRecording, setIsRecording] = useState(false);
	const [blobURL, setBlobUrl] = useState("");
	const [micPermitted, setMicPermitted] = useState(null);
	
	const [wordDefinitionModalIsOpen, setWordDefinitionModalIsOpen] = useState(false);
	const [modalDestroy, setModalDestroy] = useState(true);
	const [selectedWord, setSelectedWord] = useState("");
	const [browserAllowed, setBrowserAllowed] = useState(null);

	const [isWaiting, setIsWaiting] = useState(true);
	const [skipClicked, setSkipClicked] = useState(false);
	const [stopClicked, setStopClicked] = useState(false);

	const updateSkipClicked = (status) => {
		setSkipClicked(status);
		setIsWaiting(false);
	}

	const updateStopClicked = (status) => {
		setStopClicked(status);
	}

	const normalStrategy = () => {
		setStrategy(1);
		props.setStrategy(1);
	}

	const oneLineStrategy = () => {
		setStrategy(2);
		props.setStrategy(2);
	}


	const selectSpan = (e, item) => {
		if (!testMode) {
			const speech = new Speech();
			speech.speak({ text: item });

			openCloseWordDefinitionModal(true);
			setSelectedWord(item);
		}
	}

	const updateRecordingStatus = (status) => {
		setIsRecording(status);
	}

	const blobUrlSet = (url) => {
		setBlobUrl(url);
	}


	/**
	 * if true.
	 * this question recording is finished by user and ready to submit.
	 */
	const submitReadySet = (data) => {
		blobUrlSet(data.blobUrl);
		const data_to_send = {
			data: data.file,
			duration: data.duration,
			audio_text: data.transcript,
			strategy: eval_strategy
		};

		if (testMode) { // Test
			props.nextQuestion(data_to_send);
		} else { // Practice
			props.submit(data_to_send, true);
			props.showResult();
		}
	}

	useEffect(() => {
		// microphone permissions
		navigator.mediaDevices.getUserMedia({ audio: true })
		.then(() => {
			setMicPermitted(true);
		})
		.catch(() => {
			setMicPermitted(false);
		});

		// check browser. Only Chrome only.
		let chrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
		setBrowserAllowed(chrome);

		// filter and set question.
		const filtered_question = props.data.question.replace(/<span[^>]*>?/g, ' __').replace(/<[^>]*span>?/g, '__');
		setQuestion(filtered_question);
	}, []);


	useEffect(() => {
		if (props.restart) {
			props.setRestart();
		}
	}, [props.restart]);


	const openCloseWordDefinitionModal = (status) => {
		setWordDefinitionModalIsOpen(status);

		if (!status) {
			setTimeout(() => {
				setModalDestroy(true);
			}, 500);
		} else {
			setModalDestroy(false);
		}
	}

	const skip = (e) => {
		e.preventDefault();
		updateSkipClicked(true);
	}

	const stop = (e) => {
		e.preventDefault();
		updateStopClicked(true);
	}


	/**
	 * For Test.
	 */
	useEffect(() => {
		setModalDestroy(!props.shadowingPopup);
	}, [props.shadowingPopup]);

	return (
		<>
			{/*micPermitted && question !== "" &&
				<>
					<Col xs={12}>
			        	<h5><b></b></h5>
			        </Col>

			        <Col xs={12} className={eval_strategy === 1 ? '' : 'three-border'}>
			        	{eval_strategy === 2 &&
							<span className="border-label">ONE LINE STRATEGY</span>
						}

						<div className="col-lg-12 text-center">
							{!resultMode &&
								<>
									<div className="scoring-mode">
										<button className={`btn ${eval_strategy === 1 ? 'btnActive' : ''}`} onClick={() => normalStrategy()}>Normal</button>
										<button className={`btn ${eval_strategy === 2 ? 'btnActive' : ''}`} onClick={() => oneLineStrategy()}>One Line Strategy</button>
									</div>
									<FunctionBox
										testMode={testMode}
										answer={answer}
										blobURL={blobURL}
										blobUrlSet={blobUrlSet}
										submitReadySet={submitReadySet}
										stopRecording={props.stopRecording}
										pauseWaiting={!modalDestroy}
									/>
								</>
							}
							<Col xs={12}>
								<Audio 
									testMode={testMode}
									recording={isRecording}
									blobURL={blobURL}
								/>
							</Col>

						</div>
			        </Col>

			        <Col xs={12} className={eval_strategy === 1 ? '' : 'border-three'}>
			        	<h5>
			        		{question.split(' ').map(function(item, index) {
			        			return <React.Fragment key={index}><span className={(testMode) ? "" : "speach-text"} onClick={(e) => selectSpan(e, item)}>{item}</span>&nbsp; </React.Fragment>;
			        		}, this)}
			        	</h5>
			        </Col>

			        {!testMode && !resultMode &&
				        <Col xs={12}>
				        	<div className="row shadowing">
								<div className="word-definition">
									{!modalDestroy &&
										<WordDefinitionModal
											isOpen={wordDefinitionModalIsOpen}
											toggle={openCloseWordDefinitionModal}
											word={selectedWord}
											languages={languages}
										/>
									}
								</div>
							</div>
				        </Col>
				    }
			    </>
		    }*/}


			{micPermitted && browserAllowed ? (
				<div className="pmw-tabs-section d-flex align-items-end justify-content-between pmw-outer-wrapper">
					<div className="pmw-tabs h-100 visibility-visible">
						<nav className="tabs-content">
							<div className="nav nav-tabs" id="nav-tab" role="tablist">
								<button
									className={`nav-link ${eval_strategy === 1 && "active"}`}
									id="nav-normal-strategy"
									onClick={normalStrategy}
								>Normal</button>
								<button
									className={`nav-link ${eval_strategy === 2 && "active"}`}
									id="nav-one-line-strategy"
									onClick={oneLineStrategy}
								>One Line Strategy</button>
							</div>
						</nav>
						<div className="tab-content" id="nav-tabContent">
							<div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
								<p className="gray-text">
									{question.split(' ').map(function(item, index) {
										return <React.Fragment key={index}><span className={(testMode) ? "" : "speach-text"} onClick={(e) => selectSpan(e, item)}>{item}</span>&nbsp; </React.Fragment>;
					        		}, this)}
								</p>
							</div>
						</div>
					</div>
					<div className="recorded-section h-100">
						<div className="recorded-content">
							<FunctionBox
								testMode={testMode}
								answer={answer}
								blobURL={blobURL}
								blobUrlSet={blobUrlSet}
								submitReadySet={submitReadySet}
								stopRecording={props.stopRecording}
								pauseWaiting={!modalDestroy}
								updateRecordingStatus={updateRecordingStatus}
								skipClicked={skipClicked}
								updateSkipClicked={updateSkipClicked}
								stopClicked={stopClicked}
								updateStopClicked={updateStopClicked}
							/>
							<div className="recorded-audio-wrapper">
								{!blobURL ? (
									<div className="handle-buttons">
										{isWaiting &&
											<a
												href="#"
												className="outline-btn pull-left text-center"
												onClick={skip}
											>Skip</a>
										}
										{isRecording &&
											<a
												href="#"
												className={`outline-btn pull-right text-center visibility-visible`}
												onClick={stop}
											>Stop</a>
										}
									</div>
								) : (
									<Audio
										testMode={testMode}
										recording={isRecording}
										blobURL={blobURL}
									/>
								)}
							</div>
						</div>
					</div>

					{!testMode && !resultMode && !modalDestroy &&
						<WordDefinitionModal
							isOpen={wordDefinitionModalIsOpen}
							toggle={openCloseWordDefinitionModal}
							word={selectedWord}
							languages={languages}
						/>
					}
				</div>
			) : (
				<div className="pmw-tabs h-100">
					{micPermitted === false &&
						<div className="alert alert-danger">No Microphone permissions granted. Please grant microphone permissions and reload the page.</div>
					}

					{browserAllowed === false &&
						<div className="alert alert-danger">The browser you are using is not supported. Please use Google Chrome.</div>
					}
				</div>
			)}
		</>
	);
});
export default ReadAloud;



/**
 * Audio Box.
 */
function Audio(props) {

	const [testMode, setTestMode] = useState(false);
	const [blobUrl, setBlobUrl] = useState("");

	
	useEffect(() => {
		setTestMode(props.testMode);
	}, []);

	useEffect(() => {
		setBlobUrl(props.blobURL);
	}, [props.blobURL]);


	return (
		<>
			{blobUrl && !testMode &&
				<audio className="visibility-visible" src={blobUrl} controls="controls" />
			}
		</>
	);
}