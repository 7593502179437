import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Label
} from "reactstrap";
import { Radio } from '@material-ui/core';
import TagIcon1 from "assets/images/images_new/tag1.svg";

import "components/custom.css";

class SingleAnswer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question:"",
      secondCount:1200,
      questionId:"",
      reset:false,
      selectedValue:'',
      is_answer:false,
      correctFlag:true,
      resultObj:{}
    };
  }

  componentDidMount() {
    if (!this.props.answer) {
    	let intervalId = setInterval(this.timer, 1000);

      this.setState({
        intervalId
      });
    }
  }

  handleChange = (event) => {
    this.props.showResult();
    let correct = this.props.data.option.filter(function (item) {
  return item.correct == 1;
})[0].id;
    this.setState({selectedValue: event.target.value,resultObj:{'answer':event.target.value,'correct':correct} });
    this.props.submit({'answer':event.target.value,'correct':correct},true);
  };

  updateCorrect = () =>{
    let correct = this.props.data.option.filter(function (item) {
  return item.correct == 1;
})[0].id;
    this.props.submit({'answer':"",'correct':correct});
    this.setState({correctFlag:false,resultObj:{'answer':"",'correct':correct}})
  } 

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			return {correctFlag:true,secondCount:1200,questionId: props.data.id,reset:true};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  		
  		}    
  }

	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  showAnswer = () => {
    if (this.props.answer != this.state.is_answer) {
      this.setState({is_answer:true});  
    }
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }
  
  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.stopRecording) {
      this.stopTest();      
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}

    if (this.props.answer) {
      this.showAnswer();
    }

  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false,is_answer:false});
  	}

    const { data, resultMode } = this.props;
    const { selectedValue } = this.state;

    return (
      <>
        <div className="custom-box">
          <p className="gray-text">{question}</p>
        </div>

        <div className="editor-wrapper-section custom-box">
          <div className="radiolist-content">
            <h4>{data && data.question_mcq}</h4>
            <div className="radiolist-group">
              {data && !resultMode && data.option.map(function(item, i) {
                return (
                  <React.Fragment key={i}>
                    <label className="radio-wrapper">
                      <p>{item.options}</p>
                      <input
                        type="radio"
                        name="answer"
                        onChange={this.handleChange}
                        value={item.id}
                        checked={selectedValue == item.id}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </React.Fragment>
                )
              },this)}
            </div>
          </div>
        </div>
      </>
    );

    // return (
    //   <>
    //   <Col xs={12} className="mb-4">
    //   	<h4 className="infoText"></h4>
    //   </Col>
    //   <div className="container-fluid">
    //     <div className="row">
    //       <Col xs={6}><h5>{question}</h5></Col>
    //       <Col xs={6}>
    //         <h3 className="question">{this.props.data && this.props.data.question_mcq}</h3>
    //         <div className="answers">
    //           {this.props.data && this.props.resultMode && this.props.data.option.map(function(item){
    //               return <React.Fragment>
    //                     <Label className={ this.state.is_answer && item.correct === 1 && 'correct-line' }>
    //                       <Radio
    //                           checked={this.state.selectedValue == item.id}
    //                           value={item.id}
    //                           color="default"
    //                           onChange={this.handleChange}
    //                           inputProps={{ 'aria-label': 'secondary checkbox' }}
    //                           name="answer"
    //                         />{' '}
    //                       {item.options}
    //                     </Label>
    //                   </React.Fragment>;
    //           },this) }
    //           {this.props.data && !this.props.resultMode && this.props.data.option.map(function(item){
    //               return <React.Fragment>
    //                       <Label className={ this.props.answer && (item.correct === 1?(this.state.selectedValue == item.id?'correct-line':'blue-line'):(this.state.selectedValue == item.id)?'red-line':'') }>
    //                         <Radio
    //                           checked={this.state.selectedValue == item.id}
    //                           value={item.id}
    //                           color="default"
    //                           onChange={this.handleChange}
    //                           inputProps={{ 'aria-label': 'secondary checkbox' }}
    //                           name="answer"
    //                         />{' '}
    //                         {item.options}
    //                       </Label>
    //                     </React.Fragment>;
    //             },this) }
    //         </div>
    //       </Col>
    //     </div>
    //   </div>
    //   </>
    // );
  }
}

export default SingleAnswer;
