import { s3base } from "api";
import Image from "components/Image/Image";
import BootstrapModal from "components/PopUps/BootstrapModal";
import ModalSkeleton from "components/PopUps/ModalSkeleton";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const Item = ({ title, thumbnail, vidLink, id, description }) => {
  const [modelStatus, setModelStatus] = useState(false);
  const history = useHistory();

  const modelHandler = () => {
    if (!vidLink) {
      setModelStatus((prevState) => !prevState);
      return;
    }
    history.push("/admin/video/" + id);
  };

  let vidPopup;
  if (!vidLink) {
    vidPopup = (
      <ModalSkeleton
        status={modelStatus}
        toggle={modelHandler}
        handleClick={() => {
          history.push("/admin/plans");
        }}
        btnText="Buy Plan"
        message="Please subscribe to our gold plan to see Secret Strategy Videos"
        isSmall={false}
      />
    );
  }

  return (
    <div className="strategy-video-card">
      <div className="strategy-video-wrapper" onClick={modelHandler}>
        <Image path={s3base + thumbnail} alt="" classes="" />
      </div>
      <div className="strategy-video-content">
        <h2 className="title">{title}</h2>
        {/*<p className="sub-title">{description}</p>
        <p className="timing">12 Mins</p>*/}
      </div>
      {vidPopup}
    </div>
  );
};

export default Item;
