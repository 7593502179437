import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { store } from "store/index";
import { base } from "api.js";
import useLogOut from "hooks/useLogOut";
import AvatarImg from "assets/images/images_new/avatar.png";
import Bell from "components/Notification/Bell";
import CollapseIcon from "assets/images/images_new/dropdown-icon.svg"

const DashboardHeader = memo(function DashboardHeader({ title }) {

    const history = useHistory();
    const { logout } = useLogOut();

    const { user } = store.getState();
    // const openCloseSidebar = () => {
  	// 	document.body.classList.toggle('leftSidebarClosed');
  	// }
    
    const openCloseSidebarMobile = () => {
  		if (document.body.classList.contains('leftSidebarClosed')) {
  			document.body.classList.remove('openSidebar');
  		} else {
  			document.body.classList.add('openSidebar');
  		}
  	}

    const fullName = () => {
      const { first_name, last_name, email } = user;
      let full_name = "";

      if (first_name !== "" && first_name !== null && first_name !== undefined) {
        full_name = `${first_name}`;
      }

      if (last_name !== "" && last_name !== null && last_name !== undefined) {
        full_name = `${full_name} ${last_name}`;
      }

      if (full_name === "") {
        full_name = email;
      }
      
      return full_name;
    }

    const profilePic = () => {
      let img_url = AvatarImg;
      if (user.image) {
        img_url = `${base}${user.image}`;
      }

      return img_url;
    }

    const naviagteTo = (url) => {
      history.push('/admin/profile');
    }

    const handleOperation = (e) => {
      e.preventDefault();

      const { value } = e.target;
      switch(value) {
        case "profile":
          naviagteTo('/admin/profile');
          break;
        case "logout":
          logout();
          break;
        default:
          break;
      }
    }

    return (
      <>
      <div className="dasboard-head">
        <div className="d-flex align-items-center">
          {/*<span className="toggleIcon closeSidebar" onClick={() => openCloseSidebar()}>
            <img src={CollapseIcon} alt="collapse icon" />
          </span>*/}
          <span className="toggleIcon openSidebarMobile align-self-center pr-3" onClick={() => openCloseSidebarMobile()}>
  				  <img src="/images/new_dash/hamburger.png" alt="collapse icon" />
  			  </span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="user-info-content">
        <div className="user-notification">
        <Bell />
        </div>
        <div className="user-info">
            <div className="user-img">
            <img src={profilePic()} alt="image" />
            </div>
            <div className="user-dropdown-wrapper">                    
              <select
                name="profile"
                id="user"
                className="user-dropdown"
                onChange={handleOperation}
                value={fullName()}
              >
                  <option value="user-name">{fullName()}</option>
                  <option value="profile">My Profile</option>
                  <option value="logout">Logout</option>
              </select>
            </div>
        </div>
        </div>
      </div>
      </>
    );
});
export default DashboardHeader;