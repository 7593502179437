import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";
import { Redirect } from "react-router-dom";
import { NavLink } from "react-router-dom";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import API, { base } from "api.js";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:false
    };
  }

  componentDidMount() {
    API({
        method: 'GET',
        url: 'template/'+this.props.match.params.id
      }).then(data => {
        this.setState({data:data.data.data});
    }).catch(()=>{      
    });
  }

  fileURL = () => {
    return `${base}${this.state.data.pdf}#toolbar=0`;
  }
  
  render() {

    const { data } = this.state;
    
    return (
      <>      
        {data &&
          <div className="content p-0">
            <DashboardHeader title={data && data.title} />
            
            <Row className="outer-wrapper">
              <Col xs={12} className="p-0">
                <Card className="border-0">
                  <CardBody>
                    <Row className='unselectable'>
                      <Col>
                        {data && 
                          <embed
                            width="100%"
                            height="750px"
                            src={this.fileURL(data.pdf)}
                          />
                        }
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        }
      </>
    );
  }
}

export default Test;
