import React,{Component} from "react";
import { NavLink } from "react-router-dom";
import VideoImg from "../assets/images/images_new/user-img.png"
import ArrowIcon from "../assets/images/images_new/dropdown-icon.svg"
import TagIcon1 from "../assets/images/images_new/tag1.svg"
import TagIcon2 from "../assets/images/images_new/tag2.svg"
import TagIcon3 from "../assets/images/images_new/tag3.svg"
import TagIconDefault from "../assets/images/images_new/tagDefault.png"
import LoggingSystemImg from "../assets/images/images_new/logging-system.png"
import BoldICon from "../assets/images/images_new/bold-icon.svg"
import ArrowIcon1 from "../assets/images/images_new/arrow-icon.svg"

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  NavLink as NavLink1,
  TabContent, TabPane, Nav, NavItem,CardText,
  InputGroupText
} from "reactstrap";
import DataTable from 'react-data-table-component';
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";
import SortByExplanationVideo from "components/PracticeMaterial/SortByExplanationVideo";
// import SidebarClose from "components/Sidebar/SidebarClose";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";

// New components.
import Filters from "components/PracticeMaterial/New/Filters";
import ListTable from "components/PracticeMaterial/New/ListTable";
import QuestionListPagination from "components/PracticeMaterial/New/QuestionListPagination";

const ALL = 1;
const MONTHLY_PREDICTIONS = 2;
const EXAM_QUESTIONS = 3;

class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total:0,
      page: 1,
      search:'',
      count:10,
      mark:'all',
      attempted:'all',
      activeTab: ALL,
      type:this.props.match.params.id,
      prediction:this.props.match.params.type,
      all_ok: true,
      orderby: 'desc',
      complexity:'all',
      filterByVid: 'none',
      displaying_items: 10
    };
  }

  componentDidMount() {
    this.getListApi(1)
  }

  changePagination = (p) =>{

    let page = this.state.page+p;
    if(page > 0 && (page*10-10) < this.state.total){
      this.setState({page:page});
      this.getListApi(page);
    }

  }

  toggle = tab => {
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab,page:1},()=>{
        this.getListApi(1);
      });
    }
  }

  getListApi = (page) => {
    let { type, prediction, search, count, activeTab, mark, attempted, complexity, orderby, filterByVid } = this.state;
    API({
        method: 'GET',
        url: `question/${type}?prediction=${prediction}&page=${page}&search=${search}&count=${count}&type=${activeTab}&mark=${mark}&attempted=${attempted}&complexity=${complexity}&orderby=${orderby}&list=1&practice=true&filterByVid=${filterByVid}`,
      }).then(data => {
        this.setState({
          'data': data.data.data,
          'total': data.data.total
        });
        localStorage.setItem('orderby', this.state.orderby);
    }).catch(()=>{ });
  }

  changeInput = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value },() => {
    	if (name === "count" ) {
    		this.setState({ page : 1 },()=>{
    			this.getListApi(1);
    		});

    	}else if(name === "mark"){
        this.getListApi(1);
      }else if(name === "attempted"){
        this.getListApi(1);
      }else if(name === "complexity"){
        this.getListApi(1);
      }else if(name === "orderby" || name === "filterByVid"){
        this.getListApi(1);
      }
    });
  }

  clickSearch = () => {
    this.getListApi(1);
  }

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({
      search: value
    });
  }


  /**
   * Update display items per page. in table.
   */
  updateListingItems = (items, page) => {
    this.setState({
      count: items,
      page: page
    }, () => {
      // run api again to get updated listing.
      this.getListApi(page);
    });
  }


  /**
   * Change Pagination.
   */
  paginationChange = (page) => {
    this.setState({
      page
    }, () => {
      this.getListApi(page);
    });
  }


  /**
   * Switch between tabs.
   * All, Monthly Predictions, Exam Questions.
   */
  showData = (tab) => {
    this.setState({
      activeTab: tab,
      page: 1
    }, () => {
      this.getListApi(1);
    });
  }


  /**
   * Search by ExplanationVid.
   */
  searchByExplanationVideo = (e, val) => {
    console.log(val);
  }

  render() {
    let { page, count, all_ok, activeTab, type, mark, attempted, complexity, search, filterByVid } = this.state;
    let title = "";
    let i = page * count-count;

    switch(type) {
      case "1":
        title = "Read Aloud";
        break;
      case "2":
        title = "Repeat Sentence";
        break;
      case "3":
        title = "Describe Image";
        break;
      case "4":
        title = "Re-tell Lecture";
        break;
      case "5":
        title = "Answer Short Questions";
        break;
      case "6":
        title = "Summarize written text";
        break;
      case "7":
        title = "Write Essay";
        break;
      case "8":
        title = "Multiple Type, Single Answer";
        break;
      case "9":
        title = "Multiple Type, Multiple Answer";
        break;
      case "10":
        title = "Reorder Paragraph";
        break;
      case "11":
        title = "Reading Fill In The Blanks";
        break;
      case "12":
        title = "Fill In The Blanks Reading And Writing ";
        break;
      case "13":
        title = "Summarize Spoken Text";
        break;
      case "14":
        title = "MCQ Single Answer";
        break;
      case "15":
        title = "MCQ Multiple Answer";
        break;
      case "16":
        title = "Listening Fill In The Blank";
        break;
      case "17":
        title = "Highlight Correct Summary";
        break;
      case "18":
        title = "Select Missing Word";
        break;
      case "19":
        title = "Highlight Incorrect Word";
        break;
      case "20":
        title = "Write From Dictation";
        break;
      default:
        // code block
    }

    return (
      <>
        {!all_ok && <ChromeCheck />}
        {all_ok &&
          <div className="siteContent readAloudPage p-0">
            <DashboardHeader title="Practice Material" />
            <div className="dashboard-content-wrapper read-aloud-wrapper">
                <Card>
                  <CardHeader className="p-0">
                    <CardTitle tag="h4">{title}</CardTitle>

                    <div className="read-aloud-header">
                      <Filters
                        ALL={ALL}
                        MONTHLY_PREDICTIONS={MONTHLY_PREDICTIONS}
                        EXAM_QUESTIONS={EXAM_QUESTIONS}
                        showData={this.showData}
                        activeTab={activeTab}
                        search={search}
                        handleSearchChange={this.handleSearchChange}
                        clickSearch={this.clickSearch}
                        type={parseInt(this.state.type)}
                        filterByVid={filterByVid}
                        searchByExplanationVideo={this.searchByExplanationVideo}
                      />
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                          <div className="table-responsive">
                            <ListTable
                              data={this.state.data}
                              type={this.state.type}
                              activeTab={activeTab}
                              mark={mark}
                              attempted={attempted}
                              complexity={complexity}
                              filterByVid={filterByVid}
                              search={search}
                              page={page}
                              listing={this.state.displaying_items}
                            />
                          </div>
                        </div>
                        <div className="tab-pane fade" id="monthlyprediction" role="tabpanel" aria-labelledby="monthlyprediction-tab">
                          Monthly Prediction table
                        </div>
                        <div className="tab-pane fade" id="examquestions" role="tabpanel" aria-labelledby="examquestions-tab">
                          Exam Question Table
                        </div>
                    </div>

                    {/* Pagination */}
                    <QuestionListPagination
                      total={this.state.total}
                      listing={this.state.displaying_items}
                      updateListingItems={this.updateListingItems}
                      page={page}
                      paginationChange={this.paginationChange}
                    />
                  </CardBody>

                  {/* <CardBody className="p-0">
                    <Row>
                      <Col lg={2} md={4} sm={4} className="text-center" >
                       <label>Search by Keywords</label>
                        <InputGroup style={{width: '100%'}}>
                          <Input placeholder="Search " onKeyDown={(e) => { this._handleKeyDown(e)} } name="search" autoCorrect="false" spellCheck="false" onChange={(e) => { this.changeInput(e)} } value={this.state.search} />
                          <InputGroupAddon addonType="append">
                            <InputGroupText onClick={this.clickSearch} className="btn-primary"><i className="fa fa-search" ></i></InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>

                      <Col lg={2} md={4} sm={4} className="text-center" >
                        <label>Practice Status</label>
                        <select onChange={this.changeInput} name="attempted" className="form-control" >
                          <option value="all">Select</option>
                          <option value="1">Attempted</option>
                          <option value="0">Not Attempted</option>
                        </select>
                      </Col>

                      <Col lg={2} md={4} sm={4} className="text-center" >
                        <label>Order By</label>
                        <select onChange={this.changeInput} name="orderby" className="form-control" >
                          <option value="desc">Newest First</option>
                          <option value="asc">Oldest First</option>
                        </select>
                      </Col>

                      <Col lg={1} md={4} sm={4} className="text-center" >
                        <label>Badge</label>
                        <select onChange={this.changeInput} name="mark" className="form-control" >
                          <option value="all">Select</option>
                          <option value="marked">All Marked</option>
                          <option value="red">Red</option>
                          <option value="green">Green</option>
                          <option value="yellow">Yellow</option>
                        </select>
                      </Col>

                      <Col lg={1} md={4} sm={4} className="text-center" >
                        <label>Complexity</label>
                        <select onChange={this.changeInput} name="complexity" className="form-control" >
                          <option value="all">Select</option>
                          <option value="0">Easy</option>
                          <option value="1">Medium</option>
                          <option value="2">Difficult</option>
                        </select>
                      </Col>

                      <Col lg={1} md={4} sm={4} className="text-center" >
                        <label>Pagination</label>
                        <select onChange={this.changeInput} name="count" className="form-control" >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                        </select>
                      </Col>

                      { (this.state.type == 11 || this.state.type == 12) &&
                        <Col lg={2} md={4} sm={4} className="text-center" >
                          <SortByExplanationVideo onChange={this.changeInput} />
                        </Col>
                      }
                   </Row>
                  </CardBody> */}
                </Card>
            </div>
            

            
            {/* Listening HTML */}
            <h2 className="ms-4">Listening</h2>
            <div className="pmw write-essay-wrapper">
              <div className="dashboard-content-wrapper read-aloud-wrapper">
                  <Card>
                    <CardHeader className="p-0">
                      <div className="d-flex align-items-center">                      
                      <CardTitle tag="h4">Agriculture and Climate Change</CardTitle>
                      <div className="read-aloud-header pmw-header">
                        <div className="strategy-video">
                          <div className="strategy-video-img">
                            <img src={VideoImg} alt="Question Strategy Video" />
                          </div>
                          <span>Question Strategy Video</span>
                        </div>   
                        <span className="complexity-badge medium">MEDIUM</span>  
                        <select name="" id="" className="badge-dropdown">
                            <option value="">Badge 01</option>
                            <option value="">Badge 02</option>
                            <option value="">Badge 03</option>
                            <option value="">Badge 04</option>
                        </select>  
                        <div className="time-spent">
                        <img src={TagIcon1} alt="Badge" /><span>Time Spent:</span> 1h : 10m : 20s
                        </div>
                      </div>
                      </div>
                      <span className="item-count">Item 1 of 1534</span>                       
                    </CardHeader>
                    <CardBody>
                    <p className="gray-text">You will hear a short lecture. Write a summary for a fellow student who was not present at the lecture. You should write 50-70 words. You have 10 minutes to finish the task. Your response will be judged on the quality of your writing and on how well your response presents the key points presented in the lecture.</p>
                    </CardBody>   
                  </Card>
              </div>
              <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper"> 
                  <div className="recorded-section h-100"></div>  
                  <div className="recorded-section h-100">
                    <div className="recorded-content">
                      <ul className="recorded-list">
                        <li>Recorded</li>
                        <li>Status: <span>Stopped Recording</span></li>
                      </ul>
                      <div className="recorded-audio-wrapper">
                      <audio controls
                          src="" type="audio/mpeg">
                        </audio>
                      </div>
                      <div className="challenge-content-dropdown">
                        <select name="" id="" className="badge-dropdown">                         
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                        </select>  
                        <select name="" id="" className="badge-dropdown">                         
                            <option value="">1X</option>
                            <option value="">2X</option>
                            <option value="">3X</option>
                            <option value="">4X</option>
                        </select>  
                    </div>  
                    </div>                                     
                  </div>                  
              </div>
              <div className="editor-wrapper-section custom-box">
                <div className="time-spent">
                  <img src={TagIcon1} alt="Badge" /><span>Time Spent:</span> 1h : 10m : 20s
                </div>
                <label htmlFor="message">Message</label>
                <div className="editor-wrapper">
                  <div className="editor__toolbar">
                    <button className="toolbar__button"><img src={BoldICon} alt="Bold" /></button>
                    <button className="toolbar__button"><img src={BoldICon} alt="Bold" /></button>
                    <button className="toolbar__button"><img src={BoldICon} alt="Bold" /></button>
                    <button className="toolbar__button"><img src={BoldICon} alt="Bold" /></button>
                  </div>
                  <div className="editor__input">
                    <textarea id="message" placeholder="Enter your message"></textarea>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between  pmw-outer-wrapper">
                <div className="left-btns d-flex">
                  <a href="#" className="outline-btn">Restart</a>
                  <a href="#" className="submit-btns">Submit</a>
                  <div className="switch-wrapper">
                    <span className="switch-label">Audio Transcript</span>
                    <label className="switch">
                      <input type="checkbox" className="switch-input" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="right-btns"> 
                  <select name="" id="" className="badge-dropdown">
                      <option value="">01</option>
                      <option value="">02</option>
                      <option value="">03</option>
                      <option value="">04</option>
                  </select>  
                  <a href="#" className="prev-next-btn">Previous</a>
                  <a href="#" className="prev-next-btn">Next</a>
                </div>
              </div>
              <div className="pmw-outline-btns pmw-outer-wrapper d-flex align-items-center justify-content-between">
                <div className="outline-left-btns">
                  <a href="#" className="outline-btn">Translate</a>
                </div>
                <div className="outline-right-btns"> 
                  <a href="#" className="outline-btn">0 X Attempted</a>
                  <a href="#" className="outline-btn">19805 X Tested</a>
                  <a href="#" className="outline-btn">Report</a>
                </div>
              </div>
            </div>
            {/* Listening/answering HTML */}
            <h2 className="ms-4">Listening/answering</h2>
            <div className="pmw write-essay-wrapper">
              <div className="dashboard-content-wrapper read-aloud-wrapper">
                  <Card>
                    <CardHeader className="p-0">
                      <div className="d-flex align-items-center">
                      <CardTitle tag="h4">Robots</CardTitle>
                      <div className="read-aloud-header pmw-header">
                        <div className="strategy-video">
                          <div className="strategy-video-img">
                            <img src={VideoImg} alt="Question Strategy Video" />
                          </div>
                          <span>Question Strategy Video</span>
                        </div>   
                        <span className="complexity-badge medium">MEDIUM</span>  
                        <select name="" id="" className="badge-dropdown">
                            <option value="">Badge 01</option>
                            <option value="">Badge 02</option>
                            <option value="">Badge 03</option>
                            <option value="">Badge 04</option>
                        </select>  
                        <div className="time-spent">
                        <img src={TagIcon1} alt="Badge" /><span>Time Spent:</span> 1h : 10m : 20s
                        </div>
                      </div>
                      </div>
                      <span className="item-count">Item 1 of 1534</span>                       
                    </CardHeader>
                    <CardBody>
                    <p className="gray-text">Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct.</p>
                    </CardBody>   
                  </Card>
              </div>
              <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper"> 
                  <div className="recorded-section h-100"></div>  
                  <div className="recorded-section h-100">
                    <div className="recorded-content">
                      <ul className="recorded-list">
                        <li>Recorded</li>
                        <li>Status: <span>Stopped Recording</span></li>
                      </ul>
                      <div className="recorded-audio-wrapper">
                      <audio controls
                          src="" type="audio/mpeg">
                        </audio>
                      </div>
                      <div className="challenge-content-dropdown">
                        <select name="" id="" className="badge-dropdown">                         
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                        </select>  
                        <select name="" id="" className="badge-dropdown">                         
                            <option value="">1X</option>
                            <option value="">2X</option>
                            <option value="">3X</option>
                            <option value="">4X</option>
                        </select>  
                    </div>  
                    </div>                                     
                  </div>                  
              </div>
              <div className="radiolist-content custom-box">
                  <h4>In speaker's opinion, which of the following is correct?</h4>
                  <div className="radiolist-group">
                      <label className="radio-wrapper">
                      <p>Modern robots need to develop psychological aspect</p>
                        <input  type="radio" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-wrapper">
                      <p>Third video was all about robot-staffed stores</p>
                        <input type="radio" name="radio"   />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-wrapper">
                      <p>Robots have to work away from humans</p>
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-wrapper">
                      <p>Robots from the beginning were all about technology</p>
                        <input type="radio" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
              </div>  

              <div className="d-flex align-items-center justify-content-between  pmw-outer-wrapper">
                <div className="left-btns d-flex">
                  <a href="#" className="outline-btn">Restart</a>
                  <a href="#" className="submit-btns">Submit</a>
                  <div className="switch-wrapper">
                    <span className="switch-label">Audio Transcript</span>
                    <label className="switch">
                      <input type="checkbox" className="switch-input" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="right-btns"> 
                  <select name="" id="" className="badge-dropdown">
                      <option value="">01</option>
                      <option value="">02</option>
                      <option value="">03</option>
                      <option value="">04</option>
                  </select>  
                  <a href="#" className="prev-next-btn">Previous</a>
                  <a href="#" className="prev-next-btn">Next</a>
                </div>
              </div>
              <div className="pmw-outline-btns pmw-outer-wrapper d-flex align-items-center justify-content-end">
                <div className="outline-right-btns"> 
                  <a href="#" className="outline-btn">0 X Attempted</a>
                  <a href="#" className="outline-btn">19805 X Tested</a>
                  <a href="#" className="outline-btn">Report</a>
                </div>
              </div>
            </div>
             {/* Multiple Answer HTML */}
             <h2 className="ms-4">Multiple Answer</h2>
            <div className="pmw write-essay-wrapper">
              <div className="dashboard-content-wrapper read-aloud-wrapper">
                  <Card>
                    <CardHeader className="p-0">
                      <div className="d-flex align-items-center">
                      <CardTitle tag="h4">Theatre Studies</CardTitle>
                      <div className="read-aloud-header pmw-header">
                        <div className="strategy-video">
                          <div className="strategy-video-img">
                            <img src={VideoImg} alt="Question Strategy Video" />
                          </div>
                          <span>Question Strategy Video</span>
                        </div>   
                        <span className="complexity-badge medium">MEDIUM</span>  
                        <select name="" id="" className="badge-dropdown">
                            <option value="">Badge 01</option>
                            <option value="">Badge 02</option>
                            <option value="">Badge 03</option>
                            <option value="">Badge 04</option>
                        </select>  
                        <div className="time-spent">
                        <img src={TagIcon1} alt="Badge" /><span>Time Spent:</span> 1h : 10m : 20s
                        </div>
                      </div>
                      </div>
                      <span className="item-count">Item 1 of 1534</span>                       
                    </CardHeader>
                    <CardBody>
                    <p className="gray-text">Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.</p>
                    </CardBody>   
                  </Card>
              </div>
              <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper"> 
                  <div className="recorded-section h-100"></div>  
                  <div className="recorded-section h-100">
                    <div className="recorded-content">
                      <ul className="recorded-list">
                        <li>Recorded</li>
                        <li>Status: <span>Stopped Recording</span></li>
                      </ul>
                      <div className="recorded-audio-wrapper">
                      <audio controls
                          src="" type="audio/mpeg">
                        </audio>
                      </div>
                      <div className="challenge-content-dropdown">
                        <select name="" id="" className="badge-dropdown">                         
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                        </select>  
                        <select name="" id="" className="badge-dropdown">                         
                            <option value="">1X</option>
                            <option value="">2X</option>
                            <option value="">3X</option>
                            <option value="">4X</option>
                        </select>  
                    </div>  
                    </div>                                     
                  </div>                  
              </div>
              <div className="radiolist-content custom-box">
                <div className="checklist-content">
                  <h4>What comments are made about students in the Theatre Studies program?</h4>
                  <div className="radiolist-group">
                      <label className="radio-wrapper">
                      <p>Robots have to work away from humans</p>
                        <input  type="checkbox" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-wrapper">
                      <p>Third video was all about robot-staffed stores</p>
                        <input type="checkbox" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-wrapper">
                      <p>Modern robots need to develop psychological aspect</p>
                        <input type="checkbox" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-wrapper">
                      <p>Robots from the beginning were all about technology</p>
                        <input type="checkbox" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-wrapper">
                      <p>Robots have to work away from humans</p>
                        <input type="checkbox" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                      <label className="radio-wrapper">
                      <p>Robots have to work away from humans</p>
                        <input type="checkbox" name="radio" />
                        <span className="checkmark"></span>
                      </label>
                  </div>
                </div>
              </div>  

              <div className="d-flex align-items-center justify-content-between  pmw-outer-wrapper">
                <div className="left-btns d-flex">
                  <a href="#" className="outline-btn">Restart</a>
                  <a href="#" className="submit-btns">Submit</a>
                  <div className="switch-wrapper">
                    <span className="switch-label">Audio Transcript</span>
                    <label className="switch">
                      <input type="checkbox" className="switch-input" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="right-btns"> 
                  <select name="" id="" className="badge-dropdown">
                      <option value="">01</option>
                      <option value="">02</option>
                      <option value="">03</option>
                      <option value="">04</option>
                  </select>  
                  <a href="#" className="prev-next-btn">Previous</a>
                  <a href="#" className="prev-next-btn">Next</a>
                </div>
              </div>
              <div className="pmw-outline-btns pmw-outer-wrapper d-flex align-items-center justify-content-end">
                <div className="outline-right-btns"> 
                  <a href="#" className="outline-btn">0 X Attempted</a>
                  <a href="#" className="outline-btn">19805 X Tested</a>
                  <a href="#" className="outline-btn">Report</a>
                </div>
              </div>
            </div>
            {/* Listening filing the blank HTML */}
            <h2 className="ms-4">Listening filing the blank</h2>
            <div className="pmw re-order-section lf-blank">
              <div className="dashboard-content-wrapper read-aloud-wrapper mb-12">
                  <Card>
                    <CardHeader className="p-0">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center lf-blank-header">                     
                        <CardTitle tag="h4" className="">Numerical</CardTitle>
                        <div className="read-aloud-header pmw-header w-100 d-flex align-items-center justify-content-center">
                          <div className="d-flex align-items-center">                        
                            <div className="strategy-video">
                              <div className="strategy-video-img">
                                <img src={VideoImg} alt="Question Strategy Video" />
                              </div>
                              <span>Question Strategy Video</span>
                            </div>   
                            <span className="complexity-badge medium">MEDIUM</span>  
                            <select name="" id="" className="badge-dropdown">
                                <option value="">Badge 01</option>
                                <option value="">Badge 02</option>
                                <option value="">Badge 03</option>
                                <option value="">Badge 04</option>
                            </select>  
                            <div className="time-spent">
                              <img src={TagIcon1} alt="Badge" /><span>Time Spent:</span> 1h : 10m : 20s
                            </div>
                          </div>                    
                        </div>
                       </div>
                      <span className="item-count text-right">Item 1 of 1534</span>   
                    </div>
                    </CardHeader>
                    <CardBody>
                    <p className="gray-text">You will hear a recording. Type the missing words in each blank.</p>
                    </CardBody>   
                  </Card>
              </div>  
              <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper"> 
                  <div className="recorded-section h-100"></div>  
                  <div className="recorded-section h-100 mt-0">
                    <div className="recorded-content">
                      <ul className="recorded-list">
                        <li>Recorded</li>
                        <li>Status: <span>Stopped Recording</span></li>
                      </ul>
                      <div className="recorded-audio-wrapper">
                      <audio controls
                          src="" type="audio/mpeg">
                        </audio>
                      </div>
                      <div className="challenge-content-dropdown">
                        <select name="" id="" className="badge-dropdown">                         
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                            <option value="">ALICE</option>
                        </select>  
                        <select name="" id="" className="badge-dropdown">                         
                            <option value="">1X</option>
                            <option value="">2X</option>
                            <option value="">3X</option>
                            <option value="">4X</option>
                        </select>  
                    </div>  
                    </div>                                     
                  </div>                  
              </div>
              <div className="custom-box">
                <div className="ice-storm-form">
              An ice storm is a type of <input type="text"  className="form-control" /> when <input type="text"  className="form-control" /> rainfall comes down into the air and the water turned into <input type="text"  className="form-control" /> <input type="text"  className="form-control" /> than 16,000 households which had a blackout <input type="text"  className="form-control" /> an ice storm as the cables snapped with ice weighing on them.                                        
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between  pmw-outer-wrapper">
                <div className="left-btns d-flex">
                  <a href="#" className="outline-btn">Restart</a>
                  <a href="#" className="submit-btns">Submit</a>
                  <div className="switch-wrapper">
                    <span className="switch-label">Audio Transcript</span>
                    <label className="switch">
                      <input type="checkbox" className="switch-input" />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className="right-btns"> 
                  <select name="" id="" className="badge-dropdown">
                      <option value="">01</option>
                      <option value="">02</option>
                      <option value="">03</option>
                      <option value="">04</option>
                  </select>  
                  <a href="#" className="prev-next-btn">Previous</a>
                  <a href="#" className="prev-next-btn">Next</a>
                </div>
              </div>
              <div className="pmw-outline-btns pmw-outer-wrapper d-flex align-items-center justify-content-between">
                <div className="outline-left-btns"> 
                  <a href="#" className="outline-btn">Translate</a>
                </div>
                <div className="outline-right-btns"> 
                  <a href="#" className="outline-btn">0 X Attempted</a>
                  <a href="#" className="outline-btn">19805 X Tested</a>
                  <a href="#" className="outline-btn">Report</a>
                </div>
              </div>
            </div>
          </div>
        }
      </>
    );
  }
}

export default QuestionList;
