import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Progress,
  Button,
  Input
} from "reactstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Sound from 'react-sound';
import {base,s3base} from "api.js";
import getBlobDuration from 'get-blob-duration';
import MicRecorder from 'mic-recorder-to-mp3';
import NoMicrophone from "views/NoMicrophone.jsx";
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns.jsx";
import SpeechToText from "components/Question/Speaking/New/SpeechToText";
import Beep from "Beep.js";

import "components/custom.css";

let Mp3Recorder = new MicRecorder({ bitRate: 128 });

class RetellLec extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:8,
      duration:0,
      secondCount2:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      intervalId2:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      soundPer:0,
      trans:'',
      volume:100,
      correctFlag:true,
      beepStatus:false,
      position: 0,
      file:'',
      playbackSpeed: 1,
      soundKey: 0,
      secondCount2Bckup: 0,
      saveTranscript: false,
      micPermitted: null,
      browserAllowed: null
    };
  }


  componentDidMount() {
    // check browser. Only Chrome only.
    let chrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    // mic access.
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(() => {
      // Permission Granted
      this.setState({
        isBlocked: false,
        micPermitted: true,
        browserAllowed: chrome
      }, () => {
        if (chrome) { // check if browser is chrome only. Not even any other variant of chromium browser i.e. edge, brave etc.
          this.startProcess();
        }
      });
    }).catch(() => {
      // Permission Denied
      this.setState({
        isBlocked: true,
        micPermitted: false,
        browserAllowed: chrome
      });
    });
  }

  startProcess = () => {
    if (!this.props.answer) {
      const intervalId = setInterval(this.timer, 1000);
      this.setState({
        intervalId
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        clearInterval(state.intervalId);
        clearInterval(state.intervalId2);
        clearInterval(state.recordId);
  			
        return {correctFlag:true,recordCount:0,soundPer:0,isRecording:false,secondCount:8,secondCount2:10,questionId: props.data.id,blobURL:'',reset:true,trans:'',soundStatus:true,intervalId2:0,duration:0};
  		} else {
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  	}
  }


  start = () => {
    this.setState({ beepStatus:true});
  };

  handleBeepFinishedPlaying = () => {
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      SpeechRecognition.startListening({ continuous: true, language: 'en-GB' });
      Mp3Recorder = new MicRecorder({ bitRate: 128 });
      Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',beepStatus:false});
        }).catch((e) => console.error(e));
    }
  }

  stop = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          let file = new File([blob], 'noname2.mp3', { type: blob.type });
          this.setState({
            blobURL,
            isRecording: false,
            recordCount: 100,
            file,
            duration,
            saveTranscript: true,
          });
          SpeechRecognition.stopListening();
          // this.props.submit({'data': file,'audio_text':this.state.trans, duration:duration },true);
          // this.props.showResult();
        }).catch((e) => console.log(e));
    }

  };

  setTrans = (a)=>{
    if (this.state.trans != a) {
      this.setState({trans:a})  
    }
    
  }

  stopTest = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          //this.setState({isRecording: false, recordCount:100 });
          let file = new File([blob], 'noname2.mp3', { type: blob.type });
          SpeechRecognition.stopListening();
          this.setState({
            saveTranscript: true,
            file,
            duration
          });
          // below line was moved to setTranscript()
          // this.props.nextQuestion({'data': file,'audio_text':this.state.trans, duration: duration });
        }).catch((e) => console.log(e));
    } else {
      this.props.nextQuestion({
        data: this.state.file,
        audio_text: this.state.trans,
        duration: this.state.duration
      });
    }
  };

  updateCorrect = () =>{
     this.props.submit({'data':'',audio_text:'',duration:0});
     this.setState({correctFlag:false})
  }

	timer = () => {
    const { secondCount, intervalId } = this.state;

    if (secondCount === 0) {
      clearInterval(intervalId);
    } else {
      this.setState({
        secondCount: (secondCount - 1)
      });  
    }    
  }

  timer1 = () => {
    if (this.state.secondCount2 === 0) {
      clearInterval(this.state.intervalId2);
      this.start();
      var recordId = setInterval(this.timerRecord, 1000);
      this.setState({'soundStatus':false,recordId: recordId});
    }else{
      if(this.state.secondCount2Bckup === 0) {
        this.setState({secondCount2Bckup: this.state.secondCount2});
      }
      this.setState({ secondCount2: this.state.secondCount2 -1 });  
    }
    
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  handleSongFinishedPlaying = () => {
   // var intervalId = setInterval(this.timer1, 1000);
    this.setState({'soundStatus':false});
    this.props.soundChecked();

    //this.start();
    //var recordId = setInterval(this.timerRecord, 1000);
    //this.setState({'soundStatus':false,recordId: recordId});
  } 

  stopAudio = () => {
    //var intervalId = setInterval(this.timer1, 1000);
    this.setState({'soundStatus':false,secondCount2:10})
  }

	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}
  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  changeRange = (e) => {
  	  this.setState({volume: e.target.value});
  	  console.log(this.state.volume);
  }
	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  loadingSound = (obj) =>{
    let d = obj.duration/1000+9;
    if (obj.duration !== 0 && this.state.intervalId2 ===0){
      var intervalId = setInterval(this.timer1, 1000);
      this.setState({'soundStatus':true,intervalId2: intervalId,secondCount2:Math.ceil(d) ,secondCount2Bckup: Math.ceil(d)})
    }
  } 

  changePlaybackSpeed = (speed) => {
    console.log(this.state.secondCount2Bckup);
    if(speed !== this.state.playbackSpeed) {
      let seconds = this.state.secondCount2Bckup;
      if(this.state.secondCount === 0) {
        if(speed === 2 || speed === 1.5) {
          seconds = seconds/speed;
        }
      }
      this.setState((prevState) => ({
        soundKey: prevState.soundKey + 1
      }));
      // this.setState({playbackSpeed: speed, secondCount2: this.state.secondCount2Bckup});
      this.setState({playbackSpeed: speed, secondCount2: Math.round(seconds)});
    }
  }

  setSaveTranscript = (status) => {
    this.setState({ saveTranscript: status });
  }

  setTranscript = (transcript) => {
    this.setState({
      trans: transcript,
      saveTranscript: false
    }, () => {
      console.log('this.state.trans::', this.state.trans)
      // this code was in stop() earlier.
      // after replace <Dictaphone /> with <SpeechToText />,  this code placed here.
      // to get transcript effectively.
      const data_to_send = {
        data: this.state.file,
        audio_text: this.state.trans,
        duration: this.state.duration
      };
      
      if (this.props.testMode) {
        this.props.nextQuestion(data_to_send);
      } else {
        this.props.submit(data_to_send, true);
        this.props.showResult();
      }
    });
  }


  playSound = () => {
    const { resultMode, data } = this.props;
    const { soundStatus, secondCount } = this.state;

    let sound = false;
    if (!resultMode && data && soundStatus && secondCount === 0) {
      sound = true;
    }

    return sound;
  }

  playingQuestionSectionLabel = () => {

    const { isRecording, secondCount, soundStatus, blobURL } = this.state;
    let status = '';

    if (!isRecording) {
      if (secondCount !== 0 && soundStatus === true) {
        status = `Begining in ${this.state.secondCount} seconds`;
      } else if (secondCount === 0 && blobURL === '') {
        status = 'Playing question audio...';
      } else {
        status = 'Question audio finished.'; // just any case.
      }
    } else {
      status = 'Question audio finished.';
    }
    return status;
  }

  showSkipAudioWaitButton = () => {
    let show_button = false;
    const { secondCount, blobURL, isRecording } = this.state;
    
    if (secondCount !== 0 && blobURL === '' && !isRecording) {
      show_button = true;
    }

    return show_button;
  }

  recordingLabel = () => {
    const { secondCount, secondCount2, blobURL, isRecording } = this.state;

    let label = '';
    if (secondCount === 0 && secondCount2 !== 0) {
      label = `Recording starts in ${secondCount2} seconds...`;
    } else if (blobURL === '' && isRecording) {
      label = 'Recording audio...';
    } else if (blobURL !== '' && !isRecording) {
      label = 'Recording Finished.';
    }

    return label;
  }

  showStopRecordingButton = () => {
    let button = false;
    
    const { testMode } = this.props;
    const { isRecording } = this.state;
    
    if (!testMode && isRecording) {
      button = true;
    }

    return button;
  }

  showRecordedAudio = () => {

    const { blobURL } = this.state;
    const { testMode } = this.props;

    let recorded_audio = false;
    if (blobURL && !testMode) {
      recorded_audio = true;
    }

    return recorded_audio;
  }

  skipAudio = () => {
    const { testMode } = this.props;
    const { soundStatus, secondCount } = this.state;

    let button = false;
    if (!testMode && soundStatus === true && secondCount === 0) {
      button = true;
    }

    return button;
  }

  playBeep = () => {

    const { resultMode } = this.props;
    const { beepStatus } = this.state;
    
    let beep = false;
    if (!resultMode && beepStatus) {
      beep = true;
    }
    return beep;
  }

  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
  	if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  		question = this.props.data.question;
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}
    
    if (this.state.isBlocked) {
      return (<NoMicrophone />);
    }

    const { currentQuestion, total_questions } = this.props;
    const { micPermitted, browserAllowed } = this.state;
    
    return (
      <>
        {micPermitted && browserAllowed ? (
          <>
            <SpeechToText
              saveTranscript={this.state.saveTranscript}
              setTranscript={this.setTranscript}
            />

            {this.playSound() &&
              <Sound
                url={s3base + this.props.data.media_link}
                playStatus={Sound.status.PLAYING}
                onPlaying={this.handleSongPlaying}
                onLoading={this.loadingSound}
                onFinishedPlaying={this.handleSongFinishedPlaying}
                volume={this.state.volume} 
                playbackRate={this.state.playbackSpeed}      
                key={this.state.soundKey} 
              />
            }

            {this.playBeep() && <Sound
              url={Beep}
              playStatus={Sound.status.PLAYING}
              onFinishedPlaying={this.handleBeepFinishedPlaying}
            />}

            <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper challenge-content-wrapper visibility-visible">
              <div className="recorded-section h-100">
                <div className="recorded-content">
                  <ul className="recorded-list">
                    <li>Status:</li>
                    <li>{this.playingQuestionSectionLabel()}</li>
                  </ul>
                  <div className="recorded-audio-wrapper">
                    <Progress value={(this.state.soundPer)} />
                    {this.showSkipAudioWaitButton() &&
                      <div className="btn-outer">
                        <a
                          href="#"
                          className="outline-btn text-center mt-2 visibility-visible"
                          onClick={this.skip}
                        >Skip</a>
                      </div>
                    }
                    {this.skipAudio() &&
                      <div className="btn-outer">
                        <button
                          className="outline-btn text-center mt-2 visibility-visible"
                          onClick={this.stopAudio}
                        >Skip Audio</button>
                      </div>
                    }
                  </div>
                </div>
                <div className="challenge-content-dropdown clearfix">
                  <PlaybackSpeedBtns
                    changePlaybackSpeed={this.changePlaybackSpeed}
                    playbackSpeed={this.state.playbackSpeed}
                  />
                </div>
              </div>
              <div className="recorded-section h-100">
                <div className="recorded-content">
                  <ul className="recorded-list">
                    <li>Status:</li>
                    <li>{this.recordingLabel()}</li>
                  </ul>
                  <div className="recorded-audio-wrapper">
                    <Progress value={(this.state.recordCount*2.5)} />
                    {this.showStopRecordingButton() &&
                      <div className="btn-outer">
                        <a
                          href="#"
                          className="outline-btn text-center mt-2 visibility-visible"
                          onClick={this.stop}
                        >Stop Recording</a>
                      </div>
                    }
                    {this.showRecordedAudio() &&
                      <audio
                        controls
                        src={this.state.blobURL}
                        className="mt-2 visibility-visible"
                      ></audio>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="pmw-tabs h-100">
            {micPermitted === false &&
              <div className="alert alert-danger">No Microphone permissions granted. Please grant microphone permissions and reload the page.</div>
            }

            {browserAllowed === false &&
              <div className="alert alert-danger">The browser you are using is not supported. Please use Google Chrome.</div>
            }
          </div>
        )}
      </>
    );


    // return (
    //   <>
        
    //     <SpeechToText
    //       saveTranscript={this.state.saveTranscript}
    //       setTranscript={this.setTranscript}
    //     />

    //   <Col xs={12}>
    //   	<h4 className="infoText"></h4>
    //   </Col>

    //   <Col xs={12}>
    //   	<div className="col-lg-12 p-0 text-center">
    //   	{ !this.props.resultMode && <div> <div className="currentStatus">
    //         <p>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
    //         {this.state.secondCount == 0 && this.state.blobURL === '' && !this.state.isRecording && ' Playing' }
            
    //        	<br></br>
    //        	Volume
    //          <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
    //          <br></br>
    //         <Progress color="info" value={(this.state.soundPer)} />
    //         <div className="d-flex justify-content-between">
    //           <div className='stop-btn'>
    //             {!this.props.testMode && this.state.soundStatus ===true && this.state.secondCount ==0  && <Button color="success" onClick={this.stopAudio} size="sm">Skip</Button>}
    //             {!this.props.testMode && this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording && <Button color="success" onClick={this.skip} size="sm">Skip</Button> }
    //           </div>
    //           {!this.props.testMode && (
    //             <div className="stop-btn">
    //               <PlaybackSpeedBtns changePlaybackSpeed={this.changePlaybackSpeed} playbackSpeed={this.state.playbackSpeed} />
    //             </div>
    //           )}
    //         </div>
    //         </p>
            
    //       </div>
		//   <div className="audio-box">
    //     <h4>Recorded Answer</h4>
    //     <p><strong>Current status:</strong></p>
    //     <p> { this.state.blobURL === '' && this.state.isRecording?"Recording started": "" } </p>
    //     <p>  { this.state.secondCount == 0 && this.state.secondCount2 !== 0 && 'Recording starts in '+this.state.secondCount2+ ' seconds'  }</p>

    //     <hr></hr>
    //     {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
    //     {!this.props.testMode && this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.stop} size="sm">Stop</Button></div>}
    //     <hr></hr>
    //     <Progress color="info" value={(this.state.recordCount*2.5)} />
    //     <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
    //   </div> </div>}
    //   <Col xs={12}>
        
    //     {!this.props.resultMode && this.props.data && this.state.soundStatus && this.state.secondCount === 0 && <Sound
    //       url={s3base + this.props.data.media_link}
    //       playStatus={Sound.status.PLAYING}
    //       onPlaying={this.handleSongPlaying}
    //       onLoading={this.loadingSound}
    //       onFinishedPlaying={this.handleSongFinishedPlaying}
    //       volume={this.state.volume} 
    //       playbackRate={this.state.playbackSpeed}      
    //       key={this.state.soundKey} 
    //     />}
    //   </Col>
		//   <Col xs={12}>
    //     { this.state.blobURL && !this.props.testMode && <audio src={this.state.blobURL} controls="controls" />}
    //   </Col>
      
	  //   </div>
    //   </Col>
    //   <Col xs={12}><h5>{question}</h5></Col>
    //   {!this.props.resultMode && this.state.beepStatus && <Sound
    //               url={s3base + "/ptedata/ptemedia/beep.mp3"}
    //               playStatus={Sound.status.PLAYING}
    //               onFinishedPlaying={this.handleBeepFinishedPlaying}
    //             />}
    //   </>
    // );
  }
}

export default RetellLec;
