import React from "react";
import { Col } from "reactstrap";
import Title from "./Title";
import List from "./List";
import Item from "./Item";

const Category = ({ children, containerClass }) => {
  return (
    <div className="strategyideo-section">
      {children}
    </div>
  );
};

export default Category;

Category.Title = Title;
Category.List = List;
Category.Item = Item;
