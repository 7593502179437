import React, { useState, useEffect } from 'react';

export default function ListeningResult(props) {
	const [result, setResult] = useState(null);

	useEffect(() => {
		const { resultData } = props;
		setResult(resultData);
	}, []);

	return (
		<>
			{result !== null &&
				<div className="pmw-outer-wrapper">
					<ul className="result-list">
						{Object.keys(result).map((item, i) => {
							return (
								<React.Fragment key={i}>
									{item !== 'html' &&
										<li key={i}>
											<h4 className="result-output">
												<span className={result[item].score === 0 ? 'text-danger' : (result[item].score > 0 && result[item].score < result[item].out_of ? 'text-orange' : 'text-success')}>{result[item].score}/{result[item].out_of}</span>
											</h4>
											<h3>{result[item].label}</h3>
										</li>
									}
								</React.Fragment>
							)
						})}
					</ul>
				</div>
			}
		</>	
	);
}