import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Input,
  Col,
  Button,
  Alert
} from "reactstrap";
import axios from 'axios';
import { Redirect, withRouter } from "react-router-dom";
import Vimeo from '@u-wave/react-vimeo';
import NotificationAlert from "react-notification-alert";
import API,{base} from "api.js";
import { Player, BigPlayButton } from 'video-react';
import ReactPlayer from 'react-player';
import cookie from 'react-cookies';
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import {store,persistor} from 'store/index';
import Unauthorized from 'views/Unauthorized.jsx';

import UserIcon from "assets/images/images_new/user-icon.svg";
import ArrowIcon from "assets/images/images_new/dropdown-icon.svg";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";

class ShowVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoData:[],
      currentQuestion:1,
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      type:'',
      comment:'',
      error:false,
      userId:null,
      commentData:[],
      loadMore:false
    };
  }
  


  componentDidMount() {
    API({
      method: 'GET',
      url: `stgy-video/${this.props.match.params.id}`
    }).then(data => {

      if (data.status === 208) {
        this.props.history.push('/admin/unauthorized');
      } else if (data.status === 200) {console.log(data.data.data);
        let loadMore = true;
        if(data.data.data.comment.length < 10){
          loadMore = false;
        }

        if (data.status === 200) {
          this.setState({'videoData':data.data.data,'commentData':data.data.data.comment,loadMore:loadMore});
        }

        API({
          method: 'GET',
          url: 'user-data-get/id'
        }).then(data => {
          let userId = data.data.user.id;
          this.setState({userId:userId});
        }).catch(()=>{ });
      }
    }).catch(()=>{  });
  }

  loadMoreComment = () => {
    API({
      method: 'GET',
      url: 'videoComment/'+this.props.match.params.id+"?skip="+this.state.commentData.length
    }).then(data => {
      let loadMore = true;
      if(data.data.length < 10){
        loadMore = false;
      }
      this.setState({
        commentData: [...this.state.commentData, ...data.data],
        loadMore
      });
    }).catch(() => {}); 
  }

  changeComment = (e) => {
    let { name, value } = e.target;
    this.setState({ [name] : value });
  }

  addComent = async () => {
    var bodyFormData = new FormData();
    bodyFormData.set('comment', this.state.comment);
    bodyFormData.set('video_id', this.props.match.params.id);
    
    let res = null;
    try{
      res = await API({
        method: 'POST',
        url: 'add/comment-stgy',
        data: bodyFormData,
      })
    } catch(error) {};
    
    if(res) {
      if (res.status === 200) {
        this.setState({
          commentData: res.data.data.comment,
          comment: ''
        });
        this.notify(1,res.data.message);
      }
    }

  }

  notify = (type,message) => {
    
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  deleteComment = async (e,id) => {
    var bodyFormData = {
      id,
      video_id: this.props.match.params.id
    };
    
    let res = null;
    try {
      res = await API({
        method: 'Delete',
        url: 'delete/comment-stgy',
        data: bodyFormData
      })
    } catch(error) {};
    
    if(res) {
      if (res.status === 200) {
        this.notify(1,res.data.message)
        this.setState({commentData:res.data.data.comment});
      }
    }
  }

  comment = () => {
    this.forceUpdate();
  }

  userImage = (image) => {
    let user_image = UserIcon;
    if (image) {
      user_image = `${base}${image}`;
    }
    return user_image;
  }

  
  render() {

    const { commentData, videoData, comment, loadMore } = this.state;

    return (
      <div className="content p-0">
        <DashboardHeader title={videoData?.title} />
        <div className="videos-comment-page outer-wrapper">
          <div className="video-comment-card">
            <div className="video-comment-wrapper">
              <ReactPlayer
                url={videoData?.link}
                vimeo="true"
                className="comment-video"
                controls
              />  
            </div>  
            <div className="video-comment-content">
              <h2>PTE SPEAKING MASTERCLASS WITH UPDATED STRATEGIES</h2>
              <p>PTE Speaking Masterclass with Updated Strategies</p>
            </div>            
          </div>
          <div className="videos-comment-content">
            <textarea
              // type="text"
              className="form-control"
              placeholder="Add a comment..."
              name="comment"
              value={comment}
              onChange={(e) => (this.changeComment(e))}
            ></textarea>

            <button
              type="button"
              className="comment-btn"
              onClick={this.addComent}
            >Comment</button>
            
            <div className="comment-content">
              <h2 className="comment-title">Comments <a href="#" className="comment-number">({commentData.length} of {videoData.total_comments})</a></h2>
              <div className="comment-info">
                {commentData.map((val, key) => {
                  if (val.user !== null) {
                    return (
                      <div className="user-info" key={key}>
                        <div className="user-row">
                          <div className="user-img">
                            <img
                              src={this.userImage(val.user.image)}
                              alt="-"
                              style={{
                                width: '25px'
                              }}
                            />
                          </div>
                          <div className="user-name">{val.user.first_name}</div>
                          <div className="comment-date">{val.aus_date}(AEDT)</div>
                        </div>
                        <p className="user-comment">{val.comment}</p>
                        {val.user_id === this.state.userId &&
                          <p className="user-comment">
                            <button
                              type="button"
                              className="outline-btn"
                              onClick={(e) => (this.deleteComment(e, val.id))}
                            >Delete Comment</button>
                          </p>
                        }
                      </div>        
                    );
                  }
                })}
                
                {loadMore &&
                  <button
                    className="outline-btn mt-4"
                    onClick={
                      (e) => {
                        this.loadMoreComment();
                      }
                    }
                  >Load More</button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ShowVideo;
