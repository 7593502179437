import React,{Component} from "react";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Table,
	Row,
	Col,
	Button,
	Input,
	InputGroup,
	InputGroupAddon,
	NavLink as NavLink1,
	Nav,
	InputGroupText
} from "reactstrap";
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import { thead, tbody } from "variables/general";

import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import Filters from "components/PracticeMaterial/New/Filters";
import ListTable from "components/PracticeMaterial/New/ListTable";
import QuestionListPagination from "components/PracticeMaterial/New/QuestionListPagination";

const MONTHLY_PREDICTIONS = 2;

class QuestionList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			total: 0,
			page: 1,
			search: '',
			count: 10,
			mark: 'all',
			attempted: 'all',
			activeTab: MONTHLY_PREDICTIONS,
			type: this.props.match.params.id,
			prediction: 1,
			all_ok: true,
			orderby: 'desc',
			complexity: 'all',
			filterByVid: 'none',
      		displaying_items: 10
		};
	}

	componentDidMount() {
		this.chromeFunc();
		this.getListApi(1)
	}

	chromeFunc = () => {
		if (![1,2,3,4,5].includes(parseInt(this.state.type))) {
			return true;
		}

		let isChrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)  && !/Chromium/.test(navigator.userAgent);
		if (navigator.getUserMedia === undefined) {
			this.setState({all_ok:false});
		} else {
			navigator.getUserMedia({ audio: true }, () => {
            	console.log('Permission Granted');
            	this.setState({all_ok:isChrome});
			}, () => {
				this.setState({all_ok:false});
				console.log('Permission Denied');
    		});
		}
	}

	changePagination = (p) => {
    	let page = this.state.page+p;
		if (page > 0 && (page*10-10) < this.state.total) {
			this.setState({page:page});
			this.getListApi(page);
		}
	}

	getListApi = (page) => {
		let { type, prediction, search, count, activeTab, mark, attempted, complexity, orderby, filterByVid } = this.state;
		API({
        	method: 'GET',
        	url: `question/${type}?prediction=${prediction}&page=${page}&search=${search}&count=${count}&type=${activeTab}&mark=${mark}&attempted=${attempted}&complexity=${complexity}&orderby=${orderby}&list=1&practice=true&filterByVid=${filterByVid}`,
		}).then(data => {
	        this.setState({
				data: data.data.data,
				total: data.data.total
			});
	        localStorage.setItem('orderby', orderby);
	    }).catch(() => {});
	}

	changeInput = (e) => {
    	let { name, value } = e.target;
    	this.setState({ [name]: value }, () => {
    		if (name === "count" ) {
	    		this.setState({ page : 1 }, () => {
	    			this.getListApi(1);
	    		});
    		} else if (name === "mark") {
        		this.getListApi(1);
      		} else if (name === "attempted") {
        		this.getListApi(1);
      		} else if (name === "complexity") {
        		this.getListApi(1);
      		} else if (name === "orderby" || name === "filterByVid") {
        		this.getListApi(1);
      		}
    	});
	}

	clickSearch = () => {
		this.getListApi(1);
	}

	getComplexity = (comp) => {
    	let complexity = "";
    	switch(comp) {
			case 0:
				complexity = <span className="badge badge-primary">Easy</span>;
				break;
			case 1:
				complexity = <span className="badge badge-primary">Medium</span>;
				break;
			case 2:
				complexity = <span className="badge badge-primary">Difficult</span>;
				break;
	    }
		return complexity;
	}

	_handleKeyDown = (e) => {
    	if (e.key === 'Enter') {
      		this.getListApi(1);
    	}
	}

	/**
   * Change Pagination.
   */
	paginationChange = (page) => {
		this.setState({
			page
		}, () => {
			this.getListApi(page);
		});
	}

	/**
   * Update display items per page. in table.
   */
	updateListingItems = (items, page) => {
		this.setState({
			count: items,
			page: page
		}, () => {
			// run api again to get updated listing.
			this.getListApi(page);
		});
	}

	handleSearchChange = (e) => {
		const { value } = e.target;
		this.setState({
			search: value
		});
	}

	/**
	* Search by ExplanationVid.
	*/
	searchByExplanationVideo = (e, val) => {
		console.log(val);
	}

	render() {
		let { page, count, all_ok, activeTab, type, mark, attempted, complexity, search, filterByVid } = this.state;
    	let title = "";
    	let i = page * count-count;

		switch(type) {
			case "1":
		        title = "Read Aloud";
		        break;
			case "2":
				title = "Repeat Sentence";
				break;
			case "3":
				title = "Describe Image";
				break;
			case "4":
				title = "Re-tell Lecture";
				break;
			case "5":
				title = "Answer Short Questions";
				break;
			case "6":
				title = "Summarize written text";
				break;
			case "7":
				title = "Write Essay";
				break;
			case "8":
				title = "Multiple Type, Single Answer";
				break;
			case "9":
				title = "Multiple Type, Multiple Answer";
				break;
			case "10":
				title = "Reorder Paragraph";
				break;
			case "11":
				title = "Reading Fill In The Blanks";
				break;
			case "12":
				title = "Fill In The Blanks Reading And Writing ";
				break;
			case "13":
				title = "Summarize Spoken Text";
				break;
			case "14":
				title = "MCQ Single Answer";
				break;
			case "15":
				title = "MCQ Multiple Answer";
				break;
			case "16":
				title = "Listening Fill In The Blank";
				break;
			case "17":
				title = "Highlight Correct Summary";
				break;
			case "18":
				title = "Select Missing Word";
				break;
			case "19":
				title = "Highlight Incorrect Word";
				break;
			case "20":
				title = "Write From Dictation";
				break;
			default:
			// code block
		}

		return (
      		<>
      			{!this.state.all_ok && <ChromeCheck />}
        		{this.state.all_ok &&
					<div className="siteContent readAloudPage p-0">
						<DashboardHeader title="Monthly Prediction" />
            			<div className="dashboard-content-wrapper read-aloud-wrapper">
                    		<Card>
                    			<CardHeader className="p-0">
                      				<CardTitle tag="h4">{title}</CardTitle>

                      				<div className="read-aloud-header">
										<Filters
											MONTHLY_PREDICTIONS={MONTHLY_PREDICTIONS}
											showData={this.showData}
											activeTab={activeTab}
											search={search}
											handleSearchChange={this.handleSearchChange}
											clickSearch={this.clickSearch}
											type={parseInt(this.state.type)}
											filterByVid={filterByVid}
											searchByExplanationVideo={this.searchByExplanationVideo}
											onlyMonthlyPrediction={true}
										/>
									</div>
                    			</CardHeader>
                    			<CardBody>
                    				{/*<Row>
                    					<Col xs={12}>
                      						<Nav className="nav-pills-primary nav-pills-icons justify-content-center mb-5" pills role="tablist" tabs>
												<NavLink1 className={'active'}>
                          							Monthly Prediction
                        						</NavLink1>
                    						</Nav>
                    					</Col>
                    					<Col lg={2} md={4} sm={4}  className="text-center" >
                     						<label>Search by Keywords</label>
                      						<InputGroup style={{width: '100%'}}>
                        						<Input placeholder="Search " onKeyDown={(e) => { this._handleKeyDown(e)} } name="search" autoCorrect="false" spellCheck="false" onChange={(e) => { this.changeInput(e)} } value={this.state.search} />
                    							<InputGroupAddon addonType="append">
                      								<InputGroupText onClick={this.clickSearch} className="btn-primary"><i className="fa fa-search" ></i></InputGroupText>
                    							</InputGroupAddon>
                  							</InputGroup>
                    					</Col>
                     					<Col lg={2} md={4} sm={4} className="text-center">
                     						<label>Practice Status</label>
				                          	<select onChange={this.changeInput} name="attempted" className="form-control" >
					                            <option value="all">Select</option>
					                            <option value="1">Attempted</option>
					                            <option value="0">Not Attempted</option>
				                          	</select>
                     					</Col>
                     					<Col lg={2} md={4} sm={4} className="text-center" >
                     						<label>Order By</label>
                      						<select onChange={this.changeInput} name="orderby" className="form-control" >
					                            <option value="desc">Newest First</option>
					                            <option value="asc">Oldest First</option>
                      						</select>
                     					</Col>
                    					<Col lg={1} md={4} sm={4} className="text-center">
                     						<label>Badge</label>
                  							<select onChange={this.changeInput} name="mark" className="form-control" >
					                            <option value="all">Select</option>
					                            <option value="marked">All Marked</option>
					                            <option value="red">Red</option>
					                            <option value="green">Green</option>
					                            <option value="yellow">Yellow</option>
                  							</select>
                     					</Col>
                     					<Col lg={1} md={4} sm={4} className="text-center">
                     						<label>Complexity</label>
                      						<select onChange={this.changeInput} name="complexity" className="form-control" >
					                            <option value="all">Select</option>
					                            <option value="0">Easy</option>
					                            <option value="1">Medium</option>
					                            <option value="2">Difficult</option>
                      						</select>
                     					</Col>
                    					<Col lg={1} md={4} sm={4} className="text-center">
                     						<label>Pagination</label>
                      						<select onChange={this.changeInput} name="count" className="form-control" >
					                            <option value="10">10</option>
					                            <option value="50">50</option>
					                            <option value="100">100</option>
					                            <option value="500">500</option>
                      						</select>
                     					</Col>
                     				</Row>*/}

									<div className="tab-content" id="myTabContent">
										<div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
	                      					<div className="table-responsive">
	                      						<ListTable
													data={this.state.data}
													type={this.state.type}
													activeTab={activeTab}
													mark={mark}
													attempted={attempted}
													complexity={complexity}
													filterByVid={filterByVid}
													search={search}
													page={page}
													listing={this.state.displaying_items}
												/>
		                      					{/*<Table>
		                        					<thead className="text-primary">
		                          						<tr>
					                                      	<th className="">S. No</th>
		                                  					{this.state.type !== '2' && this.state.type !=='5' && <th className="">Title</th>}
		                                  					<th className="">Badge</th>
		                                  					<th className="">Complexity</th>
		                                  					<th className="">Open</th>
														</tr>
		                        					</thead>
		                        					<tbody>
		                          						{this.state.data.map((prop, key) => {
		                            						let color = (prop.tag.length >0)?prop.tag[0].tag:"grey";
		                            						return (
		                              							<tr key={prop.id}>
		                                      						<td>{++i}</td>
																	{this.state.type !== '2' && this.state.type !== '5' && <td className="">{prop.q_title}</td>}
		                                      						<td className="">
		                                        						<h4><i className="fa fa-tag" style={{color:color}}></i></h4>
		                                      						</td>
		                                      						<td className="">{this.getComplexity(prop.complexity)}</td>
		                                      						<td className="">
		                                        						<NavLink
												                            to={`/admin/practice/1/${type}/${prop.id}/${activeTab}/${mark}/${attempted}/${complexity}/none/?page=${i}&search=${search}`}
												                            className="nav-link"
												                            activeClassName="active"
																		>
		                        											<i className="" />
		                        											<p>Open Question</p>
		                      											</NavLink>
		                                      						</td>
																</tr>
															);
														})}
		                        					</tbody>
		                      					</Table>*/}
		                      				</div>
	                      				</div>
                      				</div>
                       				{/*<Button color="primary" className="bottom-btn" onClick={this.changePagination.bind(this,-1)}  size="sm"><i className="fa fa-arrow-left"></i></Button>
                       				<Button color="primary" className="bottom-btn" onClick={this.changePagination.bind(this,1)}  size="sm"><i className="fa fa-arrow-right"></i></Button>*/}
                    				<QuestionListPagination
										total={this.state.total}
										listing={this.state.displaying_items}
										updateListingItems={this.updateListingItems}
										page={page}
										paginationChange={this.paginationChange}
									/>
                    			</CardBody>
                  			</Card>
						</div>
					</div>
				}
      		</>
		);
  	}
}

export default QuestionList;
