import React, { Component } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Button,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Alert,
  Col,
  Nav,
  NavItem,
  FormGroup,
  Form,
  Input,
  NavLink as NavLinkRS,
  Modal, ModalHeader, ModalMody, ModalFooter
} from "reactstrap";

import API, { base } from "api.js";
import { NavLink } from "react-router-dom";
import { store, persistor } from "store/index";
import { Helmet } from "react-helmet";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { thead, tbody } from "variables/general";
import ContactUsPremium from "components/Help/ContactUsPremium";
import { connect } from "react-redux";
import SidebarClose from "components/Sidebar/SidebarClose";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import ArrowIcon from "../assets/images/images_new/dropdown-icon.svg"

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      paidMember: true,
      loadmore: false,
      activeTab: 1,
      isOpen: false,
      popup_file: "",
      popup_title: ""
    };
  }

  componentDidMount() {
    this.loadTemplate();
  }

  loadTemplate = () => {
    API({
      method: "GET",
      url: "help?skip=" + this.state.data.length + "&type=1",
    })
    .then((data) => {
      let loadmore = true;
      if (data.data.length < 10) {
        loadmore = false;
      }

      this.setState({
        data: [...this.state.data, ...data.data],
        loadmore: loadmore,
      });
    })
    .catch(() => {});
  };

  OpenPDF = (e, file, title) => {
    this.setState({
      popup_file: `${base}${file}`,
      popup_title: title
    }, () => {
      this.toggle();
    });
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Top PTE Training Institute</title>
        </Helmet>
        <div className="content p-0">
          <DashboardHeader title="Need Help" />
          <div className="need-help-page outer-wrapper">
            <Row>
              <Col xs={12}>
                <Card>
                  {!this.state.paidMember && (
                    <Alert color="success">
                      Please upgrade your plan to access secret templates.
                    </Alert>
                  )}
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button className="nav-link active" id="silver-tab" data-bs-toggle="tab" data-bs-target="#silver-tab-pane" type="button" role="tab" aria-controls="silver-tab-pane" aria-selected="true">Help</button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button className="nav-link" id="gold-tab" data-bs-toggle="tab" data-bs-target="#gold-tab-pane" type="button" role="tab" aria-controls="gold-tab-pane" aria-selected="false">Contact Us</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="silver-tab-pane" role="tabpanel" aria-labelledby="silver-tab" tabIndex="0">
                      <CardBody className="table-wrapper p-0">
                        <Table>
                          <thead className="text-primary">
                            <tr>
                              <th className="table-counting">#</th>
                              <th className="table-questiong">Question</th>
                              <th className="table-resolution">Resolution</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td className="">{val.title}</td>
                                  <td className="">
                                    <a
                                      // to={"/admin/viewpdf/" + val.id}
                                      href="javascript:void(0)"
                                      className="nav-link view-btn"
                                      onClick={(e) => this.OpenPDF(e, val.pdf, val.title)}
                                    >
                                      View
                                      <img className="view-btn-icon" src={ArrowIcon} alt="Image" />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                            {this.state.loadmore && (
                              <button
                                className="btn"
                                onClick={(e) => {
                                  this.loadTemplate();
                                }}
                              >
                                Load more
                              </button>
                            )}
                          </tbody>                        
                        </Table>
                      </CardBody>  
                    </div>
                    
                    <div className="tab-pane fade" id="gold-tab-pane" role="tabpanel" aria-labelledby="gold-tab" tabIndex="0">
                      <div className="help-form">
                        <ContactUsPremium />
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

          {this.state.isOpen &&
            <Modal
              isOpen={this.state.isOpen}
              modalClassName="view-pdf-popup"
            >
              <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    onClick={this.toggle}
                  ></button>
                  <h1 className="modal-title" id="exampleModalLabel">
                    {this.state.popup_title}
                  </h1>
                </div>
                <div className="modal-body">
                  <embed
                    src={`${this.state.popup_file}#toolbar=0`}
                    style={{
                      width: "100%",
                      height: "85vh"
                    }}
                    frameBorder="0"
                  ></embed>
                </div>
            </Modal>
          }
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, null)(Template);
