import React, { useState, useEffect } from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from 'reactstrap';
import API from 'api.js';
import SelectOption from "components/PracticeMaterial/SelectOption";

import multiLing from "assets/images/images_new/translation-icon.png";

export default function TranslateModal(props) {

	const { isOpen, toggle, question, question_id, languages, voices } = props;

	const [question_text, setQuestionText] = useState(question);
	const [selectedLanguage, setSelectedLanguage] = useState("English");


	const selectScriptLanguage = (selectedValue) => {
		if (selectedValue !== "English" && selectedValue !== "0") {
			setQuestionText("Translating text. Please wait...");

			API({
				url: 'translate/sentence',
				method: 'POST',
				data: {
					script: question,
					lang: selectedValue,
					question_id: question_id
				}
			}).then((res) => {

				if (res.status === 200) {

					if (typeof res.data.translation === "object") {
						setQuestionText(res.data.translation.translation);
					} else {
						setQuestionText(res.data.translation);
					}
				} else {
					setQuestionText(res.message);
				}

				setSelectedLanguage(selectedValue);
				
			});
		} else {
			setSelectedLanguage("English");
			setQuestionText(question);
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			size="lg"
			className="text-translate-modal"
		>
			<ModalHeader
				className="text-translation"
				tag="div"
			>
				<div className="modal-header-img">
					<img src={multiLing} alt="multi-languages" />
				</div> Text Translation

				<Button className="modalCloseBtn" onClick={() => toggle(false)}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24"
						height="24"
					>
						<path
							d="M18 6L6 18M6 6l12 12"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Button>
			</ModalHeader>
			<ModalBody>
				<div className="row">
					<h2 className="translate-title">Language</h2>
					{languages.length > 0 &&
						<SelectOption
							isClearable={false}
							selected={selectedLanguage}
							data={languages.filter((language, i) => {
								return language.label !== selectedLanguage
							})}
							changeEvent={selectScriptLanguage}
						/>
					}
				</div>
				<div className="text-to-translate">
					<div className="mb-2 ques-text">{question_text}</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					color="danger"
					className="close-button"
					onClick={() => toggle(false)}
				>Close</Button>
			</ModalFooter>
		</Modal>
	);
}